<template>
    <head>

        <!-- App favicon -->
        <link rel="shortcut icon" href="assets/images/favicon.svg">

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
        <!-- Bootstrap select styling -->
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
        <!-- App Css-->
        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />


    </head>

    <body data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">


        <NavigationBar/>

        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Finance Act</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item active">User's Register</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- eTransactions table -->

                    <div class="row">
                        <div class="col-4">
                            <div class="card p-4">

                                <div>
                                    <div class="form-group">
                                        <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">
                                            <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                                <label >All Departments({{departments.length}})<strong class="text-danger">*</strong></label>
                                                <div v-if="getRight('CREATE DEPARTMENT')" class="d-flex">
                                                    <a @click="gotTo('department')" type="button" class="btn btn-primary dropdown-toggle option-selector" data-bs-toggle="offcanvas" data-bs-target="#dt__filter" aria-controls="offcanvasRight">
                                                        <i class="mdi mdi-account-multiple-plus-outline  font-size-6"></i> <span class="pl-1 d-md-inline">Add Department</span>
                                                    </a>
                                                </div>


                                            </div>
                                        </div>


                                        <table class="table table-striped table-sm font-12 sources-table">
                                            <thead>
                                            <tr class="font-weight-bold text-black">
                                                <th scope="col">No</th>
                                                <th scope="col">Department</th>

                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr style="cursor: pointer;" v-for="(item, index) in departments" :value="item" :key="index" @click="getIncomeTypes(item.departmentID)">
                                                <td scope="row">{{index+1}}.</td>

                                                <td>{{item.department}}</td>
                                            </tr>
                                            <tr style="background: #cee9d8;">
                                                <th><strong>Total {{departments.length}}</strong></th>
                                                <th></th>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <!--                                        <ul>-->
                                        <!--                                            <li style="cursor: pointer;" v-for="(item, index) in departments" :key="`${index}`" @click="selectedDepartment(item.department,item.id)" > {{item.department}}</li>-->
                                        <!--                                        </ul>-->
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-4">
                            <div class="card">
                                <div class="calc-30px">
                                    <div class="card">

                                        <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                            <h7>Revenue streams  <button @click="getIncomeTypes('')" class="btn btn-warning btn-sm text-nowrap" title="View unmapped streams to department">unmapped</button> </h7>
                                            <button @click="clearSelected" v-if="getRight('CREATE REVENUE STREAM')" class="btn btn-success btn-sm text-nowrap" title="Add New Revenue Stream" data-toggle="modal" data-target="#addRevenueStream">Add</button>

                                        </div>

                                        <div class="card-body">


                                            <div class="table-responsive font-12">
                                                <table class="table table-striped table-sm font-12 sources-table">
                                                    <thead>
                                                    <tr class="font-weight-bold text-black">
                                                        <th scope="col">No</th>
                                                        <th scope="col">Revenue streams</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr style="cursor: pointer;" v-for="(item, index) in incomeTypes" :value="item" :key="index">
                                                        <td scope="row">{{index+1}}</td>
                                                        <td scope="row" @click="getFeesAndCharges(item.incomeTypeId,item.incomeTypeDescription,item.accountDesc)">
                                                          <strong>{{item.brimsCode}}</strong> {{item.incomeTypeDescription}} <small><b>{{item.zoneCategory}}</b></small>

                                                        </td>

                                                        <td v-if="getRight('EDIT REVENUE STREAM')">
                                                            <button @click="selectStream(item)" class="btn btn-success btn-sm text-nowrap" title="change department" data-toggle="modal" data-target="#changeStream">Edit</button>
                                                        </td>

                                                        <td v-if="getRight('DELETE REVENUE STREAM')">
                                                            <button @click="deleteStream(item)" class="btn btn-danger btn-sm text-nowrap" title="change department" data-toggle="modal" data-target="#changeStream">Delete</button>
                                                        </td>

                                                    </tr>
                                                    <tr style="background: #cee9d8;">
                                                        <th><strong>Total {{incomeTypes.length}}</strong></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-4">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">
                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <h6 class="mb-0">Fees and charges</h6>

                                        <button @click="clearSelected" v-if="getRight('CREATE REVENUE STREAM')" class="btn btn-success btn-sm text-nowrap" title="Add New Fee and charges" data-toggle="modal" data-target="#addFeeAndCharge">Add</button>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">

                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="bx  bx-trash label-icon"></i> Delete Contact(s)</button>
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-plus label-icon"></i> Compose Message</button>
                                    </div>
                                </div>
                                <div class="card-body">

                                    <table class="table table-striped table-sm font-12 sources-table">
                                        <thead>
                                        <tr class="font-weight-bold text-black">
                                            <th scope="col">No</th>
                                            <th scope="col">Stream({{incomeTypeDescription}})</th>
                                            <th scope="col">Amount (KES)</th>


                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr style="cursor: pointer;" v-for="(item, index) in feesAndCharges" :value="item" :key="index">
                                            <td>{{index+1}}</td>

                                            <td scope="row"><strong>{{item.brimsCode}}</strong> {{item.feeDescription}} <small><b>{{item.zone}}</b></small></td>

                                            <td><b>{{item.unitFeeAmount}}</b></td>

                                            <td v-if="getRight('EDIT REVENUE STREAM')">
                                                <button @click="selectFeeAndCharge(item)" class="btn btn-success btn-sm text-nowrap" title="Edit Fees and Charges" data-toggle="modal" data-target="#updateFeeAndCharge">Edit</button>
                                            </td>

                                            <td v-if="getRight('DELETE REVENUE STREAM')">
                                                <button @click="deleteFeeAndCharge(item)" class="btn btn-danger btn-sm text-nowrap" title="Delete Fees and Charges" data-toggle="modal" data-target="#updateFeeAndCharge">Delete</button>
                                            </td>

                                        </tr>
                                        <tr style="background: #cee9d8;">
                                            <th><strong>Total {{feesAndCharges.length}}</strong></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- bootstrap modal -->
            <div class="modal fade" id="changeStream" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Update {{incomeType.incomeTypeDescription}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>


                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">

                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div>
                                                <label class="form-label">Revenue Department</label>
                                                <select v-model="departmentID" class="form-control bg-white" title="Select Revenue Stream" data-live-search="true" >
                                                    <option value="">Select Department</option>
                                                    <option v-for="(item, index) in departments" :key="`${index}`" :value="item.departmentID">{{item.department}}</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group" style="margin-top: 20px">
                                        <label>Revenue Type<strong class="text-danger">*</strong></label>
                                        <select v-model="incomeTypePrefix" class="form-control bg-white" title="Select Revenue Stream" data-live-search="true">
                                            <option value="">Select Revenue Type</option>
                                            <option v-for="(item, index) in streams" :key="`${index}`" :value="JSON.stringify(item)">{{item.stream}}</option>
                                        </select>
                                    </div>

                                    <div class="form-group" style="margin-top: 20px">
                                        <label>Revenue Stream<strong class="text-danger">*</strong></label>
                                        <input v-model="incomeType.incomeTypeDescription" type="text" class="form-control" placeholder="Revenue Stream">
                                    </div>


                                    <div class="form-group" style="margin-top: 20px">
                                        <label>Brims Code<strong class="text-danger">*</strong></label>
                                        <input v-model="incomeType.brimsCode" type="text" class="form-control" placeholder="Brims Code">
                                    </div>

                                    <div class="form-group" style="margin-top: 20px">
                                        <small>Check for this to appear on USSD as a menu</small><br>
                                        <label>Available on USSD<strong class="text-danger">*</strong></label>
                                        <input v-model="ussd"  type="checkbox">
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div v-if="message!==null" class="alert alert-danger" role="alert">
                            {{message}}
                        </div>
                        <div class="modal-footer">
                            <button @click="updateRevenueStream()" type="button" class="btn btn-primary w-100 text-center">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of changing revenue stream modal -->

            <div class="modal fade" id="updateFeeAndCharge" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Update Fees and Charges</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="">Fee Description<strong class="text-danger">*</strong></label>
                                                <input v-model="feesAndCharge.feeDescription" type="text" class="form-control" placeholder="Fee Description">
                                            </div>

                                            <div class="form-group" style="margin-top:20px">
                                                <label for="">Unit Fee Amount<strong class="text-danger">*</strong></label>
                                                <input v-model="feesAndCharge.unitFeeAmount" type="text" class="form-control" placeholder="Enter Amount">
                                            </div>

                                            <div class="form-group" style="margin-top:20px">
                                                <label for="">Zone/Collection Points/Sub County<strong class="text-danger">*</strong></label>

                                                <select class="form-select"  v-model="feesAndCharge.zone">
                                                    <option ><a class="dropdown-item" href="#">ALL SUB COUNTIES</a></option>
                                                    <option ><a class="dropdown-item" href="#">OTHER SUB COUNTIES</a></option>
                                                    <option v-for="(item, index) in zones" :key="`${index}`" :value="item.zone"><a class="dropdown-item" href="#">{{item.zone}}</a></option>
                                                </select>
                                                <small>Select zone or Sub County where the services apply</small>
                                            </div>



                                            <div class="form-group" style="margin-top: 20px">
                                                <label>Brims Code<strong class="text-danger">*</strong></label>
                                                <input v-model="feesAndCharge.brimsCode" type="text" class="form-control" placeholder="Brims Code">
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="message!==null" class="alert alert-danger" role="alert">
                            {{message}}
                        </div>
                        <div class="modal-footer">
                            <button data-dismiss="modal" @click="updateFeesAndCharge()" type="button" class="btn btn-primary w-100 text-center">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addFeeAndCharge" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{feesAndCharge.typeDescription}}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">

                            <div><small>Create new Fees and charges</small></div>
                            <div class="row" style="margin-top: 20px">
                                <div class="col-lg-12">
                                    <div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Fee Description<strong class="text-danger">*</strong></label>
                                                <input v-model="feesAndCharge.feeDescription" type="text" class="form-control" placeholder="Fee Description">
                                            </div>

                                            <div class="form-group" style="margin-top:20px">
                                                <label >Unit Fee Amount<strong class="text-danger">*</strong></label>
                                                <input v-model="feesAndCharge.unitFeeAmount" type="text" class="form-control" placeholder="Enter Amount">
                                            </div>

                                            <div class="form-group" style="margin-top:20px">
                                                <label for="">Zone/Collection Points/Sub County<strong class="text-danger">*</strong></label>

                                                <select class="form-select"  v-model="feesAndCharge.zone">
                                                    <option ><a class="dropdown-item" href="#">ALL SUB COUNTIES</a></option>
                                                    <option ><a class="dropdown-item" href="#">OTHER SUB COUNTIES</a></option>
                                                    <option v-for="(item, index) in zones" :key="`${index}`" :value="item.zone"><a class="dropdown-item" href="#">{{item.zone}}</a></option>
                                                </select>
                                                <small>Select zone or Sub County where the services apply</small>
                                            </div>

                                            <div class="form-group" style="margin-top: 20px">
                                                <label>Brims Code<strong class="text-danger">*</strong></label>
                                                <input v-model="feesAndCharge.brimsCode" type="text" class="form-control" placeholder="Brims Code">
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="message!==null" class="alert alert-danger" role="alert">
                            {{message}}
                        </div>
                        <div class="modal-footer">
                            <button data-dismiss="modal" @click="addNewFeeAndCharge()" type="button" class="btn btn-primary w-100 text-center">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addRevenueStream" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Create new Revenue Stream</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div class="modal-body">

                            <div><small>Create new Revenue Stream</small></div>
                            <div class="row" style="margin-top: 20px">
                                <div class="col-lg-12">
                                    <div>
                                        <div class="col-12">

                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div>
                                                        <label class="form-label">Revenue Department</label>
                                                        <select class="form-control bg-white" title="Select Revenue Stream" data-live-search="true" @change="handleChange">
                                                            <option value="">Select Department</option>
                                                            <option v-for="(item, index) in departments" :key="`${index}`" :value="item.departmentID">{{item.department}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group" style="margin-top: 20px">
                                                <label>Revenue Type<strong class="text-danger">*</strong></label>
                                                <select v-model="incomeTypePrefix" class="form-control bg-white" title="Select Revenue Stream" data-live-search="true">
                                                    <option value="">Select Revenue Type</option>
                                                    <option v-for="(item, index) in streams" :key="`${index}`" :value="JSON.stringify(item)">{{item.stream}}</option>
                                                </select>
                                            </div>

                                            <div class="form-group" style="margin-top: 20px">
                                                <label>Revenue Stream<strong class="text-danger">*</strong></label>
                                                <input v-model="incomeTypeDescription" type="text" class="form-control" placeholder="Revenue Stream">
                                            </div>

                                            <div class="form-group" style="margin-top: 20px">
                                                <label>Brims Code<strong class="text-danger">*</strong></label>
                                                <input v-model="incomeType.brimsCode" type="text" class="form-control" placeholder="Brims Code">
                                            </div>

                                            <div class="form-group" style="margin-top: 20px">
                                                <small>Check for this to appear on USSD as a menu</small><br>
                                                <label>Available on USSD<strong class="text-danger">*</strong></label>
                                                <input v-model="ussd"  type="checkbox">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="message!==null" class="alert alert-danger" role="alert">
                            {{message}}
                        </div>
                        <div class="modal-footer">
                            <button data-dismiss="modal" @click="addNewRevenueStream()" type="button" class="btn btn-primary w-100 text-center">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>


            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                            © Best Birdy
                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">
                                Best Birdy Billing system.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


        </div>
        <!-- end main content-->


    </div>

    </body>

</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import router from "@/router";
    import {authUrl, biller, execute, getRight} from "@/api";



    export default {
        name: "AddUser",
        components: {NavigationBar},
        data() {
            return{
                ussd: false,
                permission:'',
                message:null,
                departmentID:'',
                incomeTypeId:'',
                departments:[],
                revenueStream:'',
                incomeTypeDescription:'',
                incomeTypePrefix:'',
                incomeType:{
                    incomeTypeId:null,
                    incomeTypeDescription:null,
                    incomeTypePrefix:null,
                    fundDescription:null,
                    status:null,
                    departmentID:null,
                    zoneCategoryID:null,
                    zoneCategory:null,
                    onUssd:null,
                    brimsCode:null,
                },
                incomeTypes:[],
                feesAndCharges:[
                    {
                        feeId: "731",
                        feeDescription: "Up to 7 tons",
                        unitOfMeasure: "Per Trip",
                        unitFeeAmount: "1000",
                        accountNo: "",
                        incomeTypeId: "45",
                        feeType: "",
                        accountDesc: "Cess on Building Stones",
                        costCenterNo: "",
                        zone: "ALL SUB COUNTIES",
                        typeDescription: ""
                    }
                ],
                feesAndCharge:{
                    feeId: "",
                    feeDescription: "",
                    unitOfMeasure: "",
                    unitFeeAmount: "",
                    accountNo: "",
                    incomeTypeId: "",
                    feeType: "",
                    accountDesc: "",
                    costCenterNo: "",
                    zone: "ALL SUB COUNTIES",
                    typeDescription: "",
                    brimsCode: ""
                },
                zones: [],
                streams:[
                    {
                        id: null,
                        incomeTypePrefix: null,
                        stream: null
                    },
                ]
            }
        },
        computed: {

        },
        watch: {

        },

        mounted() {

            this.feesAndCharges.splice(0)
            this.departments.splice(0)
            this.getDepartment()
            this.getIncomeTypes("")
            this.getZones()
            this.getMainStreams()
            this.permission = sessionStorage.getItem("permission")

        },
        methods:{
            getRight(type){
                return getRight(type)
            },
            gotTo(route){
                router.push(route)
            },
            handleChange(event) {
                this.departmentID = event.target.value;
            },selectStream(item) {
                this.incomeType = item
                this.getDepartment()
            },
            selectFeeAndCharge(item) {
                this.feesAndCharge = item;
            },
            clearSelected() {
                this.feesAndCharge.unitFeeAmount = "";
                this.feesAndCharge.feeDescription = "";
            },
            deleteFeeAndCharge(item) {
                this.feesAndCharge = item;
                const isConfirmed = window.confirm("Are you sure you want to delete this fee and charge?");

                // If the user confirms, proceed with the deletion
                if (isConfirmed) {
                    this.incomeType = item;
                    const data = new FormData();
                    data.append("function", "deleteFeeAndCharge");
                    data.append("feesAndCharge", this.feesAndCharge.feeId);
                    execute(data, biller)
                        .then((res) => {
                            if (res.data.success) {
                                this.message = res.data.message;
                                // this.departments = res.data.data.departments
                            } else {
                                this.message = res.data.message;
                            }
                        })
                        .catch((e) => {
                            // alert(e.message);
                            this.message = e.message;
                        });
                }

            },

            deleteStream(item) {
                // Display a confirmation alert
                const isConfirmed = window.confirm("Are you sure you want to delete this stream?");

                // If the user confirms, proceed with the deletion
                if (isConfirmed) {
                    this.incomeType = item;
                    const data = new FormData();
                    data.append("function", "deleteStream");
                    data.append("incomeTypeId", this.incomeType.incomeTypeId);
                    execute(data, biller)
                        .then((res) => {
                            if (res.data.success) {
                                this.message = res.data.message;
                                // this.departments = res.data.data.departments
                            } else {
                                this.message = res.data.message;
                            }
                        })
                        .catch((e) => {
                            // alert(e.message);
                            this.message = e.message;
                        });
                }
            },
            updateRevenueStream(){
                this.message = null
                if(this.departmentID ==='' ){
                    this.message ='Select department to change'
                    return
                }
                this.message = null
                const data = new FormData();
                data.append("function", "updateRevenueStream");
                data.append("departmentID", this.departmentID);
                data.append("incomeTypeId", this.incomeType.incomeTypeId);
                data.append("incomeTypeDescription", this.incomeType.incomeTypeDescription);
                data.append("incomeTypePrefix", this.incomeTypePrefix);
                data.append("ussd", this.ussd);
                data.append("brimsCode", this.incomeType.brimsCode);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.message =res.data.message
                            //this.departments = res.data.data.departments
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });

            },
            getDepartment(){
                this.message = null
                const data = new FormData();
                data.append("function", "getDepartment");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.departments.splice(0)
                            this.departments = res.data.data.departments

                            this.departments.forEach((value, index) => {
                                if (this.incomeType.departmentID === value.departmentID) {
                                    this.departmentID = this.departments[index].departmentID;
                                }
                            });
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getZones(){
                const data = new FormData();
                data.append("function", "getZones");
                execute(data,authUrl)
                    .then((res) =>{
                        if (res.data.success) {
                            this.zones = res.data.data.zones
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getMainStreams(){
                const data = new FormData();
                data.append("function", "getMainStreams");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.streams.splice(0)
                            this.streams = res.data.data.streams
                            this.streams.forEach((value, index) => {
                                if (this.incomeType.incomeTypePrefix === value.incomeTypePrefix) {
                                    this.incomeTypePrefix = this.streams[index].incomeTypePrefix;
                                }
                            });
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            updateFeesAndCharge(){
                this.message = null
                const data = new FormData();
                data.append("function", "updateFeesAndCharge");
                data.append("feesAndCharge", JSON.stringify(this.feesAndCharge));
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.getFeesAndCharges(this.feesAndCharge.incomeTypeId,this.feesAndCharge.typeDescription)
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            addNewFeeAndCharge(){
                this.message = null
                const data = new FormData();
                data.append("function", "addNewFeeAndCharge");
                data.append("feesAndCharge", JSON.stringify(this.feesAndCharge));
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.getFeesAndCharges(this.feesAndCharge.incomeTypeId,this.feesAndCharge.typeDescription)
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            addNewRevenueStream(){
                if(this.departmentID === "" || this.incomeTypePrefix === ""){
                    this.message = "Please select";
                    alert("Please select")
                    return;
                }
                this.message = null
                const data = new FormData();
                data.append("function", "addNewRevenueStream");
                data.append("incomeTypeDescription", this.incomeTypeDescription);
                data.append("incomeTypePrefix", this.incomeTypePrefix);
                data.append("departmentID", this.departmentID);
                data.append("ussd", this.ussd);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.getIncomeTypes("")
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getIncomeTypes(departmentID){
                this.incomeTypes.splice(0)
                const data = new FormData();
                data.append("function", "getIncomeTypes");
                data.append("departmentID", departmentID);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.incomeTypes = res.data.data.incomeTypes
                            // alert(this.incomeTypes)
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getFeesAndCharges(incomeTypeId,incomeTypeDescription,accountDesc){
                this.message = null
                this.feesAndCharge.incomeTypeId = incomeTypeId
                this.feesAndCharge.typeDescription = incomeTypeDescription
                this.feesAndCharge.accountDesc = accountDesc


                this.incomeTypeDescription = incomeTypeDescription
                this.feesAndCharges.splice(0)
                const data = new FormData();
                data.append("function", "getFeesAndCharges");
                data.append("incomeTypeId", incomeTypeId);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.feesAndCharges = res.data.data.feesAndCharges
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            }
        },
    }
</script>

<style scoped>

</style>