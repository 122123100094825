<template>

    <head>

        <meta charset="utf-8" />
        <title>Hotline Transporters Sacco | All invoices</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta content="Rental management system by Nouveta LTD" name="description" />
        <meta content="Kelvin Njuguna" name="author" />
        <!-- App favicon -->
        <link rel="shortcut icon" href="assets/images/favicon.svg">
        <!-- droping file -->
        <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
        <!-- Bootstrap select styling -->
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
        <!-- App Css-->
        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

    </head>

    <body data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>


        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Data On Businesses In Homa Bay County</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Business Mapping</a></li>
                                        <li class="breadcrumb-item active">Business register</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">


                                <div class="card-header bg-white pt-2">
                                    <div class="row">

                                        <div class="col-2">
                                            <div class="d-flex flex-column"><small class="text-uppercase fw-semibold mb-3">Sub County</small>
                                                <div class="d-flex align-items-center">

                                                    <select class="form-control bg-white" data-live-search="true"
                                                            title="Select Sub County" v-model="subCounty">
                                                        <option  selected="" value="">All Sub Counties</option>
                                                        <option :key="index" :value="item"
                                                                v-for="(item, index) in subCounties">
                                                            {{ item.subCountyName }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-2">
                                            <div class="d-flex flex-column"><small class="text-uppercase fw-semibold mb-3">Ward</small>
                                                <div class="d-flex align-items-center">

                                                    <select class="form-control bg-white" data-live-search="true"
                                                            title="Select Sub County" v-model="ward">
                                                        <option  selected="" value="">All Wards</option>
                                                        <option :key="index" :value="item"
                                                                v-for="(item, index) in wards">
                                                            {{ item.wardName }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-2">
                                            <div class="d-flex flex-column"><small class="text-uppercase fw-semibold mb-3">Category</small>
                                                <div class="d-flex align-items-center">

                                                    <select class="form-control bg-white" data-live-search="true" title="Select Sub County" v-model="tradeCategory">
                                                        <option  selected="" value="">All Categories</option>
                                                        <option :key="index" :value="item"
                                                                v-for="(item, index) in tradeCategories">
                                                            {{ item.incomeTypeDescription }}
                                                        </option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-2">
                                            <div class="d-flex flex-column"><small class="text-uppercase fw-semibold mb-3">Sub Categories</small>
                                                <div class="d-flex align-items-center">

                                                    <select class="form-control bg-white" data-live-search="true" title="Select Sub County" v-model="tradeSubCategory">
                                                        <option value="" >All Sub Categories</option>
                                                        <option :key="index" :value="item" v-for="(item, index) in tradeSubCategories">
                                                            {{ item.feeDescription }}
                                                        </option>
                                                    </select>



                                                </div>
                                            </div>
                                        </div>



                                        <div class="col-2">
                                            <div class="d-flex flex-column"><small class="text-uppercase fw-semibold mb-3">Other Licences</small>
                                                <div class="d-flex align-items-center"> {{licence}}
                                                    <select class="form-control form-select" tittle="Filter Transactions By" v-model="licence">
                                                        <option  selected="" value="">All Businesses</option>
                                                        <option value="fireLicence">Fire Licenses</option>
                                                        <option value="LiquorLicence">Liquor</option>

                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-2">

                                            <div class="d-flex flex-column"><small class="text-uppercase fw-semibold mb-3">Reports</small>
                                                <a @click="downloadBusinessData()"  type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                    <i class="mdi mdi-download  font-size-10"></i> <span class="pl-1 d-md-inline">{{download}}</span>
                                                </a>
                                            </div>

                                        </div>

                                        <!-- <div class="col-3 d-flex justify-content-end align-items-end">
                                            <div class="text-sm-end mt-4 mt-sm-0 justify-end d-flex text-right flex-column">
                                                <p class="text-muted mb-2">Collected Data</p>
                                                <h5>45 Businesses </h5>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <div class="flex-grow-1">
                                            <div class="search-box mb-0 me-3">
                                                <div class="position-relative">
                                                    <input v-model="searchValue" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                                                    <i class="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex">

                                            <div class="d-flex align-items-center me-3 pe-3">
                                                <span class="pr-3 fw-semibold">From:</span>

                                                <input v-model="dateFrom" class="form-control" placeholder="Start date" type="date">

                                                <span class="px-3 fw-semibold">TO:</span>
                                                <input v-model="dateTo" class="form-control" placeholder="Start date" type="date">

                                            </div>


                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button type="button" data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>


                                <div class="card-body">
                                    <div class="table-responsive ">

                                        <table class="table align-middle table-hover  contacts-table table-striped " id="">
                                            <thead class="table-light">
                                            <tr class="table-dark">
                                                <th scope="col">NO.</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">Sub category</th>
                                                <th scope="col">Sub County</th>
                                                <th scope="col" class="">Ward</th>
                                                <th>Owner</th>
                                                <th scope="col" >Phone</th>
                                                <th>Liquor Business</th>
                                                <th>Fire License</th>
                                                <th scope="col" class="">Description</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr :key="index" :value="item" v-for="(item, index) in businesses">
                                                <td>{{index+1}}.</td>
                                                <td>{{item.businessName}}</td>
                                                <td>{{item.businessCategory}}</td>
                                                <td>{{item.businessSubCategory}}</td>
                                                <td>{{item.subCountyName}}</td>
                                                <td>{{item.wardName}}</td>
                                                <td>{{item.contactPersonNames}}</td>
                                                <td>{{item.contactPersonPhone}}</td>
                                                <td>{{item.liquorLicence}}</td>
                                                <TD>{{item.fireLicence}}</TD>
                                                <td>{{item.businessType}}</td>
                                                <td> <button @click="selectedBusiness(item)" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target=".receipting-modal">More Details</button></td>
                                            </tr>
                                            </tbody>
                                            <tfoot class="table-dark">
                                            <tr>

                                                <th colspan="6">
                                                    {{businesses.length}} Businesses
                                                </th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <td> <button class="btn btn-sm btn-primary">More Details</button></td>


                                            </tr>

                                            </tfoot>
                                        </table>

                                        <div class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center"
                                             v-if="loading">
                                            <div class="p-5 py-0 pt-3">
                                                <div class="p-5">
                                                    <div class="spinner-border text-info m-1" role="status">
                                                        <span class="sr-only text-uppercase">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="text-uppercase">Loading Data</h4>
                                            <div class="col-sm-12 col-md-8 col-lg-5">
                                                <p>We are fetching the data for you. It will be ready soon. Please wait
                                                    and have fun. Thank you for your interest.</p>

                                            </div>
                                        </div>
                                        <div class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center"
                                             v-if="businesses.length<1 & loading === false">
                                            <div class="p-5 py-0 pt-3">
                                                <img :src="'./assets/images/no-results.png'" alt="No search results"
                                                     class="img mb-4"/>
                                            </div>
                                            <h4>No Results To Show</h4>
                                            <p>Sorry, we could not find any results that match your search or filter
                                                criteria. Please try using different keywords or filters.</p>
                                        </div>


                                    </div>
                                </div>


                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example "
                                         class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px " v-model="pageSize">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                                <option value="10000">10,000 Rows</option>
                                                <option value="50000">50,000 Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                                            <li :class="{ disabled: currentPage === 1 }" class="page-item">
                                                <a :disabled="currentPage === 1" @click="prevPage" class="page-link"
                                                   tabindex="-1">Previous</a>
                                            </li>
                                            <!-- replace the v-for directive with the pagination function call -->
                                            <li :class="{ active: currentPage === page }" :key="page" class="page-item"
                                                v-for="page in pagination(currentPage, totalPages, 3)">
                                                <!-- check if the page is an ellipsis and display it as plain text -->
                                                <span class="page-link" v-if="page === '...'">{{ page }}</span>
                                                <!-- otherwise, display it as a clickable link -->
                                                <a @click="goToPage(page)" class="page-link" v-else>{{ page }}</a>
                                            </li>
                                            <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                                            <li :class="{ disabled: currentPage === totalPages }" class="page-item">
                                                <a :disabled="currentPage === totalPages" @click="nextPage"
                                                   class="page-link">Next</a>
                                            </li>
                                        </ul>


                                    </nav>
                                </div>


                            </div>
                        </div>
                        <!-- end col -->
                    </div>



                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Business Captured Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Captured By:</strong><br>
                                        {{business.createdBy}}<br>
                                        {{business.createdByIDNo}} <br>

                                        <br>
                                        {{formatDate(business.dateCreated)}}

                                    </address>
                                </div>
                                <div class="col-sm-6 text-sm-end d-none">
                                    <address class="mt-2 mt-sm-0">
                                        <strong>Payment Method</strong><br>
                                        <span class="fw-medium">MPESA</span><br>
                                        Phone No. 0704 549 859<br> <br>
                                        On 1 Mar 2022, 10:20 AM
                                    </address>
                                </div>
                            </div>

                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Collected Data</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap vertical-align-middle table-sm table-striped">

                                        <tbody>
                                        <tr>
                                            <th>Business Name</th>
                                            <td>{{business.businessName}}</td>
                                        </tr>

                                        <tr>
                                            <th>Business Category</th>
                                            <td>{{business.businessCategory}}</td>
                                        </tr>

                                        <tr>
                                            <th>Business Sub category</th>
                                            <td>{{business.businessSubCategory}}</td>
                                        </tr>

                                        <tr>
                                            <th>Owner</th>
                                            <td>{{business.contactPersonNames}}</td>
                                        </tr>

                                        <tr>
                                            <th>Owners ID</th>
                                            <td>{{business.contactPersonIDNo}}</td>
                                        </tr>

                                        <tr>
                                            <th>Phone Number</th>
                                            <td>{{business.contactPersonPhone}}</td>
                                        </tr>

                                        <tr>
                                            <th>Business Description</th>
                                            <td>{{business.businessType}}</td>
                                        </tr>

                                        <tr>
                                            <th>Plot Number</th>
                                            <td>{{business.plotNumber}}</td>
                                        </tr>

                                        <tr>
                                            <th>Sub county and Ward</th>
                                            <td>{{business.subCountyName}}, {{business.wardName}}</td>
                                        </tr>

                                        <tr>
                                            <th>Physical Address</th>
                                            <td>{{business.physicalAddress}}</td>
                                        </tr>

                                        <tr>
                                            <th>Is Liquor License Applicable to the Business</th>
                                            <td>{{business.liquorLicence}}</td>
                                        </tr>

                                        <tr>
                                            <th>Id Fire Permit applicable to the Business</th>
                                            <td>{{business.fireLicence}}</td>
                                        </tr>
                                        <!--  <tr>
                                              <th>Amount Applicable To the Business</th>
                                              <td>KES 3,900</td>
                                          </tr>-->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Invoice Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>Billed To:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Hse No. 410, 90 Degrees By Tsavo
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                        <tr>
                                            <th style="width: 70px;">No.</th>
                                            <th>Item</th>
                                            <th class="text-end">Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>Rent Deposit</td>
                                            <td class="text-end">KES 24,500</td>
                                        </tr>

                                        <tr>
                                            <td>02</td>
                                            <td>Monthly Rent</td>
                                            <td class="text-end">KES 12,000</td>
                                        </tr>

                                        <tr>
                                            <td>03</td>
                                            <td>TAX (18%)</td>
                                            <td class="text-end">KES 250</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-end">Total</td>
                                            <td class="text-end fw-bold">KES 36,750</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" class="text-end">Paid</td>
                                            <td class="text-end  fw-bold">KES 0.00</td>
                                        </tr>

                                        <tr>
                                            <td colspan="2" class="border-0 text-end">
                                                <strong>Balance</strong></td>
                                            <td class="border-0 text-end">
                                                <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                        <tr data-id="1">
                                            <td>
                                                <label for="" class="">Payment Method</label>
                                                <select class="form-control selectpicker w-100 payment-method" data-style="btn-secondary w-100" data-live-search="true" title="Select payment Method">
                                                    <option value="Mpesa">MPESA</option>
                                                    <option value="Cash">CASH</option>
                                                </select>
                                            </td>
                                            <td class="px-3 ">
                                                <div class="phone-num d-none">
                                                    <label for="">Phone No.</label>
                                                    <input type="text " class="form-control w-100 d-flex " placeholder="Phone No." spellcheck="false" data-ms-editor="true">
                                                </div>

                                            </td>
                                            <td class="px-3">
                                                <label for="">Amount To Be Paid</label>
                                                <input type="text " class="form-control w-100 d-flex" placeholder="KES" spellcheck="false" data-ms-editor="true">

                                            </td>
                                            <td class="text-right float-right">
                                                <div class="d-flex flex-column">
                                                    <label class="opacity-0">Something</label>
                                                    <a href="#" class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                </div>
                                            </td>


                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">©County
                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">
                                County Government
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>

</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import moment from "moment";
    import {biller, execute, executeDownload, trade} from "@/api";
    export default {
        name: "BusinessData",
        components: {NavigationBar},
        data() {
            return {
                subCountyName:'',
                wardName:'',
                dateFrom:'',
                dateTo:'',
                searchValue:"",
                currentPage: 1,
                // set the total number of pages as 10 (you can change this according to your data source)
                totalPages: 0,
                // set the number of items per page as 5 (you can change this according to your preference)
                pageSize: 10,
                download:'Download Report',
                licence:'',
                loading: false,
                keyword:'',
                color:'alert-success',
                feeId:'',
                subCountyID:'',
                wardID:'',
                tradeCategory: {
                    incomeTypeId: null,
                    incomeTypeDescription: null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null,
                    departmentID: null,
                    zoneCategoryID: null,
                    zoneCategory: null
                },
                tradeCategories:[ {
                    incomeTypeId: null,
                    incomeTypeDescription: null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null,
                    departmentID: null,
                    zoneCategoryID: null,
                    zoneCategory: null
                }],
                tradeSubCategory:
                    {
                        feeId: null,
                        feeDescription: null,
                        unitOfMeasure: null,
                        unitFeeAmount: null,
                        accountNo: null,
                        incomeTypeId: null,
                        feeType: null,
                        accountDesc: null,
                        costCenterNo: null,
                        zone: null,
                        typeDescription: null,
                        prorated: null,
                        duration: null
                    }
                ,
                tradeSubCategories:[
                    {
                        feeId: null,
                        feeDescription: null,
                        unitOfMeasure: null,
                        unitFeeAmount: null,
                        accountNo: null,
                        incomeTypeId: null,
                        feeType: null,
                        accountDesc: null,
                        costCenterNo: null,
                        zone: null,
                        typeDescription: null,
                        prorated: null,
                        duration: null
                    }
                ],
                businesses: [{
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    plotNumber: null,
                    physicalAddress: null ,
                    buildingName: null,
                    buildingOccupancy:null ,
                    floorNo:null ,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    feeID: null,
                    businessEmail:null ,
                    postalAddress: null,
                    postalCode:null ,
                    businessPhone: null,
                    contactPersonNames:null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames:null ,
                    ownerID:null ,
                    ownerPhone: null,
                    ownerEmail:null ,
                    kraPin:null ,
                    createdBy: null,
                    createdByIDNo: null,
                    dateCreated: null,
                    lat: null,
                    lng: null,
                    fireLicence: null,
                    liquorLicence: null,
                    businessType: null
                }],
                business: {
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    plotNumber: null,
                    physicalAddress: null ,
                    buildingName: null,
                    buildingOccupancy:null ,
                    floorNo:null ,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    feeID: null,
                    businessEmail:null ,
                    postalAddress: null,
                    postalCode:null ,
                    businessPhone: null,
                    contactPersonNames:null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames:null ,
                    ownerID:null ,
                    ownerPhone: null,
                    ownerEmail:null ,
                    kraPin:null ,
                    createdBy: null,
                    createdByIDNo: null,
                    dateCreated: null,
                    lat: null,
                    lng: null,
                    fireLicence: null,
                    liquorLicence: null,
                    businessType: null
                },
                subCounties:[{
                    subCountyID: "",
                    subCountyName: ""
                }],
                wards:[{
                    wardID: "",
                    wardName: ""
                }],
                subCounty:{
                    subCountyID: null,
                    subCountyName: null
                },
                ward:{
                    wardID: null,
                    wardName: null
                },

            }

        },
        watch:{
            pageSize(newPageSize) {
                this.getBusinessData("")
                // get the total number of items from your data source (you can change this according to your logic)
                const totalItems = this.summary.count;
                // calculate the new total pages by dividing the total items by the new page size and rounding up
                const newTotalPages = Math.ceil(totalItems / newPageSize);
                // update the totalPages data property with the new value
                this.totalPages = newTotalPages;
                // reset the current page to 1
                this.currentPage = 1;

            },

            tradeCategory: function () {
                this.keyword = this.tradeCategory.incomeTypeDescription
                this.getTradeSubCategory()
                this.getBusinessData()
            },tradeSubCategory: function () {
                this.keyword = this.tradeSubCategory.feeId
                this.getBusinessData()
            },
            subCounty: function () {
                this.subCountyID =  this.subCounty.subCountyID
                this.subCountyName =  this.subCounty.subCountyName
                this.getWards()
                this.getBusinessData()

            } ,
            ward: function () {
                this.wardID = this.ward.wardID
                this.wardName = this.ward.wardName

                this.getBusinessData()
            },licence: function () {
                this.keyword = this.licence
                this.getBusinessData()
            }
        },
        mounted() {
            this.getTradeCategory()
            this.getSubCounties()

            this.$watch('searchValue', function(newValue) {
                this.keyword = newValue;
                this.getBusinessData()
            })
            this.$watch('dateFrom', function() {
                if(this.dateFrom==='' || this.dateTo===''){

                }else{
                    this.getBusinessData()
                }

            })
            this.$watch('dateTo', function() {
                if(this.dateFrom==='' || this.dateTo===''){

                }else{
                    this.getBusinessData()
                }
            })

            this.getBusinessData()
        },
        computed:{
            // calculate the start index of the items for the current page
            startIndex() {
                return (this.currentPage - 1) * this.pageSize;
            },
            // calculate the end index of the items for the current page
            endIndex() {
                return this.currentPage * this.pageSize;
            },

        },
        methods: {

            //pagination code
            pagination(currentPage, totalPages, maxVisibleButtons) {
                //initialize an empty array for the pages
                let pages = [];
                //calculate the start and end indices of the visible buttons
                let start = currentPage - Math.floor(maxVisibleButtons / 2);
                let end = currentPage + Math.floor(maxVisibleButtons / 2);
                //adjust the start and end indices if they are out of bounds
                if (start < 1) {
                    start = 1;
                    end = start + maxVisibleButtons - 1;
                    if (end > totalPages) {
                        end = totalPages;
                    }
                }
                if (end > totalPages) {
                    end = totalPages;
                    start = end - maxVisibleButtons + 1;
                    if (start < 1) {
                        start = 1;
                    }
                }
                //loop through the start and end indices and push the pages to the array
                for (let i = start; i <= end; i++) {
                    pages.push(i);
                }
                //add an ellipsis at the beginning if there are more pages before the start index
                if (start > 1) {
                    pages.unshift("...");
                }
                //add an ellipsis at the end if there are more pages after the end index
                if (end < totalPages) {
                    pages.push("...");
                }
// return the array of pages
                return pages;
            },
            // define a method to go to the previous page
            prevPage() {
                // check if the current page is not the first page
                if (this.currentPage > 1) {
                    // decrement the current page by 1
                    this.currentPage--;
                    this.getBusinessData()
                }
            },
            // define a method to go to the next page
            nextPage() {
                // check if the current page is not the last page
                if (this.currentPage < this.totalPages) {
                    // increment the current page by 1
                    this.currentPage++;
                    this.getBusinessData()
                }
            },
            // define a method to go to a specific page
            goToPage(page) {
                // check if the page is valid and not equal to the current page
                if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
                    // set the current page to the given page
                    this.currentPage = page;
                    this.getBusinessData()
                }
            },

            selectedBusiness(item){
                this.business = item

            },

            formatDate(date) {
                let d = new Date(date);
                let day = d.getDate();
                let month = d.toLocaleString('en-US', { month: 'short' });
                let year = d.getFullYear();

                let suffix = '';
                if (day % 10 === 1 && day !== 11) {
                    suffix = 'st';
                } else if (day % 10 === 2 && day !== 12) {
                    suffix = 'nd';
                } else if (day % 10 === 3 && day !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }
                return `${day}${suffix} ${month} ${year}`;
            },
            formattedTime(time) {
                return moment(time).format('hh:mm A')
            },
            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },
            getSubCounties(){
                const data = new FormData();
                data.append("function", "getSubCounty");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.subCounties =res.data.data.subCounties
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getWards(){
                this.wards =[]
                this.ward = ''
                const data = new FormData();
                data.append("function", "getWards");
                data.append("subCountyID", this.subCounty.subCountyID);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.wards =res.data.data.wards
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getBusinessData() {
                this.loading = trade
                this.businesses.splice(0)

                const data = new FormData();
                data.append("function", "getBusinessData");
                data.append("search", this.keyword !== undefined ? this.keyword : "");
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("subCountyID", this.subCountyID !== null && this.subCountyID !== undefined ? this.subCountyID : "");
                data.append("wardID", this.wardID !== null && this.wardID !== undefined ? this.wardID : "");
                execute(data, trade)
                    .then((res) => {
                        this.loading = false
                        if (res.data.success) {
                            this.businesses = res.data.data.businesses

                            this.totalItems = res.data.data.count.count
                            this.totalPages =  Math.ceil(this.totalItems / this.pageSize);

                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },
            downloadBusinessData(){
                this.download ="Please wait.."
                const data = new FormData();
                data.append("function", "downloadBusinessData");
                data.append("search", this.keyword !== undefined ? this.keyword : "");
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                data.append("dateFrom", this.dateFrom);
                data.append("dateTo", this.dateTo);
                data.append("subCountyID", this.subCountyID !== null && this.subCountyID !== undefined ? this.subCountyID : "");
                data.append("wardID", this.wardID !== null && this.wardID !== undefined ? this.wardID : "");

                executeDownload(data,trade).then(response => {
                    this.download ="Download Report"
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = this.subC !== null && this.subCountyName !== undefined ? this.subCountyName : ""+this.wardName !== null && this.wardName !== undefined ? this.wardName : ""+' '+this.dateFrom+' to '+ this.dateTo+"BusinessData.csv";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
            },
            getTradeCategory(){
                this.message =null
                this.tradeCategories.splice(0)
                const data = new FormData();
                data.append( "function", "getIncomeTypes");
                data.append( "incomeTypePrefix", "SBP");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.tradeCategories =res.data.data.incomeTypes
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getTradeSubCategory(){
                this.tradeSubCategories.splice(0)
                const data = new FormData();
                data.append("function", "getFeesAndCharges");
                data.append("incomeTypeId", this.tradeCategory.incomeTypeId);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.tradeSubCategories =res.data.data.feesAndCharges
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },

        }
    }
</script>

<style scoped>

</style>