<template>
  <div>
    <head>
      <!-- App favicon -->
      <link rel="shortcut icon" href="assets/images/favicon.svg">

      <link href="assets/libs/select2/css/select2.min.css" rel="stylesheet" type="text/css" />

      <link href="assets/libs/bootstrap-datepicker/css/bootstrap-datepicker.min.css" rel="stylesheet" type="text/css">
      <link href="assets/libs/spectrum-colorpicker2/spectrum.min.css" rel="stylesheet" type="text/css">
      <link href="assets/libs/bootstrap-timepicker/css/bootstrap-timepicker.min.css" rel="stylesheet" type="text/css">
      <link href="assets/libs/bootstrap-touchspin/jquery.bootstrap-touchspin.min.css" rel="stylesheet" type="text/css" />
      <link rel="stylesheet" href="assets/libs/%40chenfengyuan/datepicker/datepicker.min.css">



      <!-- Bootstrap Css -->
      <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
      <!-- Bootstrap select styling -->
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
      <!-- Icons Css -->
      <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
      <!-- App Css-->
      <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
      <!-- custom Css-->
      <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />





    </head>

    <body data-sidebar="dark">

    <!-- <body data-layout="horizontal" data-topbar="dark"> -->
    <!-- Loader -->
    <!--  <div id="preloader">
        <div id="status">
          <div class="spinner-chase">
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
            <div class="chase-dot"></div>
          </div>
        </div>
      </div>-->
    <!-- Begin page -->

    <!-- Begin page -->
    <div id="layout-wrapper">

      <NavigationBar/>

      <!-- Editing bill details -->
      <div class="modal fade " id="editModal" data-backdrop="static" data-keyboard="false" tabindex="-1 " role="dialog " aria-labelledby="staticBackdropLabel " aria-hidden="true ">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content ">
            <div class="modal-header border-bottom-0 ">
              <h4>Edit Bill item details</h4>
              <button type="button " class="btn-close " data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body ">
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Income Type</label>
                    <select class="form-control selectpicker bg-white" title="Select Traffic offence" data-live-search="true" >
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Account Name</label>
                    <select class="form-control selectpicker bg-white" title="Select Traffic offence" data-live-search="true">
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Sub County</label>
                    <select class="form-control selectpicker bg-white" title="Select Traffic offence" data-live-search="true">
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Ward</label>
                    <select class="form-control selectpicker bg-white" title="Select Traffic offence" data-live-search="true">
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Details</label>
                    <textarea name="" id="" cols="30" rows="3" placeholder="More details" class="form-control"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex">
              <button class="btn btn-primary flex-grow-1">Done</button>
              <button class="btn btn-light" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
      <!-- edditing bill item details -->

      <!-- Offender email address -->
      <div class="modal fade " id="subscribeModal" data-backdrop="static" data-keyboard="false" tabindex="-1 " role="dialog " aria-labelledby="staticBackdropLabel " aria-hidden="true ">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content ">
            <div class="modal-header border-bottom-0 ">
              <button type="button " class="btn-close d-none" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body ">
              <div class="text-center mb-4 ">
                <div class="avatar-md mx-auto mb-4 ">
                  <div class="avatar-title bg-light rounded-circle text-primary h1 ">
                    <i class="mdi mdi-card-account-phone-outline "></i>
                  </div>
                </div>

                <div v-if="message!==null" class="alert alert-danger" role="alert">
                  {{message}}
                </div>


                <div class="row justify-content-center ">
                  <div class="col-xl-10 ">
                    <h4 class="text-primary ">Client's Phone No..</h4>
                    <p class="text-muted font-size-14 mb-4 ">
                      Enter the client's Phone Number to continue.
                    </p>

                    <form>
                      <div class="row ">
                        <div class="col-12 ">
                          <div class="mb-3 ">
                            <label for="digit1-input " class="visually-hidden ">ID Number</label>
                            <input v-model="phoneNumber" type="text " class="form-control form-control-lg text-center two-step " placeholder="Enter Client Phone Number">
                          </div>
                        </div>
                      </div>
                      <button @click="getCustomer()"  type="button" class="btn btn-primary btn-block w-100 ">
                        <i class="bx bx-search-alt-2 font-size-16 align-middle me-2 "></i>
                        Search
                      </button>

                      <!-- this is to be hidden afte rimplimenting it. It s meannt to be shown incase the said user is not yet registered -->
                      <button  data-toggle="modal" data-target="#registerCustomer" data-dismiss="modal" type="button" class="btn btn-light btn-block w-100  mt-3">
                        Register User
                      </button>

                      <button style="display: none" data-toggle="modal" data-dismiss="modal" type="button" id="closeModalSearch" >
                        close
                      </button>

                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Offender's email address -->

      <!-- customer registration -->
      <div class="modal fade " id="registerCustomer" data-backdrop="static" data-keyboard="false" tabindex="-1 " role="dialog " aria-labelledby="staticBackdropLabel " aria-hidden="true ">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content ">
            <div class="modal-header border-bottom-0 ">
              <button type="button " class="btn-close d-none" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body ">
              <div class="text-center mb-4 ">
                <div class="avatar-md mx-auto mb-4 ">
                  <div class="avatar-title bg-light rounded-circle text-primary h1 ">
                    <i class="mdi mdi-account-multiple-plus "></i>
                  </div>
                </div>

                <div v-if="message!==null" class="alert alert-danger" role="alert">
                  {{message}}
                </div>

                <div class="row justify-content-center ">
                  <div class="col-xl-10 ">
                    <h4 class="text-primary text-capitalize">New Client Registration</h4>
                    <p class="text-muted font-size-14 mb-4 ">
                      Fill In the details below to continue
                    </p>

                    <form>
                      <div class="row ">
                        <div class="col-12 ">
                          <div class="mb-3 ">
                            <label for="digit1-input " class="visually-hidden ">Full Names</label>
                            <input v-model="customerName" type="text " class="form-control  text-center two-step " placeholder="Full Names">
                          </div>
                        </div>

                        <div class="col-12 ">
                          <div class="mb-3 ">
                            <label for="digit1-input " class="visually-hidden ">Email</label>
                            <input v-model="email" type="text " class="form-control  text-center two-step " placeholder="Email">
                          </div>
                        </div>

                        <div class="col-12 ">
                          <div class="mb-3 ">
                            <label for="digit1-input " class="visually-hidden ">Phone Number</label>
                            <input v-model="phoneNumber" type="text " class="form-control  text-center two-step " placeholder="Phone Number">
                          </div>
                        </div>
                      </div>

                      <button @click="addCustomers()" type="button" class="btn btn-success btn-block w-100 ">
                        <i class="mdi-account-multiple-plus mdi font-size-16 align-middle me-2 "></i>
                        Register Client
                      </button>
                      <button style="display: none" id="closeAddCustomerModal"  data-dismiss="modal" type="button" class="btn btn-success btn-block w-100 ">
                        close
                      </button>

                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- customer registration -->

      <!-- payment modal -->
      <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header d-none">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                </button>
              </div>

              <div class="payment-panel-parent">

                <div v-if="paymentPanel" class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4 pb-4 pt-4">
                    <img src="assets/images/bills.svg" height="200" alt="">
                  </div>
                  <h4 class="text-black fw-bold">{{message}}</h4>
                  <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
                  <div class="d-flex w-100 pb-2">
                    <button @click="showPaymentMethod()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                      <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                      Receive Payment
                    </button>
                    <a @click="printBill()" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
                  </div>
                </div>

                <div v-if="paymentMethod" class="send-method payment-panel ">
                  <label for="" class="mb-0 pb-0">Payment Method</label>
                  <p><small class="text-muted">How would you like to send this money?</small></p>

                  <div>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                              <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div>
                              <p>
                                A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to the MPESA number you enter below.
                              </p>
                            </div>
                            <div class="form-group">
                              <label for="">MPESA Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="phoneNumber" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Phone No.</label>
                              </div>

                              <button @click="showTransactionSummary('MPESA')" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                Send Payment Request
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                              <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>


                            </div>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div class="form-group">
                              <label for="">Enter The Bank's Receipt Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="paymentData.bankReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Bank Ref No.</label>
                              </div>

                              <button @click="showTransactionSummary('BANK')" class="btn w-100 btn-warning mt-3 btn-bank">
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div v-if="transactionSummary" class="transaction-summary payment-panel">
                  <label for="">Transaction Breakdown</label>
                  <div class="border p-4 rounded ">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="text-muted ">
                          BillO NO.
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{billDetails.billNo}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Bill For
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{billDetails.incomeTypeDescription}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Payment Method
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{paymentData.paymentMode}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Transaction Amount
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          KES {{billDetails.billBalance}}
                        </div>
                      </div>


                      <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                      <div class="col-lg-6 text-uppercase">
                        <div class="text-muted mt-2 font-21">
                          Total
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end text-uppercase">
                        <div class=" text-right text-black font-21 fw-bold">
                          KES {{billDetails.billBalance}}
                        </div>
                      </div>
                    </div>

                    <div class="mpesa-confirmation ">
                      <div v-if="paymentData.paymentMode=='MPESA'">
                        <p class="text-muted mt-2">A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to <strong class="text-black"> phone number ({{ phoneNumber }})</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                          <br>
                          <br>
                          Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>
                      </div>

                      <div>{{message}}  </div>
                      <br>
                      <button @click="makePayment()" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="stk-timer-container d-none justify-content-center align-items-center">
                            <span class="mdi mdi-timer-outline font-16px"></span>
                            <span class="stk-timer px-2"></span>
                          </div>
                          <div class="justify-content-center align-items-center d-flex">
                            <span class="px-2">Send Request</span>
                            <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                          </div>
                        </div>
                      </button>
                    </div>


                  </div>
                </div>

                <div v-if="transactionCompleted" class="text-center d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4">
                    <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                  </div>
                  <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                  <p class="text-muted mb-4"><strong class="text-black">KES {{receiptDetails.receiptAmount}}</strong> has been received for <strong class="text-black">Bill No. {{receiptDetails.billNo}}</strong></p>
                  <button @click="printReceipt()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                    <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                    Print Receipt
                  </button>
                </div>



              </div>
            </div>
            <div class="modal-footer d-flex d-none bill-modal-footer">
              <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i>Previouse</button>
              <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
              <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end of payment modal -->

      <!-- new food hygine -->
      <div class="modal fade" id="new-food-hygene" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-capitalize" id="exampleModalLongTitle">Food hygine Certificate Application</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Business ID</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter Business ID">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Business Name</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter Business Name">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">PIN Number</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="KRA Pin Number">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Plot Number</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter plot Number">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Phone Number</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter phone No.">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Email Address</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="email@email.com">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">PO Box</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter phone No.">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Town</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="email@email.com">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Sub County</label>
                    <select class="form-control selectpicker bg-white" title="Select Traffic offence" data-live-search="true" >
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Ward</label>
                    <select class="form-control selectpicker bg-white" title="Select Traffic offence" data-live-search="true" >
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>

                <div class="col-xl-12 col-sm-12">
                  <div class="mt-4">
                    <h5 class="font-size-14 mb-4 text-capitalize">Check where necessary</h5>
                    <div class="form-check mb-3">
                      <input class="form-check-input" type="radio" name="formRadios" id="formRadios1" checked="">
                      <label class="form-check-label" for="formRadios1">
                        This application has been approved
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="formRadios" id="formRadios2">
                      <label class="form-check-label" for="formRadios2">
                        This application is provisional
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary w-100">Create Certificate</button>
            </div>
          </div>
        </div>
      </div>
      <!-- new food hygiene -->
      <!-- new food handler certiifictae application -->
      <div class="modal fade" id="new-food-handler" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-capitalize" id="exampleModalLongTitle">Food Handler Certificate Application</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">First Name</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter first name">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Other Name(s)</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter Other Names">
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">ID Number</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter ID Number">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Phone Number</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter phone No.">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Email Address</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="email@email.com">
                  </div>
                </div>



                <div class="col-xl-12 col-sm-12">
                  <div class="mt-4">
                    <h5 class="font-size-14 mb-4 text-capitalize">Gender</h5>
                    <div class="d-flex">
                      <div class="form-check mb-3 pr-4">
                        <input class="form-check-input" type="radio" name="Gender" id="gender-male" checked="">
                        <label class="form-check-label" for="gender-male">
                          Male
                        </label>
                      </div>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="Gender" id="gender-female">
                        <label class="form-check-label" for="gender-female">
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Sub County</label>
                    <select class="form-control selectpicker bg-white" title="Select Applicant's subcounty" data-live-search="true" >
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Ward</label>
                    <select class="form-control selectpicker bg-white" title="Select Applicant's Ward" data-live-search="true" >
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary w-100">Create license</button>
            </div>
          </div>
        </div>
      </div>
      <!-- new food handlers certifacate application -->


      <!-- new business application -->
      <div class="modal fade" id="new-business" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-capitalize" id="exampleModalLongTitle">New SBP Application</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div v-if="message!==null" class="alert alert-danger" role="alert">
              {{message}}
            </div>
            <div class="modal-body steps-container">
              <div class="row the-step biz-info active">
                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Select Year</label>

                    <select v-model="fiscalYear" class="form-control bg-white" title="Select ward" data-live-search="true" >
                      <option v-for="(item, index) in years" :value="item.year" :key="index">
                        {{ item.year }}
                      </option>
                    </select>

                  </div>
                </div>
                <div class="col-12">
                  <div class="bg-primary border-2 bg-soft p-3 mb-4">
                    <p class="fw-semibold mb-0 pb-0">Business Owner</p>
                  </div>
                </div>
                <div class="col-8">
                  <div class="mb-3">
                    <label for="" class="form-label">Full Name <span class="text-danger fw-bold">*</span></label>
                    <input v-model="trade.businessDetails.fullNames" type="text" class="form-control" id=""  placeholder="Enter Owner's Full Names">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Owner's ID Number <span class="text-danger fw-bold">*</span></label>
                    <input v-model="trade.businessDetails.ownerID" type="text" class="form-control" id=""  placeholder="Enter Owner's ID Number">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Owner's Phone No. <span class="text-danger fw-bold">*</span></label>
                    <input v-model="trade.businessDetails.ownerPhone" type="text" class="form-control" id=""  placeholder="Enter Owner's Phone No.">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Email</label>
                    <input v-model="trade.businessDetails.ownerEmail" type="email" class="form-control" id=""  placeholder="Owner's Email address">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">KRA Pin</label>
                    <input v-model="trade.businessDetails.kraPin" type="text" class="form-control" id=""  placeholder="Owner's KRA Pin">
                  </div>
                </div>

              </div>

              <div class="row the-step biz-additional-info d-none">

                <div class="col-12">
                  <div class="bg-primary border-2 bg-soft p-3 mb-4">
                    <p class="fw-semibold mb-0 pb-0">Business Details</p>
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label for="" class="form-label">Business Name {{trade.businessDetails.lat}} </label>
                    <input v-model="trade.businessDetails.businessName" type="text" class="form-control" id=""  placeholder="Enter Business Name">
                  </div>
                </div>

                <div class="col-12">
                  <div class="">
                    <div class="card-header bg-white boarder-bottom-1 d-none">
                      <div class="row d-flex justify-content-between">
                        <div class="col-12">
                          <div class="search-box mb-2 me-2">
                            <div class="position-relative">
                              <input type="text" class="form-control bg-light border-light rounded map-searcher" id="pac-inpu" placeholder="Search for location">
                              <i class="bx bx-search-alt search-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="" style="height: 350px;">
                      <div id="mapView" class="w-100 mt-1 h-100 live-map-cont main-map-container"></div>

                    </div>

                  </div>

                  <div class="">
                    <div class="position-relative ">
                      <!-- map serach input box -->
                      <div class="form-group map-searcher mt-3 pt-3">
                        <input type="text" class="form-control form-control-lg text-black" placeholder="Search for a location" id="pac-input">
                        <button title="Clear search results" class="btn btn-transparent border-0 clear-map"><i class="mdi mdi-close d-none"></i></button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Sub County</label>
                    <select v-model="subCounty" class="form-control  bg-white" title="Select Sub County" data-live-search="true" >
                      <option v-for="(item, index) in subCounties" :value="item" :key="index">
                        {{ item.subCountyName }}
                      </option>
                    </select>

                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Ward</label>
                    <select v-model="ward" class="form-control bg-white" title="Select ward" data-live-search="true" >
                      <option v-for="(item, index) in wards" :value="item" :key="index">
                        {{ item.wardName }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Plot No.</label>
                    <input v-model="trade.businessDetails.plotNumber" type="text" class="form-control" id=""  placeholder="Enter Plot No.">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Business Premises Nature</label>

                    <select v-model="trade.businessDetails.buildingOccupancy" class="form-control bg-white" title="Select ward" data-live-search="true" >
                      <option value="Occupies Section In building">Occupies Section In building</option>
                      <option value="Occupies Entire Building">Occupies Entire Building</option>
                      <option value="Mobile Business">Mobile Business</option>
                    </select>

                  </div>
                </div>


                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Floor Number </label>
                    <select v-model="trade.businessDetails.floorNo" class="form-control bg-white" title="Select ward" data-live-search="true" >
                      <option v-for="(item, index) in trade.floors" :value="item.floor" :key="index">
                        {{ item.floor }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Room Number</label>
                    <input v-model="trade.businessDetails.roomNo" type="text" class="form-control" id=""  placeholder="Room No">
                  </div>
                </div>


              </div>

              <div class="row the-step biz-owner d-none">

                <div class="col-12">
                  <div class="bg-primary border-2 bg-soft p-3 mb-4">
                    <p class="fw-semibold mb-0 pb-0">Business contact information</p>
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Company's Email address <span class="text-danger fw-bold"></span></label>
                    <input v-model="trade.businessDetails.businessEmail" type="text" class="form-control" id=""  placeholder="Enter email">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Postal address <span class="text-danger fw-bold">*</span></label>
                    <input v-model="trade.businessDetails.postalAddress" type="email" class="form-control" id=""  placeholder="Enter Postal address *">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Postal Code <span class="text-danger fw-bold">*</span></label>
                    <input v-model="trade.businessDetails.postalCode" type="email" class="form-control" id=""  placeholder="Postal Code">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Company's Phone No. <span class="text-danger fw-bold">*</span></label>
                    <input v-model="trade.businessDetails.businessPhone" type="text" class="form-control" id=""  placeholder="Enter Company's Phone No.">
                  </div>
                </div>


                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Physical Address <span class="text-danger fw-bold">*</span></label>
                    <input v-model="trade.businessDetails.physicalAddress" type="text" class="form-control" id=""  placeholder="Enter physical Address">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Building Name<span class="text-danger fw-bold">*</span></label>
                    <input v-model="trade.businessDetails.buildingName" type="text" class="form-control" id=""  placeholder="Enter Building Name">
                  </div>
                </div>


                <div class="col-12">
                  <div class="bg-primary border-2 bg-soft p-3 mb-4">
                    <p class="fw-semibold mb-0 pb-0">Contact Person</p>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label for="" class="form-label">Contact Person's Full names</label>
                    <input v-model="trade.businessDetails.contactPersonNames" type="text" class="form-control" id=""  placeholder="Enter full Names">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Contact Person's ID No.</label>
                    <input v-model="trade.businessDetails.contactPersonIDNo" type="text" class="form-control" id=""  placeholder="Enter ID No.">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Person's Role</label>



                    <select v-model="trade.businessDetails.businessRole" class="form-control bg-white" title="Select ward" data-live-search="true" >
                      <option value="Secretary">Secretary</option>
                      <option value="Director">Director</option>
                    </select>

                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Contact Person's Email address</label>
                    <input v-model="trade.businessDetails.contactPersonEmail" type="text" class="form-control" id=""  placeholder="ie email@email.com">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Contact Person's mobile number</label>
                    <input v-model="trade.businessDetails.contactPersonPhone" type="text" class="form-control" id=""  placeholder="ie 07....">
                  </div>
                </div>


              </div>

              <div class="row the-step biz-activity d-none">

                <div class="col-12">
                  <div class="bg-primary border-2 bg-soft p-3 mb-4">
                    <p class="fw-semibold mb-0 pb-0">Business Activity information</p>
                  </div>
                </div>



                <div class="col-12">
                  <div class="mb-3">
                    <label for="" class="form-label">Business Categories</label>
                    <select @change="getTradeSubCategory($event)" class="form-control bg-white" title="Select Business Activity" data-live-search="true" >
                      <option value=" ">Select Options</option>
                      <option v-for="(item, index) in trade.tradeCategories" :value="item.incomeTypeId" :key="index">
                        {{ item.incomeTypeDescription }}
                      </option>
                    </select>

                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label for="" class="form-label">Business establishment Type</label>
                    <select @change="selectedTradeSubCategory($event)" class="form-control bg-white" title="Select Sub Categories" data-live-search="true" >
                      <option v-for="(item, index) in trade.tradeSubCategories" :value="JSON.stringify(item)" :key="index">
                        {{ item.feeDescription }} KES {{item.unitFeeAmount}}
                      </option>
                    </select>
                  </div>
                </div>

                <!--  <select @change="selectedFeeAndCharge($event)" class="form-control bg-white" title="Select Traffic offence" data-live-search="true" >
                  <option v-for="(item, index) in feesAndCharges" :value="JSON.stringify(item)" :key="index">
                    {{ item.feeDescription }}
                  </option>
                </select>-->
                <div class="col-6">
                  <div class="mb-3">
                    <label for="" class="form-label">Activity Description <span class="text-danger fw-bold">*</span></label>
                    <input v-model="trade.businessDetails.businessDes" type="text" class="form-control" id=""  placeholder="Eg retail shop for  general goods">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="" class="form-label">Premises Size (M<sup>2</sup>)</label>
                    <input v-model="trade.businessDetails.premiseSize" type="text" class="form-control" id=""  placeholder="Enter Premises Size">
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Vehicle Tonage</label>
                    <select v-model="trade.businessDetails.tonnage" class="form-control bg-white" title="Select ward" data-live-search="true" >
                      <option v-for="(item, index) in trade.tonnage" :value="item.tonnage" :key="index">
                        {{ item.tonnage }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3">
                    <label for="" class="form-label">Number Of Employees</label>
                    <input v-model="trade.businessDetails.numberOfEmployees" type="text" class="form-control" id=""  placeholder="Enter Number Of Employees">
                  </div>
                </div>

                <div class="col-12 mt-4">
                  <div class="bg-warning border-2 bg-soft p-3 mb-4">
                    <p class="mb-0 pb-0">Computed Amount</p>
                    <P><h4 class="fw-semibold mb-0 pb-0">KES {{trade.amount}}</h4></P>

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="d-flex modal-next-prev-container">
                <button type="button" class="btn btn-outline-secondary btn-prev waves-effect waves-effect waves-light me-3">
                  <i class="bx bx-left-arrow-alt font-size-16 align-middle me-2"></i> Previouse
                </button>

                <button type="button" class="btn btn-primary waves-effect waves-effect waves-light btn-next">
                  Next <i class="bx bx-right-arrow-alt font-size-16 align-middle me-2"></i>
                </button>

                <button @click="registerBusiness()" data-dismiss="modal" type="button" class="btn btn-submit btn-success d-none ml-3 ms-3 waves-effect waves-effect waves-light btn-next">
                  <i class="bx bx-check-double font-size-16 align-middle me-2"></i> Submit Application
                </button>


              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- new business application -->

      <!-- new liquor license -->
      <div class="modal fade" id="new-liquore-license" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-capitalize" id="exampleModalLongTitle">Liquore License Application</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Business ID</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter Business ID">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Business Name</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter Business Name">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">KRA PIN Number</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="KRA Pin Number">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Plot Number</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter plot Number">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Phone Number</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter phone No.">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Email Address</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="email@email.com">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">PO Box</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter phone No.">
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Town</label>
                    <input type="text" class="form-control" id="formrow-email-input"  placeholder="email@email.com">
                  </div>
                </div>
                <div class="col-12">
                  <div class="mb-3">
                    <label for="formrow-email-input" class="form-label">Physical Address</label>
                    <textarea name="" id="" class="form-control" placeholder="Enter the physical Address" cols="30" rows=""></textarea>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Sub County</label>
                    <select class="form-control selectpicker bg-white" title="Select Traffic offence" data-live-search="true" >
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="mb-3">
                    <label for="formrow-firstname-input" class="form-label">Ward</label>
                    <select class="form-control selectpicker bg-white" title="Select Traffic offence" data-live-search="true" >
                      <option value=" ">Failing to carry and produce a driving license on demand.</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 1-5 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 6-10 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 11-15 KPH</option>
                      <option value=" ">Exceeding speed limit prescribed for class of vehicle by 16-20 KPH</option>
                    </select>
                  </div>
                </div>

                <div class="col-xl-12 col-sm-12">
                  <div class="mt-4">
                    <h5 class="font-size-14 mb-4 text-capitalize">Check where necessary</h5>
                    <div class="form-check mb-3">
                      <input class="form-check-input" type="radio" name="formRadios" id="formRadios1" checked="">
                      <label class="form-check-label" for="formRadios1">
                        This application has been approved
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="formRadios" id="formRadios2">
                      <label class="form-check-label" for="formRadios2">
                        This application is provisional
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary w-100">Submit Application</button>
            </div>
          </div>
        </div>
      </div>



      <!-- ============================================================== -->
      <!-- Start right Content here -->
      <!-- ============================================================== -->
      <div class="main-content">

        <div class="page-content">
          <div class="container-fluid">

            <!-- start page title -->
            <div class="row">
              <div class="col-12">
                <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 class="mb-sm-0 font-size-18 text-capitalize">Create New Bill</h4>

                  <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                      <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                      <li class="breadcrumb-item active">New Bill</li>
                    </ol>
                  </div>

                </div>
              </div>
            </div>
            <!-- end page title -->

            <!-- eTransactions table -->

            <div class="row">
              <div class="col-lg-12">
                <div class="card bill-creater-module">
                  <div class="card-body ">
                    <div class="row">
                      <div class="col-12">
                        <div class="p-4 bg-light mb-4">
                          <div class="row d-flex justify-content-between align-items-center">
                            <div class="col-12 d-flex justify-content-between  align-items-center">
                              <div class="d-fle">
                                <p class="mb-0 fw-semibold text-uppercase pb-0"><a href="#">Client Details</a></p>

                              </div>
                              <div class="">
                                <div class="mb-0 d-flex justify-content-between align-items-center ">
                                  <button class="btn btn-success btn-sm"  data-toggle="modal" data-target="#subscribeModal">Select A different Client</button>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <hr>
                            </div>


                            <div class="col-12">
                              <div class="row">
                                <div class="col-4 col-sm-12 col-md-4 d-none">
                                  <div class="d-flex">
                                    <div class="flex-shrink-0 me-3 align-self-center">
                                      <i class="mdi mdi-card-account-details-outline  h2 text-dark"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                      <p class="text-muted mb-0">ID No.</p>
                                      <h5 class="mb-0 font-14px"><span>12345678</span></h5>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4 col-sm-12 col-md-4">
                                  <div class="d-flex">
                                    <div class="flex-shrink-0 me-3 align-self-center">
                                      <i class="mdi mdi-account  h2 text-dark"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                      <p class="text-muted mb-0">Customer Name</p>
                                      <h5 class="mb-0 font-14px"><span>{{customerName}}</span></h5>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-4 col-sm-12 col-md-4">
                                  <div class="d-flex">
                                    <div class="flex-shrink-0 me-3 align-self-center">
                                      <i class="mdi mdi-phone  h2 text-dark"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                      <p class="text-muted mb-0">Phone No.</p>
                                      <h5 class="mb-0 font-14px text-uppercase"><span>{{phoneNumber}}</span></h5>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-4 col-sm-12 col-md-4">
                                  <div class="d-flex">
                                    <div class="flex-shrink-0 me-3 align-self-center">
                                      <i class="mdi mdi-email-outline  h2 text-dark"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                      <p class="text-muted mb-0">Email Address</p>
                                      <h5 class="mb-0 font-14px"><span>{{email}}</span></h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4 col-lg-5">
                        <div class="d-flex justify-items-center align-items-center">
                          <div class="border-1 invoice-form p-4">
                            <h4 class="card-title mb-4">Enter the invoice details beow</h4>

                            <div v-if="message!==null" class="alert alert-danger" role="alert">
                              {{message}}
                            </div>

                            <form>
                              <div class="row tenant-invoice-container invoice-options">

                                <div class="col-12">
                                  <div class="mb-3">
                                    <label for="formrow-firstname-input" class="form-label">Revenue Stream</label>



                                    <select @change="selectRevenue($event)" class="form-select" v-model="stream">
                                      <option :key="`${index}`" :value="item.incomeTypePrefix" v-for="(item, index) in streams"><a class="dropdown-item" href="javascript: void(0);">{{item.stream}}</a></option>
                                    </select>


                                  </div>
                                </div>

                                <div class="col-12 rev-options-cont">

                                  <div class="row plans-rev d-none ">


                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Property LR No.</label>
                                        <input v-model="plotNO" type="text" class="form-control" placeholder="Enter property Number">
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Charge Type</label>

                                        <select @change="selectedIncomeType($event)"  title="-- Income Type --" class="form-control show-tick" data-live-search="true" v-model="billingModule.incomeType">
                                          <option value="">Please Select</option>
                                          <option v-for="(item, index) in billingModule.incomeTypes" :value="JSON.stringify(item)" :key="index">
                                            {{ item.incomeTypeDescription }}
                                          </option>
                                        </select>


                                      </div>
                                    </div>

                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Fee Description</label>
                                        <select @change="selectedFeeAndCharge($event)" class="form-control bg-white" title="Select Traffic offence" data-live-search="true" >
                                          <option value="">Please Select</option>
                                          <option v-for="(item, index) in feesAndCharges" :value="JSON.stringify(item)" :key="index">
                                            {{ item.feeDescription }} KES {{item.unitFeeAmount}}
                                          </option>
                                        </select>
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Sub County</label>

                                        <select v-model="subCounty" class="form-control  bg-white" title="Select Sub County" data-live-search="true" >
                                          <option v-for="(item, index) in subCounties" :value="item" :key="index">
                                            {{ item.subCountyName }}
                                          </option>
                                        </select>


                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Ward</label>

                                        <select v-model="ward" class="form-control bg-white" title="Select Ward" data-live-search="true" >
                                          <option v-for="(item, index) in wards" :value="item" :key="index">
                                            {{ item.wardName }}
                                          </option>
                                        </select>


                                      </div>
                                    </div>

                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Details</label>
                                        <textarea v-model="description" name=""  cols="30" rows="3" placeholder="More details on the incident" class="form-control" spellcheck="false" data-ms-editor="true"></textarea>
                                      </div>
                                    </div>



                                    <div class="col-md-6">
                                      <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">Quantity/Instances</label>
                                        <input v-model="quantity" type="text" class="form-control" id="formrow-email-input"  placeholder="Enter amount to be paid">
                                      </div>
                                    </div>

                                    <div class="col-md-6">
                                      <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">Unit Price</label>
                                        <input v-model="amount"  type="text" class="form-control" id="formrow-email-input"  placeholder="Enter amount to be paid" disabled>
                                      </div>
                                    </div>

                                    <div>
                                      <button @click="addToBillCustomer(feeID,plotNO)" type="button" class="btn btn-primary waves-effect btn-label waves-light w-100"><i class="bx bx-plus label-icon"></i> Bill Item</button>
                                    </div>
                                  </div>

                                  <div class="row others-rev">
                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Income Type</label>

                                        <select @change="selectedIncomeType($event)"  title="-- Income Type --" class="form-control show-tick" data-live-search="true" v-model="billingModule.incomeType">
                                          <option value="">Please Select</option>
                                          <option v-for="(item, index) in billingModule.incomeTypes" :value="JSON.stringify(item)" :key="index">
                                            {{ item.incomeTypeDescription }}
                                          </option>
                                        </select>

                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Account Name</label>
                                        <select @change="selectedFeeAndCharge($event)" class="form-control bg-white" title="Select Traffic offence" data-live-search="true" >
                                          <option v-for="(item, index) in feesAndCharges" :value="JSON.stringify(item)" :key="index">
                                            {{ item.feeDescription }} KES {{item.unitFeeAmount}}
                                          </option>
                                        </select>
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Sub County</label>

                                        <select v-model="subCounty" class="form-control  bg-white" title="Select Sub County" data-live-search="true" >
                                          <option v-for="(item, index) in subCounties" :value="item" :key="index">
                                            {{ item.subCountyName }}
                                          </option>
                                        </select>

                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Ward</label>

                                        <select v-model="ward" class="form-control bg-white" title="Select Ward" data-live-search="true" >
                                          <option v-for="(item, index) in wards" :value="item" :key="index">
                                            {{ item.wardName }}
                                          </option>
                                        </select>

                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Details</label>
                                        <textarea v-model="description" name="" id="" cols="30" rows="3" placeholder="More details" class="form-control"></textarea>
                                      </div>
                                    </div>


                                    <div class="col-md-6 col-sm-12 col-lg-5 mb-4">
                                      <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">Quantity</label>
                                        <input v-model="quantity" type="text" class="form-control" id="formrow-email-input"  placeholder="Quantity">
                                      </div>
                                    </div>

                                    <div class="col-md-6 col-sm-12 col-lg-5 mb-4">
                                      <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">Amount</label>
                                        <input v-model="amount" type="text" class="form-control" id="formrow-email-input"  placeholder="Bill Item amount" readonly>
                                      </div>
                                    </div>

                                    <div>
                                      <button @click="addToBill(description)" type="button" class="btn btn-primary waves-effect btn-label waves-light"><i class="bx bx-plus label-icon"></i> Bill Item</button>
                                    </div>
                                  </div>

                                  <div class="row Rent-rev d-none">
                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">Estate</label>

                                        <select title="-- Select The estate --" class="form-control show-tick" data-live-search="true" v-model="houseItem.estate">
                                          <option v-for="(item, index) in estates" :value="item" :key="index">
                                            {{ item.estateName }}
                                          </option>
                                        </select>

                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">House Type</label>
                                        <select @change="selectedHouseType($event)" title="-- Select Hse Type --" class="form-control show-tick" data-live-search="true">
                                          <option v-for="(item, index) in houseTypes" :value="JSON.stringify(item)" :key="index">
                                            {{ item.houseType }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">House Number</label>
                                        <select class="form-control show-tick" data-live-search="true" v-model="houseItem.houseNo">

                                          <option v-for="(item, index) in houseNumbers" :value="item" :key="index">
                                            {{ item.houseNumber }}
                                          </option>

                                        </select>
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Sub County</label>
                                        <select class="form-control show-tick" data-live-search="true" v-model="subCounty">

                                          <option v-for="(item, index) in subCounties" :value="item" :key="index">
                                            {{ item.subCountyName }}
                                          </option>

                                        </select>

                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Ward.</label>
                                        <select class="form-control show-tick" data-live-search="true" v-model="ward">
                                          <option v-for="(item, index) in wards" :value="item" :key="index">
                                            {{ item.wardName }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>

                                    <div>
                                      <button @click="addToBill(houseItem)" type="button" class="btn btn-primary waves-effect btn-label waves-light"><i class="bx bx-plus label-icon"></i> Bill Itemk</button>
                                    </div>

                                  </div>

                                  <div class="row parking-rev d-none">

                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Parking Category</label>
                                        <select @change="selectRevenue($event)"  class="form-control form-select bg-white" title="Select Car Type" data-live-search="true" v-model="parking.durationCode">
                                          <option value="DAILY">--Select Duration--</option>
                                          <option value="DAILY">Daily Parking</option>
                                          <option value="SEASONAL">Seasonal Parking</option>
                                          <option value="PENANLTY">Parking Penalty</option>
                                          <option value="OFFSTREET">Offstreet Parking</option>
                                        </select>
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Plate Number</label>
                                        <input v-model="parking.numberPlate" type="text" class="form-control" id="" placeholder="Enter Plate Number">
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Car Category</label>

                                        <select title="-- Select Car Type --" class="form-control show-tick" data-live-search="true" v-model="parking.category">
                                          <option v-for="(item, index) in parking.categories" :value="item.category" :key="index">
                                            {{ item.category }}
                                          </option>
                                        </select>

                                      </div>
                                    </div>
                                    <div class="col-12">
                                      <!-- optional fields depending on selected parking type -->
                                      <div class="row">
                                        <div class="col-12 d-none offstreet-duration">
                                          <div class="mb-3">
                                            <label for="formrow-firstname-input" class="form-label">Duration Parked</label>
                                            <input type="text" class="form-control" id="" value="" placeholder="Enter duration in Minutes">
                                          </div>
                                        </div>
                                        <div class="col-12 d-none seasonal-duration">
                                          <div class="mb-3">
                                            <label for="formrow-firstname-input" class="form-label">Parking Duration {{parking.duration}}</label>

                                            <select title="-- Parking Duration --" class="form-control show-tick" data-live-search="true" v-model="parking.duration">
                                              <option v-for="(item, index) in parking.durations" :value="item.duration" :key="index">
                                                {{ item.duration }}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Parking Zone</label>

                                        <select @change="selectZone($event)" title="-- Parking Zone --" class="form-control show-tick" data-live-search="true" v-model="parking.zoneCode">
                                          <option v-for="(item, index) in parking.zones" :value="item.zoneCode" :key="index">
                                            {{ item.zoneCode }}
                                          </option>
                                        </select>

                                      </div>
                                    </div>

                                    <div class="col-md-12 col-sm-12 col-lg-12 mb-4">
                                      <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">Charges</label>
                                        <input v-model="parking.charges" type="text" class="form-control" id="formrow-email-input" placeholder="Bill Item amount" readonly>
                                      </div>
                                    </div>

                                    <div>
                                      <button @click="addToBill(parking.numberPlate)" type="button" class="btn btn-primary waves-effect btn-label waves-light"><i class="bx bx-plus label-icon"></i> Bill Item</button>
                                    </div>
                                  </div>

                                  <div class="row health-rev d-none">
                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label ">Select Service</label>
                                        <select class="form-select health-selector" title="Select Option" data-live-search="true" >
                                          <option value="Food Hygien">Food Hygien</option>
                                          <option value="Food Handler">Food Handler</option>

                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-6 ">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Select Option</label>
                                        <select class="form-select hyginene-selector" title="Select Option" data-live-search="true" >
                                          <option value="New Application">New Application</option>
                                          <option value="Renewal">Renewal</option>
                                        </select>

                                        <select class="form-select handler-selector d-none" title="Select Option" data-live-search="true" >
                                          <option value="New Application">New Application</option>
                                          <option value="Renewal">Renewal</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-12 health-options">
                                      <div class="row food-hygiene-cont">
                                        <div class="col-12 new-hygiene">
                                          <button type="button" class="btn btn-outline-success waves-effect waves-light btn-soft waves-effect waves-light w-sm w-100 p-4 text-uppercase" data-toggle="modal" data-target="#new-food-hygene">
                                            <i class="mdi-food-fork-drink mdi d-block font-size-30 "></i> Start Food Hygiene Application
                                          </button>
                                        </div>
                                        <div class="col-12 d-none renew-hygene">
                                          <div class="mb-3">
                                            <label for="formrow-firstname-input" class="form-label">Business ID</label>
                                            <input type="text" class="form-control" id="" value="" placeholder="Enter Business ID to continue">
                                          </div>
                                          <div class="row">
                                            <div class="col-12">
                                              <div class="mb-3">
                                                <label for="formrow-firstname-input" class="form-label">Business Name</label>
                                                <input  type="text" class="form-control" id=""  disabled placeholder="Searched Business ...">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div class="col-12">
                                              <button type="button" class="btn btn-info waves-effect btn-label waves-light w-100">
                                                <i class="dripicons-plus label-icon"></i> Add to Bill
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row food-handler-cont d-none">
                                        <div class="col-12 new-Handler">
                                          <button type="button" class="btn btn-outline-success waves-effect waves-light btn-soft waves-effect waves-light w-sm w-100 p-4 text-uppercase" data-toggle="modal" data-target="#new-food-handler">
                                            <i class="mdi-chef-hat mdi d-block font-size-30 "></i> Start Food Handler Application
                                          </button>
                                        </div>
                                        <div class="col-12 d-none renew-Handler">
                                          <div class="mb-3">
                                            <label for="formrow-firstname-input" class="form-label">Client's ID No.</label>
                                            <input type="text" class="form-control" id="" value="" placeholder="Enter Business ID to continue">
                                          </div>
                                          <div class="row">
                                            <div class="col-12">
                                              <div class="mb-3">
                                                <label for="formrow-firstname-input" class="form-label">Client's Name</label>
                                                <input type="text" class="form-control" disabled placeholder="Persons Name">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div class="col-12">
                                              <button type="button" class="btn btn-info waves-effect btn-label waves-light w-100">
                                                <i class="dripicons-plus label-icon"></i> Add to Bill
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row trade-rev d-none">
                                    <div class="col-12">
                                      <select  @change="selectRevenue($event)" name="" id="" class="form-select mb-4">
                                        <option value="Register New Business">Register New Business</option>
                                        <option value="Renew License Business">Renew License</option>
                                      </select>
                                    </div>
                                    <div class="col-12">
                                      <div class="row">
                                        <div class="col-12 register-new-business">
                                          <button type="button" class="btn btn-outline-success waves-effect waves-light btn-soft waves-effect waves-light w-sm w-100 p-4 text-uppercase" data-toggle="modal" data-target="#new-business">
                                            <i class="mdi-briefcase-plus-outline mdi d-block font-size-30 "></i> Start New Business Registration
                                          </button>
                                        </div>
                                        <div class="col-12 d-none renew-business">
                                          <div class="mb-3">
                                            <label for="formrow-firstname-input" class="form-label">Business ID</label>
                                            <input v-model="businessID" type="text" class="form-control"  placeholder="Enter Business ID to continue">
                                          </div>
                                          <div class="row">
                                            <div class="col-12">
                                              <div class="mb-3">
                                                <label for="formrow-firstname-input" class="form-label">Business Name oo</label>
                                                <input v-model="trade.businessDetails.businessName" type="text" class="form-control"  disabled placeholder="Searched Business ...">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div class="col-12">
                                              <button @click="addToBill(trade.businessDetails.businessName)" type="button" class="btn btn-info waves-effect btn-label waves-light w-100">
                                                <i class="dripicons-plus label-icon"></i> Add to Bill
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row liquor-rev d-none">
                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label ">Select Service</label>
                                        <select class="form-select liquor-selector" title="Select Option" data-live-search="true" >
                                          <option value="New Application">Licence Application</option>
                                          <option value="License Renewal">Licence Renewal</option>

                                        </select>
                                      </div>
                                    </div>

                                    <div class="col-12 liqur-options">
                                      <div class="row">
                                        <div class="col-12 new-application">
                                          <button type="button" class="btn btn-outline-success waves-effect waves-light btn-soft waves-effect waves-light w-sm w-100 p-4 text-uppercase" data-toggle="modal" data-target="#new-liquore-license">
                                            <i class="mdi-glass-mug-variant mdi d-block font-size-30 "></i> Start Liquor License Application
                                          </button>
                                        </div>
                                        <div class="col-12 d-none renew-liquor">
                                          <div class="mb-3">
                                            <label for="formrow-firstname-input" class="form-label">Business ID {{businessID}}</label>
                                            <input v-model="businessID" type="text" class="form-control"  placeholder="Enter Business ID to continue">
                                          </div>
                                          <div class="row">
                                            <div class="col-12">
                                              <div class="mb-3">
                                                <label for="formrow-firstname-input" class="form-label">Business Name</label>
                                                <input v-model="trade.businessDetails.businessName" type="text" class="form-control" disabled placeholder="Searched Business ...">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="row">
                                            <div class="col-12">
                                              <button type="button" class="btn btn-info waves-effect btn-label waves-light w-100">
                                                <i class="dripicons-plus label-icon"></i> Add to Bill
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row landrate-rev d-none">
                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Property Number</label>
                                        <input type="text" class="form-control" placeholder="Enter property Number">
                                      </div>
                                    </div>

                                    <div class="col-12">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Owner's Name</label>
                                        <input type="text" class="form-control" placeholder="The names" readonly>
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Annual Landrate</label>
                                        <input type="text" class="form-control" placeholder="Search first" readonly>
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Arreas</label>
                                        <input type="text" class="form-control" placeholder="Search first" readonly>
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Penalties</label>
                                        <input type="text" class="form-control" placeholder="Search first" readonly>
                                      </div>
                                    </div>

                                    <div class="col-6">
                                      <div class="mb-3">
                                        <label for="formrow-firstname-input" class="form-label">Total Balance</label>
                                        <input type="text" class="form-control" placeholder="Search first" readonly>
                                      </div>
                                    </div>

                                    <div class="col-md-12">
                                      <div class="mb-3">
                                        <label for="formrow-email-input" class="form-label">Amount</label>
                                        <input type="text" class="form-control" id="formrow-email-input"  placeholder="Enter amount to be paid">
                                      </div>
                                    </div>

                                    <div>
                                      <button type="button" class="btn btn-primary waves-effect btn-label waves-light"><i class="bx bx-plus label-icon"></i> Bill Item</button>
                                    </div>
                                  </div>

                                </div>

                              </div>

                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-8 col-lg-7">
                        <div class="d-flex justify-items-center align-items-center ">
                          <div class="border-1 invoice-table p-4 w-100">
                            <h4 class="card-title mb-4">Created Bill Details</h4>
                            <div class="row">
                              <div class="col-12">
                                <div class="table-responsive border-1">
                                  <table class="table table-nowrap  vertical-align-middle table-sm">
                                    <thead class="bg-light">
                                    <tr>
                                      <th style="width: 70px;">No.</th>
                                      <th>Details</th>
                                      <th class="text-end">Amount</th>
                                      <th class="" style="width: 150px;"></th>
                                    </tr>
                                    </thead>
                                    <tfoot class="bg-light">
                                    <tr>
                                      <td colspan="2" class="border-0 text-end text-right">
                                        <strong>Total</strong>
                                      </td>
                                      <td class="border-0 text-end">
                                        <h5 class="m-0 text-uppercase fw-bold">KES {{totalAmount}}</h5>
                                      </td>
                                      <td>

                                      </td>
                                    </tr>
                                    </tfoot>
                                    <tbody v-for="(item,index) in billItem" :key="`item-${index}`">
                                    <tr  title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                      <td>{{index+1}}</td>
                                      <td>
                                        <p class="the-bill-item mb-0 p-0 m-0">{{item.incomeTypeId}} {{item.feeDescription}}</p>
                                      </td>

                                      <td class="text-end">KES {{item.unitFeeAmount}}</td>
                                      <td class="text-right">
                                        <button title="Edit Item" type="button" data-toggle="modal" data-target="#editModal"  class="btn btn-link waves-effect"><i class="mdi mdi-pencil font-size-16 align-middle me-2"></i></button>
                                        <button  title="View Item" type="button" class="btn btn-link waves-effect"><i class="mdi mdi-eye-outline font-size-16 align-middle me-2"></i></button>
                                        <button @click="selectedIndex(item,index)" data-target="#remove-bill-item" title="Remove Item" type="button" class="btn btn-link waves-effect text-danger"><i class="mdi mdi-close font-size-16 align-middle me-2"></i></button>
                                      </td>
                                    </tr>

                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <button @click="generateBill()" type="button" data-toggle="modal" data-target="#payment-modal" class="btn btn-success waves-effect btn-label waves-light"><i class="bx bx-plus label-icon"></i> Create Bill</button>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- end card body -->
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>

            <!-- end row -->
          </div>
          <!-- container-fluid -->
        </div>
        <!-- End Page-content -->


        <footer class="footer ">
          <div class="container-fluid ">
            <div class="row ">
              <div class="col-sm-6 ">
                <!--              <script>
                                document.write(new Date().getFullYear())
                              </script> © RevenueSure-->
              </div>
              <div class="col-sm-6 ">
                <div class="text-sm-end d-sm-block ">
                  A product of county
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
    </body>

  </div>
</template>

<script>
  /* eslint-disable */
  import NavigationBar from "@/components/Navigation.vue";
  import {execute,biller,rent,parking,trade} from "@/api";
  import $ from 'jquery'

  import Multiselect from 'vue-multiselect';
  import 'vue-select/dist/vue-select.css'; // Import the CSS styles
  import { ref } from 'vue';
  import vSelect from 'vue-select';
  //npm install vue-multiselect@^2.1.6 --force



  export default {
    name: "BillNew",
    components: {NavigationBar,Multiselect,vSelect},

    data(){
      const selectedValue = ref(null);
      const options = [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Structural Plans for 046m2 -93m2   Each', value: 'Structural Plans for 046m2 -93m2 -- Eac' },
        { label: 'Building approval fees for 046m2 - 093m2 ', value: 'Building approval fees for 046m2 - 093m2 ' },
        { label: 'Application fees for industries/factories ', value: 'Application fees for industries/factories ' },
        { label: 'Building approval fees for 046m2 - 093m2 ', value: 'Building approval fees for 046m2 - 093m2 ' },
        { label: 'Plot demarcation fe', value: 'Plot demarcation fe' },
      ];

      return{

        selectedValue,
        options,

        feeID:'',
        plotNO:'',
        fiscalYear:'',
        quantity: 1,
        billGenerated: {
          billNo: null,
          payBillNo: null,
          amount: null,
          success: true
        },
        confirmCash: false,
        phoneNumber:'',
        customerName:'',
        email:'',
        transactionCompleted: false,
        transactionSummary: false,
        paymentPanel: true,
        paymentMethod: false,
        billDetails:{
          id:'',
          billNo:'',
          incomeTypeID:'',
          incomeTypeDescription:'',
          costCenterNo:'',
          accountNo:'',
          feeID:'',
          detailAmount:'',
          receiptAmount:'',
          billBalance:'',
          wardID:'',
          subCountyID:'',
          status:'',
          dateCreated:''
        },
        billInfo:[],
        briefDescription:'',
        subCounty:{},
        ward:{},
        houseItem:{
          estate:null,
          houseType:{
            id: null,
            houseTypeCode:null,
            houseType: null
          },
          houseNo:{
            id: null,
            houseNumberCode: null,
            houseNumber:null,
            houseTypeCode: null,
            houseType: null,
            estateCode: null,
            estateName: null
          },
        },
        revenueStreamItem:{},
        selectedBillItem:{},
        itemIndex:'',
        description:'',
        amount:0,
        unitFeeAmount:0,
        wardID:'',
        subCountyID:'',
        houseNumberCode:'',
        houseTypeCode:'',
        estateCode:'',
        estates:[
          {
            id: "",
            estateCode: "",
            estateName: ""
          }
        ],
        houseTypes:[{
          id: "",
          houseTypeCode: "",
          houseType: ""
        }],
        houseNumbers:[{
          id: "",
          houseNumberCode: "",
          houseNumber: "",
          houseTypeCode: "",
          houseType: "",
          estateCode: "",
          estateName: ""
        }],
        subCounties:[{
          subCountyID: "",
          subCountyName: ""
        }],
        wards:[{
          wardID: "",
          wardName: ""
        }],
        billItem:[],
        feesAndChargeItems:[{
          feeId: null,
          feeDescription: null,
          unitOfMeasure: null,
          unitFeeAmount: null,
          accountNo: null,
          incomeTypeId: null,
          feeType: null,
          accountDesc:null ,
          costCenterNo:null,
          zone: null,
          typeDescription: null,
          customer: null
        }],
        feesAndCharges:[{
          feeId: null,
          feeDescription: null,
          unitOfMeasure: null,
          unitFeeAmount: null,
          accountNo: null,
          incomeTypeId: null,
          feeType: null,
          accountDesc:null ,
          costCenterNo:null,
          zone: null,
          typeDescription: null
        }],
        totalAmount:0,
        paymentData: {
          bankReceiptNo: null,
          accNo: null,
          payBillNo: null,
          amount: null,
          phoneNumber: null,
          success: false,
          paymentMode:null
        },
        timer: null,
        randomNumber: null,
        receiptDetails: {
          id: '',
          receiptNo: null,
          billNo: '',
          detailAmount: '',
          receiptAmount: '',
          billBalance: '',
          costCenterNo: '',
          accountNo: '',
          incomeTypeDescription: '',
          feeID: '',
          wardID: '',
          subCountyID:'',
          currency: '',
          source: '',
          transactionCode: '',
          paidBy: '',
          dateCreated: '',
          dateModified: '',
          createdBy: '',
          modifiedBy: '',
          isActive: '',
          status: ''
        },
        receiptInfos: [
          {
            id: '',
            receiptNo: '',
            billNo: '',
            billTotal: '',
            receiptAmount: '',
            billBalance: '',
            customer: '',
            clientPhoneNo: '',
            description: '',
            receiptDate: '',
            printCount: '',
            wardID: '',
            subCountyID: '',
            dateCreated: '',
            dateModified: '',
            createdBy: '',
            modifiedBy: '',
            printedBy: '',
            updated: '',
            isActive: '',
            status: ''
          }],
        //Billing Module
        billingModule:{
          incomeType:{
            incomeTypeId: null,
            incomeTypeDescription:null,
            incomeTypePrefix: null,
            fundDescription: null,
            status: null
          },
          incomeTypes:[{
            incomeTypeId: null,
            incomeTypeDescription:null,
            incomeTypePrefix: null,
            fundDescription: null,
            status: null}]
        },
        selectedStream:'Others',
        //Parking Module
        parking:{
          zones:[],
          categories:[],
          durations:[],
          category:null,
          zoneCode:null,
          numberPlate:null,
          durationCode:null,
          charges: null
        },
        message:null,

        //Trade
        businessID:null,
        trade:{
          amount: null,
          tonnage:[],
          floors:[],
          tradeCategories:[ {
            incomeTypeId: null,
            incomeTypeDescription: null,
            incomeTypePrefix: null,
            fundDescription: null,
            status: null,
            departmentID: null,
            zoneCategoryID: null,
            zoneCategory: null
          }],
          tradeSubCategories:[
            {
              feeId: null,
              feeDescription: null,
              unitOfMeasure: null,
              unitFeeAmount: null,
              accountNo: null,
              incomeTypeId: null,
              feeType: null,
              accountDesc: null,
              costCenterNo: null,
              zone: null,
              typeDescription: null,
              prorated: null,
              duration: null
            }
          ],

          businessDetails:{
            id: null,
            businessID: null,
            businessName: null,
            subCountyID: null,
            subCountyName: null,
            wardID: null,
            wardName: null,
            plotNumber: null,
            physicalAddress: null,
            buildingName: null,
            buildingOccupancy: null,
            floorNo: null,
            roomNo: null,
            premiseSize: null,
            numberOfEmployees: null,
            tonnage : null,
            businessDes: null,
            businessCategory: null,
            businessSubCategory : null,
            incomeTypeID : null,
            feeID : null,
            businessEmail: null,
            postalAddress : null,
            postalCode: null,
            businessPhone: null,
            contactPersonNames: null,
            contactPersonIDNo: null,
            businessRole: null,
            contactPersonPhone: null,
            contactPersonEmail: null,
            fullNames: null,
            ownerID: null,
            ownerPhone: null,
            ownerEmail: null,
            kraPin: null,
            createdBy: null,
            createdByIDNo: null,
            dateCreated: null,
            lat : 0.0,
            lng : 0.0
          }
        },
        streams:[
          {
            id: null,
            incomeTypePrefix: null,
            stream: null
          },
        ],

      }
    },
    watch:{
      subCounty: function () {
        //Trade demographics
        this.trade.businessDetails.subCountyName = this.subCounty.subCountyName
        this.trade.businessDetails.subCountyID = this.subCounty.subCountyID

        this.getWards()
      },
      ward: function () {
        //Trade demographics
        this.trade.businessDetails.wardName = this.ward.wardName
        this.trade.businessDetails.wardID = this.ward.wardID

      },
      businessID: function () {
        if(this.businessID ===null || this.businessID === ""){

        }else {
          this.getBusiness(true)
        }

      },
      quantity: function () {
        this.amount = this.quantity *  this.unitFeeAmount;

      }
    },
    mounted() {
      this.getMainStreams()
      this.getYears()
      this.getSubCounties()
      this.getFloor()
      this.getTonnage()

      /*const modal = new
      bootstrap.Modal(document.getElementById('subscribeModal'));
      modal.show();*/
      this.initMap()

      var button = document.querySelector(".btn.btn-success.btn-sm");
      button.click();
    },
    methods:{

      //TRADE
      getBusiness(renewal){
        const data = new FormData();
        data.append("function", "getBusiness");
        data.append("businessID", this.businessID);
        execute(data,trade)
                .then((res) =>{
                  if (res.data.success) {
                    this.feesAndChargeItems.splice(0)
                    this.trade.businessDetails = res.data.data.business
                    this.subCounty.subCountyID = this.trade.businessDetails.subCountyID
                    this.subCounty.subCountyName =  this.trade.businessDetails.subCountyName
                    this.ward.wardID =  this.trade.businessDetails.wardID
                    this.ward.wardName = this.trade.businessDetails.wardName
                    //Set fee and charge

                    this.feesAndChargeItems = res.data.data.feesAndCharges

                    this.feesAndChargeItems = this.feesAndChargeItems.map(item => {
                      if (item.feeId === this.trade.businessDetails.feeID) {
                        item.customer = "Business ID: " + this.trade.businessDetails.businessID;
                      }else {
                        item.customer =""
                      }
                      return item;
                    });


                    if(!renewal){
                      this.addToBill(this.trade.businessDetails.businessName)
                    }

                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      registerBusiness(){

        this.trade.businessDetails.createdByIDNo = sessionStorage.getItem("idNo")
        this.trade.businessDetails.createdBy = sessionStorage.getItem("names")

        const data = new FormData();
        data.append("function", "registerBusiness");
        data.append("business",  JSON.stringify(this.trade.businessDetails));
        execute(data,trade)
                .then((res) =>{
                  if (res.data.success) {
                    this.businessID = res.data.data.business.businessID;
                    this.getBusiness(false)
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getFloor(){
        const data = new FormData();
        data.append("function", "getFloor");
        execute(data,trade)
                .then((res) =>{
                  if (res.data.success) {
                    this.trade.floors =res.data.data.floors
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getTonnage(){
        const data = new FormData();
        data.append("function", "getTonnage");
        execute(data,trade)
                .then((res) =>{
                  if (res.data.success) {
                    this.trade.tonnage =res.data.data.tonnage
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getTradeCategory(){
        this.message =null
        const data = new FormData();
        data.append( "function", "getIncomeTypes");
        data.append( "incomeTypePrefix", "SBP");
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {
                    this.trade.tradeCategories =res.data.data.incomeTypes
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getYears(){
        const data = new FormData();
        data.append("function", "getYears");
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {
                    this.years =res.data.data.years
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getTradeSubCategory(event){
        this.message =null
        const data = new FormData();
        this.trade.businessDetails.incomeTypeID = event.target.value
        data.append("function", "getFeesAndCharges");
        data.append("incomeTypeId", event.target.value);
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {
                    this.trade.tradeSubCategories =res.data.data.feesAndCharges
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      selectedTradeSubCategory(event){
        //set fee and charges
        this.selectedFeeAndCharge(event)

        const data = JSON.parse(event.target.value)
        this.trade.businessDetails.businessCategory = data.accountDesc
        this.trade.businessDetails.businessSubCategory =  data.feeDescription
        this.trade.amount =  data.unitFeeAmount
        this.trade.businessDetails.feeID = data.feeId;

      },

      //PARKING
      getZones(){
        const data = new FormData();
        data.append("function", "getZones");
        execute(data,parking)
                .then((res) =>{
                  if (res.data.success) {
                    this.parking =res.data.data
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getRate(){
        this.parking.charges = null
        this.message = null
        const data = new FormData();
        data.append("function", "getFeesAndCharges");
        data.append("zoneCode", this.parking.zoneCode);
        data.append("categoryCode", this.parking.category);
        data.append("durationCode", this.parking.durationCode);
        execute(data,parking)
                .then((res) =>{
                  if (res.data.success) {
                    this.parking.charges  =res.data.data.total.amount
                    this.feesAndChargeItems = res.data.data.feesAndCharges
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      selectZone(){
        this.getRate()
      },

      //BILLER
      addToBillCustomer(feeId,customer){

        this.feesAndChargeItems = this.feesAndChargeItems.map(item => {
          if (item.feeId === feeId) {
            item.customer = customer;
          }else {
            item.customer =""
          }
          return item;
        });
        this.addToBill(this.phoneNumber);
      },
      selectedFeeAndCharge(event){
        const data = JSON.parse(event.target.value)
        this.amount = data.unitFeeAmount
        this.feeID = data.feeId
        this.unitFeeAmount = data.unitFeeAmount
        this.feesAndChargeItems = []
        this.feesAndChargeItems.push(data)
      },
      selectedHouseType(event){
        const houseType = JSON.parse(event.target.value)
        this.houseItem.houseType = houseType
        console.log('Error: ', this.houseItem.houseType.houseTypeCode);
        this.getFeesAndCharges()
        this.getHouseNumbers()
      },
      selectedIncomeType(event){
        const value = event.target.value
        const result = JSON.parse(value);
        this.getFeesAndChargesBilling(result.incomeTypeId)
      },
      getIncomeTypes(incomeTypePrefix){
        this.message =null
        this.billingModule.incomeTypes.splice(0)
        this.feesAndCharges.splice(0)
        const data = new FormData();
        data.append("function", "getIncomeTypes");
        data.append("incomeTypePrefix", incomeTypePrefix);
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {
                    this.billingModule.incomeTypes =res.data.data.incomeTypes
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getFeesAndChargesBilling(incomeTypeId){
        this.message =null
        this.feesAndCharges.splice(0)
        const data = new FormData();
        data.append("function", "getFeesAndCharges");
        data.append("incomeTypeId",incomeTypeId);
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {
                    this.feesAndCharges = res.data.data.feesAndCharges
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },

      initMap() {

        //closing marker details

        $('.close-aside').on('click', function () {
          $(this).parent().parent().addClass('left-100');
          $('.ma-backdrop').addClass('d-none');
          // marker.setAnimation(null);
          alert('hhh')
          // removeMarkers();
        });


        //the map options

        const latitude = 0.04986230310348805;
        const longitude = 37.64667110366901;

        var options = {
          zoom: 15,
          center: {lat: latitude, lng: longitude}
        }


        //new map
        var map = new google.maps.Map(document.getElementById('mapView'), options);

        var gmarkers = [];

        //listen for click on  map

        // the smooth zoom function not in use
        function  numberFormat(num) {
          const britishNumberFormatter = new Intl.NumberFormat("en-GB");
          return britishNumberFormatter.format(num)
        }

        function smoothZoom(map, max, cnt) {
          if (cnt >= max) {
            return;
          } else {
            z = google.maps.event.addListener(map, 'zoom_changed', function (event) {
              google.maps.event.removeListener(z);
              smoothZoom(map, max, cnt + 1);
            });
            setTimeout(function () {
              map.setZoom(cnt)
            }, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
          }
        }

        google.maps.event.addListener(map, 'dblclick', function (event)  {


          //Remove Markers
          for (var i = 0; i < gmarkers.length; i++) {
            gmarkers[i].setMap(null);
          }

          //add marker

          addMarker({coords: event.latLng});

          //get value of clicked coodinates

          //stores coodinates of selected area
          var newCoords = event.latLng;

          // return clicked area coods
          console.log(event.latLng);

          console.log(newCoords.toString());
          newCoords = newCoords.toString();
          var Latitude;
          var longitude;
          longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
          Latitude = newCoords.substring(0, newCoords.indexOf(','));
          Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);

          longitude = longitude.substring(0, longitude.indexOf(')'));
          console.log("Lat:"+Latitude);
          console.log("long:"+longitude);
          this.trade.businessDetails.lat = Latitude
          this.trade.businessDetails.lng = longitude

          //reverse geocoding function
          //usses clicked coodinates to get the newly clicked llocation

          smoothZoom(map, 12, map.getZoom());

          map.setCenter(new google.maps.LatLng(Latitude, longitude));

          reverseGeocoding(Latitude, longitude);

          //opens the side bar form
          $('#newPoint').removeClass('left-100').siblings().addClass('left-100');

        }.bind(this));

        //remove marker function
        function removeMarkers() {
          for (let i = 0; i < gmarkers.length; i++) {
            gmarkers[i].setMap(null);
          }
        }
        //add marker function
        function addMarker(props,item,color) {
          //add marker
          var marker = new google.maps.Marker({
            position: props.coords,
            map: map,
            animation: google.maps.Animation.DROP,
            // icon:props.iconImage
          });




          if (!props.content) {
            gmarkers.push(marker);

          }

          if (props.iconImage) {
            //set icon image if its there
            marker.setIcon(props.iconImage);

          }

          // check if there is content
          if (props.content) {
            //set icon image if its there

            var infowindow = new google.maps.InfoWindow({
              content: props.content
            });

            marker.addListener('mouseover', function (e) {

              infowindow.open(map, marker);

              var newCoords = e.latLng;
              newCoords = newCoords.toString();
              // alert(newCoords);

              var Latitude;
              var longitude;
              longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
              Latitude = newCoords.substring(0, newCoords.indexOf(','));
              Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);
              longitude = longitude.substring(0, longitude.indexOf(')'));


              this.trade.businessDetails.lat = Latitude
              this.trade.businessDetails.lng = longitude

              reverseGeocoding(Latitude, longitude);
              // alert("mouse hovered");

            });

            marker.addListener('click', (e) => {
              toggleBounce(marker);

              this.business = item
              this.color = color

              var newCoords = e.latLng;
              newCoords = newCoords.toString();
              // alert(newCoords);

              var Latitude;
              var longitude;
              longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
              Latitude = newCoords.substring(0, newCoords.indexOf(','));
              Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);
              longitude = longitude.substring(0, longitude.indexOf(')'));

              reverseGeocoding(Latitude, longitude);


              // alert(iconImage);
              // alert( e.latLng);

              //position of the clicked marker . the longitude and latitude
              var pointPosition = e.latLng;

              //gettting the image

              //initializing image as empty
              var theImage = "assets/images/billboard-ads/no-add.jpg";

              console.log(infowindow.content.toString())

              //checking if the content has an image
              if (infowindow.content.includes("<img")) {
                //if it has image assign mage to

                theImage = infowindow.content.substring(infowindow.content.indexOf('src="') + 5);
                //the image url
                theImage = theImage.substring(0, theImage.indexOf('"'));


              }

              var theContent = infowindow.content.substr(18);
              var theContent = theContent.substring(0, theContent.indexOf('<'));

              //the group of markers eg car,incident,collection points etc
              var theGroup = theContent.substring(0, theContent.indexOf('|'));

              //the unique identifier of the clicked item eg number plate
              var uniqueIdentifier = theContent.substring(theContent.lastIndexOf("|") + 1);

              var theCanvas = new bootstrap.Offcanvas(offcanvasExample)
              theCanvas.show()


            });

            // close content when mouse exits
            marker.addListener('mouseout', function () {
              infowindow.close(map, marker);

            });

          }
        }

        var locationForm = document.getElementById('mapView');

        //listen for submit
        locationForm.addEventListener('submit', geocode);

        function geocode(e) {
          e.preventDefault();
          var location = document.getElementById('location-input').value;
          axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
              address: location,
              key: 'AIzaSyAGPbYfcYqdbSBeXdIpH5iWrznfU886Qk8'
            }


          })
                  .then(function (response) {
                    // log full response
                    console.log(response);

                    //formated address
                    var formattedAddress = response.data.results[0].formatted_address;
                    console.log(formattedAddress);

                    var formattedAddressOutput = `
              <ul class="list-group">
                <li class="list-group-item">${formattedAddress}</li>
              </ul>
            `;

                    // Address components
                    var addressComponents = response.data.results[0].address_components;
                    var addressComponentsOutput = '<ul class="list-group">'
                    for (var i = 0; i < addressComponents.length; i++) {
                      addressComponentsOutput += `
                    <li class="list-group-item">
                        <strong>${addressComponents[i].types[0]}</strong>: ${addressComponents[i].long_name}
                    </li>
                `;
                    }
                    addressComponentsOutput += '</ul>'

                    //geometry
                    var lat = response.data.results[0].geometry.location.lat;
                    var lng = response.data.results[0].geometry.location.lng;

                    var geometryOutput = `
              <ul class="list-group">
                <li class="list-group-item"><strong>Latitude:</strong> :${lat}</li>
                <li class="list-group-item"><strong>Longitude:</strong> :${lng}</li>
              </ul>
            `;

                    //output to APP
                    document.getElementById('formatted-address').innerHTML = formattedAddressOutput;
                    document.getElementById('address-components').innerHTML = addressComponentsOutput;
                    document.getElementById('geometry').innerHTML = geometryOutput;
                  })
                  .catch(function (error) {
                    console.log(response);
                  });
        }
        //make the marker bounce
        function toggleBounce(marker) {
          if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
          } else {
            marker.setAnimation(google.maps.Animation.BOUNCE);
            setTimeout(function () {
              marker.setAnimation(null);
            }, 750);
          }
        }
        // reverse geo coding
        function reverseGeocoding(Latitude, longitude) {
          const KEY = "AIzaSyAGPbYfcYqdbSBeXdIpH5iWrznfU886Qk8";
          const LAT = -1.270102;
          const LNG = 36.8589333;
          let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${Latitude},${longitude}&key=${KEY}`;
          fetch(url)
                  .then(response => response.json())
                  .then(data => {
                    var reverseResultsOutput = '<ul class="list-group">';
                    console.log(data);
                    var numOfResults = data.results.length;

                    var County;
                    var subCounty;
                    var street;
                    var address;
                    var ward;
                    var province;
                    var constituency;


                    for (var num = 0; num < numOfResults; num++) {
                      // let parts=data.results[i].address_components;
                      address = data.results[0].formatted_address;

                      // alert(num);
                      let parts = data.results[num].address_components;
                      parts.forEach(part => {
                        if (part.types.includes("administrative_area_level_2")) {
                          //we found subcounty inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                          subCounty = part.long_name;


                        }
                        if (part.types.includes("country")) {
                          //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo

                          Country = part.long_name;

                        }

                        if (part.types.includes("administrative_area_level_1")) {
                          //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                          County = part.long_name;


                        }
                        if (part.types.includes("administrative_area_level_4")) {
                          //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                          ward = part.long_name;


                        }
                        if (part.types.includes("administrative_area_level_4")) {
                          //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                          ward = part.long_name;


                        }
                        if (part.types.includes("sublocality_level_1")) {
                          //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                          ward = part.long_name;


                        }

                        if (part.types.includes("route")) {
                          //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                          street = part.long_name;


                        }
                        if (part.types.includes("sublocality_level_1")) {
                          //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                          constituency = part.long_name;


                        }

                      });


                    }
                    // alert("ward::"+ward);
                    // alert("street: "+street);
                    // alert("constituency: "+constituency);
                    // alert("subcounty:"+subCounty);

                    $('#newPoint .clicked-ward').text(ward);
                    $('.form-map-locations .clicked-ward').text(ward).addClass('text-black').removeClass('text-danger');
                    ;

                    $('#newPoint .clicked-street').text(street);
                    $('.form-map-locations .clicked-street').text(street).addClass('text-black').removeClass('text-danger');
                    ;

                    $('#newPoint .clicked-subcounty').text(subCounty);
                    $('.form-map-locations .clicked-subcounty').text(subCounty).addClass('text-black').removeClass('text-danger');


                    $('#newPoint .clicked-address').text(address);
                    $('.form-map-locations .clicked-address').text(address).addClass('text-black').removeClass('text-danger');
                    $('.form-map-locations .form-address').val(address);

                    $('.form-map-locations .form-latitude').val(Latitude);
                    $('.form-map-locations .form-longitude').val(longitude);

                    $('.permits-asside .the-clicked-address').text(address);
                    $('.the-clicked-address').text(address);

                    //alert(address);

                    $('#plate .plate-address').text(address);
                    $('#application .plate-address').text(address);

                    $('#plate .plate-street').text(street);
                    $('#application .plate-street').text(street);

                    $('#multi-plate .plate-street').text(street);
                    $('#multi-plate .plate-address').text(address);


                    let parts = data.results[0].address_components;
                    reverseResultsOutput += `
            <li class="list-group-item"><strong>Address: </strong> :${data.results[0].formatted_address}</li>
            `;
                    parts.forEach(part => {
                      // if(part.types.includes("country")){
                      //     //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                      //     reverseResultsOutput+=`
                      //     <ul class="list-group">
                      //         <li class="list-group-item"><strong>Country:</strong> :${part.long_name}</li>
                      //     `;

                      //     document.getElementById('location-cods').innerHTML=reverseResultsOutput;
                      // }

                      if (part.types.includes("administrative_area_level_2")) {
                        //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                        reverseResultsOutput += `

                    <li class="list-group-item"><strong>County: </strong> :${part.long_name}</li>
                `;

                        document.getElementById('location-cods').innerHTML = reverseResultsOutput;
                      }

                      reverseResultsOutput += "</ul>";
                    })
                  })
                  .catch(err => console.warn(err.message));
        }

        //search address starts here
        var map;
        var infowindow;

        var input = document.getElementById("pac-input");
        var searchBox = new google.maps.places.SearchBox(input);
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(input); // Bias the SearchBox results towards current map's viewport.

        map.addListener("bounds_changed", function() {
          searchBox.setBounds(map.getBounds());
        });

        //marker
        var markers = [];
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.

        //this function runs when the search box is clicked
        $('#pac-input').on('click', function() {
          $('.map-info-cont').addClass('left-100');
        });

        //shows the close button on the search box when someone starts to search for a new place
        $('#pac-input').on('keyup', function() {
          removeMarkers();
          $('.clear-map i').removeClass('d-none');
          $('.map-info-cont').addClass('left-100');
        });

        //this function is fired up when the close button is clicked
        // it clears the searched icons plus the search input box
        $('.clear-map').on('click', function() {

          $('.form-map-locations .clicked-subcounty').text("Not Selected").addClass('text-danger').removeClass('text-black');
          $('.form-map-locations .clicked-address').text("Not Selected").addClass('text-danger').removeClass('text-black');
          $('.form-map-locations .clicked-ward').text("Not Selected").addClass('text-danger').removeClass('text-black');
          $('.form-map-locations .clicked-street').text("Not Selected").addClass('text-danger').removeClass('text-black');


          const places = searchBox.getPlaces();
          console.log(places);
          $('.map-info-cont').addClass('left-100');
          $('#pac-input').val("");
          $('.clear-map i').addClass('d-none');
          removeMarkers();
          // Clear out the old markers.

          markers.forEach((marker) => {
            marker.setMap(null);
          });
        });

        searchBox.addListener("places_changed", ()=>{

          const places = searchBox.getPlaces();

          if (places.length == 0) {
            return;
          }

          // Clear out the old markers.
          markers.forEach((marker) => {
            marker.setMap(null);

          });
          markers = [];


          // For each place, get the icon, name and location.
          var bounds = new google.maps.LatLngBounds();

          places.forEach(function(place) {
            if (!place.geometry) {
              console.log("Returned place contains no geometry");
              return;
            }

            //the icons attributes for search results
            const icon = {
              // url: place.icon,//adds unique marker depending on search results
              size: new google.maps.Size(71, 71),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(17, 34),
              scaledSize: new google.maps.Size(25, 25),
            };

            // Create a marker for each place.
            var marker = new google.maps.Marker({
              map: map,
              title: place.name,
              position: place.geometry.location,
              icon: icon,
              description: `<strong>` + place.name + `</strong><br><p class="text-info text-underline text-uppercase">Click Icon To Add Businese's actual location</p>`
            })
            markers.push(marker);

            var infowindow = new google.maps.InfoWindow({
              content: marker.description
            });

            marker.addListener('mouseout', function() {
              infowindow.close(map, marker);

            });

            /*  // add a hover event on the search results markers
              google.maps.event.addListener(marker, "mouseover", function(e) {
                infowindow.open(map, marker);
                infowindow.setContent(data.description);

                var newCoords = e.latLng;
                newCoords = newCoords.toString();
                // alert(newCoords);

                var Latitude;
                var longitude;
                longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
                Latitude = newCoords.substring(0, newCoords.indexOf(','));
                Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);
                longitude = longitude.substring(0, longitude.indexOf(')'));



                reverseGeocoding(Latitude, longitude);


              });
              */
            toggleBounce(marker);

            //   adding marker click event

            google.maps.event.addListener(marker, "click", function(e) {

              var thePlace = place.name;
              var newCoords = e.latLng;

              newCoords = newCoords.toString();



              var Latitude;
              var longitude;
              longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
              Latitude = newCoords.substring(0, newCoords.indexOf(','));
              Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);
              longitude = longitude.substring(0, longitude.indexOf(')'));

              reverseGeocoding(Latitude, longitude);
              $('#newPoint').removeClass('left-100').siblings().addClass('left-100');

              var thePlaceHolder = $('.listview .selected-point-details');

              thePlaceHolder = `
                    <p class="mb-0"><strong>The Place Name</strong></p>
                    <p class="clicked-place">${thePlace}</p>
                    `;
              toggleBounce(marker);

              $('.latLngInput').val(Latitude+','+longitude)



            });

            //   marker click event ends here
            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);

          // console.log(places[0]);
          // console.log("address components");
          // console.log(places[0].address_components[0]);
          // console.log("######## "+places[0].geometry.location);

          this.trade.businessDetails.lat = places[0].geometry.location.lat()
          this.trade.businessDetails.lng = places[0].geometry.location.lng()

        });

        //search address ends here

      },
      //RENTS
      getEstates(){
        const data = new FormData();
        data.append("function", "getEstates");
        execute(data,rent)
                .then((res) =>{
                  if (res.data.success) {
                    this.estates =res.data.data.estates
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getHouseType(){
        const data = new FormData();
        data.append("function", "getHouseType");
        execute(data,rent)
                .then((res) =>{
                  if (res.data.success) {
                    this.houseTypes =res.data.data.houseTypes
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getHouseNumbers(){
        this.houseNumbers =[]
        this.houseItem.houseNo = ''
        const data = new FormData();
        data.append("function", "getHouseNumbers");
        data.append("estateCode", this.houseItem.estate.estateCode);
        data.append("houseTypeCode", this.houseItem.houseType.houseTypeCode);
        execute(data,rent)
                .then((res) =>{
                  if (res.data.success) {
                    this.houseNumbers =res.data.data.houseNumbers
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getFeesAndCharges(){
        const data = new FormData();
        data.append("function", "getFeesAndCharges");
        data.append("estateCode", this.houseItem.estate.estateCode);
        data.append("houseTypeCode", this.houseItem.houseType.houseTypeCode);
        execute(data,rent)
                .then((res) =>{
                  if (res.data.success) {
                    this.amount  =res.data.data.total.amount
                    this.feesAndChargeItems = res.data.data.feesAndCharges
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      selectedIndex:function (item,itemIndex){
        this.selectedBillItem = item
        this.itemIndex = itemIndex
        this.removeFromBill()
      },
      removeFromBill: function (){
        this.totalAmount -= this.billItem[this.itemIndex].unitFeeAmount
        this.billItem.splice(this.itemIndex,1)
      },

      //Main
      selectRevenue(event){
        this.selectedStream = event.target.value
        //alert(this.value);
        if(this.selectedStream==="PLANS"){
          //Planning
          $('.plans-rev').removeClass("d-none").siblings().addClass('d-none')
          this.getIncomeTypes(event.target.value)
        }
        else if(this.selectedStream==="RNT"){
          $('.Rent-rev').removeClass("d-none").siblings().addClass('d-none')
          //RENT
          this.getIncomeTypes(event.target.value)
          this.getEstates()
          this.getHouseType()
          this.getSubCounties()
        }
        else if(this.selectedStream==="PKN"){
          $('.parking-rev').removeClass("d-none").siblings().addClass('d-none')
          this. this.getIncomeTypes(event.target.value)
          this.getZones()
        }
        else if(this.selectedStream==="HEALTH"){//Todo change the code or add HEALTH revenue stream
          $('.health-rev').removeClass("d-none").siblings().addClass('d-none')
        }
        else if(this.selectedStream==="SBP"){
          $('.trade-rev').removeClass("d-none").siblings().addClass('d-none')
          this.getSubCounties()
          this.getTradeCategory()
        }
        else if(this.selectedStream==="Register New Business"){
          $('.register-new-business').removeClass("d-none").siblings().addClass('d-none')

        }
        else if(this.selectedStream==="Renew License Business"){
          $('.renew-business').removeClass("d-none").siblings().addClass('d-none')
        }
        else if(this.selectedStream==="Liquor"){//Todo change the code or add LIQOUR revenue stream
          $('.liquor-rev').removeClass("d-none").siblings().addClass('d-none')
        }
        else if(this.selectedStream==="LR"){
          $('.landrate-rev').removeClass("d-none").siblings().addClass('d-none')
        }
        else if(this.selectedStream==="Others"){

          $('.others-rev').removeClass("d-none").siblings().addClass('d-none')
          //Billing Module
          this.getIncomeTypes()
          this.getSubCounties()

          // $('.rev-options-cont').children('.row').each(function(index) {
          //     $(this).addClass('d-none')
          // });
        }
        else if(this.selectedStream === "SEASONAL"){
          $('.seasonal-duration').removeClass("d-none").siblings().addClass('d-none')
        }
        else if(this.selectedStream ==="DAILY"){
          $('.seasonal-duration').addClass("d-none")
          $('.offstreet-duration').addClass("d-none")
        }
        else if(this.selectedStream ==="OFFSTREET"){
          $('.offstreet-duration').removeClass("d-none").siblings().addClass('d-none')
        }
        else if(this.selectedStream === "PENALTY"){
          $('.offstreet-duration').addClass("d-none")
        }
        else{
          $('.others-rev').removeClass("d-none").siblings().addClass('d-none')
          this.getIncomeTypes(event.target.value)
        }

      },
      getSubCounties(){
        const data = new FormData();
        data.append("function", "getSubCounty");
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {
                    this.subCounties =res.data.data.subCounties
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getWards(){
        this.wards =[]
        this.ward = ''
        const data = new FormData();
        data.append("function", "getWards");
        data.append("subCountyID", this.subCounty.subCountyID);
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {
                    this.wards =res.data.data.wards
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getCustomer(){
        if(this.phoneNumber==''){
          this.message = "Weka phone number bana"
          return
        }
        const data = new FormData();
        data.append("function", "getCustomer");
        data.append("phoneNumber", this.phoneNumber);
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {


                    this.email = res.data.data.customer.email
                    this.customerName = res.data.data.customer.names
                    this.email = res.data.data.customer.email


                    var button = document.querySelector("#closeModalSearch");
                    button.click();

                    this.message = null


                  }else{

                    this.message =res.data.message

                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      addCustomers(){
        if(this.phoneNumber === ''){
          this.message ="Please Enter customer phone number"
          return
        }
        if(this.customerName === ''){
          this.message ="Please Enter customer name"
          return
        }

        const data = new FormData();
        data.append("function", "addCustomers");
        data.append("phoneNumber", this.phoneNumber);
        data.append("email", this.email);
        data.append("names", this.customerName);
        data.append("createdBy", sessionStorage.getItem("names"));
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {

                    var button = document.querySelector("#closeAddCustomerModal");
                    button.click();

                    this.message =null
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      getMainStreams() {
        const data = new FormData();
        data.append('function', 'getMainStreams');
        execute(data, biller)
                .then((res) => {
                  if (res.data.success) {
                    this.streams.splice(0);
                    this.streams = res.data.data.streams;

                    // Preselection logic
                    this.streams.forEach((value, index) => {
                      if (this.zoneCategory === value.stream) {
                        this.stream = this.streams[index];
                      }
                    });
                  } else {
                    this.message = res.data.message;
                  }
                })
                .catch((e) => {
                  this.message = e.message;
                });
      },
      getMainStreams2(){
        const data = new FormData();
        data.append("function", "getMainStreams");
        execute(data,biller)
                .then((res) =>{
                  if (res.data.success) {
                    this.streams.splice(0)
                    this.streams = res.data.data.streams
                    //preselection
                    this.streams.forEach((value, index) => {

                      // console.log(JSON.stringify(this.selectedZone))
                      console.log(`${this.ZoneCategory} ::: ${value.stream}`);

                      if (this.zoneCategory === value.stream) {
                        this.stream = this.streams[index];
                      }
                    });

                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  //alert(e.message);
                  this.message =e.message
                });
      },

      addToBill(revenueStreamItem){

        for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer} of this.feesAndChargeItems) {
          this.totalAmount += parseFloat(unitFeeAmount * this.quantity);

          this.billItem.push({
            fiscalYear: this.fiscalYear,
            typeDescription,
            feeDescription,
            unitFeeAmount: unitFeeAmount * this.quantity,
            accountNo,
            costCenterNo,
            incomeTypeId,
            revenueStreamItem: revenueStreamItem,
            feeId,
            amount:  unitFeeAmount * this.quantity,
            customer: customer,
            zone: sessionStorage.getItem("zone"),
            subCountyID:this.subCounty.subCountyID,
            subCountyName:this.subCounty.subCountyName,
            wardID: this.ward.wardID,
            wardName: this.ward.wardName,
            idNo: sessionStorage.getItem("idNo"),
            phoneNumber: sessionStorage.getItem("phoneNumber"),
            names: sessionStorage.getItem("names"),
            customerPhoneNumber: this.phoneNumber,
            description: this.description,
          });
        }
      },
      generateBill(){

        this.paymentMethod = false;
        this.paymentPanel = true;
        this.transactionSummary = false ;
        this.transactionCompleted = false
        this.message ="Generating Bill...please wait... "
        const data = new FormData();
        data.append("function", "generateBill3");
        data.append("billItem", JSON.stringify(this.billItem));
        execute(data,biller)
                .then((res) =>{
                  this.message =res.data.message
                  if (res.data.success) {
                    this.billDetails = res.data.data.bill.billDetails
                    this.billInfo = res.data.data.bill.billInfo
                    this.paymentData.amount = res.data.data.bill.billDetails.billBalance
                    this.paymentData.accNo = res.data.data.bill.billDetails.billNo
                    this.paymentData.payBillNo = res.data.data.billGenerated.payBillNo
                  }else{
                    this.message =res.data.message
                  }
                })
                .catch((e) => {
                  this.message =e.message
                });
      },
      printBill() {
        localStorage['params'] = JSON.stringify({
          billNo: this.billDetails.billNo
        })
        const routeData = this.$router.resolve({name: 'bill'});
        window.open(routeData.href, '_blank');
      },
      printReceipt() {
        if (this.receiptDetails.receiptNo == null) {
          alert("Try again later");
        } else {
          //  this.$refs.closeReceived.click();
          localStorage['params'] = JSON.stringify({
            receiptNo: this.receiptDetails.receiptNo
          })
          const routeData = this.$router.resolve({name: 'receipt'});
          window.open(routeData.href, '_blank');
        }
      },
      showPaymentMethod(){
        this.paymentMethod = true;
        this.paymentPanel = false;
        this.transactionSummary = false;
        this.transactionCompleted = false
      },
      showTransactionSummary(paymentMode){

        this.paymentData.paymentMode = paymentMode

        if(paymentMode === "MPESA") {
          if (this.phoneNumber == null) {
            alert("Enter phone number");
            return;
          }
        }
        if(paymentMode === "BANK") {
          if (this.paymentData.bankReceiptNo == null) {
            alert("Enter Bank Receipt No");
            return;
          }

        }

        this.paymentMethod = false;
        this.paymentPanel = false;
        this.transactionSummary = true;
        this.transactionCompleted = false
      },
      makePayment() {
        if(this.paymentData.paymentMode === "MPESA") {
          if (this.phoneNumber == null) {
            alert("Enter phone number");
            return;
          }
        }
        if(this.paymentData.paymentMode === "BANK") {
          if (this.paymentData.bankReceiptNo == null) {
            alert("Enter Bank Receipt No");
            return;
          }
        }
        this.message = "Payment Request sent";
        this.paymentData.phoneNumber = this.phoneNumber

        const data = new FormData();
        data.append("function", "makePayment");
        data.append("paymentData", JSON.stringify(this.paymentData));

        let stream = null;
        if (this.paymentData.accNo.startsWith("SBP")) {
          stream = trade;
        }
        if (this.paymentData.accNo.startsWith("PKN")) {
          stream = parking;
        }

        if (this.paymentData.accNo.startsWith("RNT")) {
          stream = rent;
        }

        if (this.paymentData.accNo.startsWith("BLL")) {
          stream = biller;
        }

        execute(data, stream)
                .then((res) => {
                  if (res.data.success) {
                    this.paymentMethod = false;
                    this.paymentPanel = false;
                    //start checking payment
                    this.timer = setInterval(() => {

                      this.checkPayment();
                    }, 3000);
                  } else {
                    this.rate = null;
                    alert(res.data.message);
                  }
                })
                .catch((e) => {
                  alert(e.message);
                });
      },
      checkPayment() {
        this.randomNumber = Math.floor(Math.random() * 3) + 1
        if(this.randomNumber === 1){
          this.message = "Waiting for payment.";
        }
        if(this.randomNumber === 2){
          this.message = "Waiting for payment..";
        }
        if(this.randomNumber ===3){
          this.message = "Waiting for payment...";
        }
        const data = new FormData();
        data.append("function", "checkPayment");
        data.append("accountRef", this.paymentData.accNo);

        execute(data, biller)
                .then((res) => {
                  if (res.data.success) {
                    this.message = null;
                    clearInterval(this.timer);
                    this.receipt = true;
                    this.paymentData.success = false;
                    this.transaction = res.data.data.transaction;
                    this.receiptDetails = res.data.data.receipt.receiptDetails
                    //Close the modal
                    this.transactionSummary = false
                    this.transactionCompleted = true
                  }
                })
                .catch((e) => {
                  alert(e.message);
                });
      }
    }
  }
</script>

<style scoped>

</style>

