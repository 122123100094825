<template>
    <head>

        <meta charset="utf-8" />
        <title>Desk Birdy | Parking Quick Stats</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta content="Rental management system by Nouveta LTD" name="description" />
        <meta content="Kelvin Njuguna" name="author" />
        <!-- App favicon -->
        <link rel="shortcut icon" href="assets/images/favicon.svg">
        <!-- droping file -->
        <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
              type="text/css" />

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
        <!-- Bootstrap select styling -->
        <link rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
        <!-- App Css-->

        <!-- owl.carousel css -->
        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

    </head>

    <body data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">


        <!-- ========== Left Sidebar Start ========== -->

        <NavigationBar/>

        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- tool bar -->
                    <!-- end of toolbar -->
                    <div class="row">
                        <div class="col-lg-12 px-sm-30px">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-sm-12 align-self-center text-capitalize">
                                            <div class="text-lg-left mt-4 mt-lg-0">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-3 col-lg-3 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-warning font-size-20">
                                                                    <i class="mdi mdi-car-multiple text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Saccos's/Sub Groups</p>
                                                            <h5 class="mb-0">{{saccos.length}}</h5>

                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-3 col-lg-3 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-info font-size-20">
                                                                    <i
                                                                            class="mdi-file-document-edit-outline mdi text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2 text-capitalize">
                                                                Registered Vehicles</p>
                                                            <h5 class="mb-0">{{registeredVehicles}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-md-3 col-lg-3 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span class="avatar-title rounded-circle font-size-20">
                                                                    <i class="bx bxs-ship text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Compliant Vehicles</p>
                                                            <h5 class="mb-0">{{compliantVehicles}}</h5>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-12 col-md-3 col-lg-3 mb-5 mb-md-0">
                                                        <div>
                                                            <div class="avatar-sm mb-3">
                                                                <span
                                                                        class="avatar-title rounded-circle bg-danger font-size-20">
                                                                    <i class="mdi mdi-car-off text-white"></i>
                                                                </span>
                                                            </div>
                                                            <p class="text-muted text-truncate mb-2">Uncompliant Vehicles
                                                            </p>
                                                            <h5 class="mb-0">{{uncompliantVehicles}}</h5>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <!-- end row -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12 px-sm-30px">
                            <div class="card">
                                <div class="card-header bg-white border-bottom d-flex justify-content-between">
                                    <div>
                                        <h4 class="card-title">Registered Sacco's/Sub Groups</h4>
                                    </div>
                                    <div>
                                        <a v-if="getRight('CREATE SACCO')" @click="gotTo('parking-fleet-new')" href="javascript: void(0);" type="button" class="btn btn-primary waves-effect waves-light">
                                            <i class="bx bx-plus font-size-16 align-middle me-2"></i> Add Sacco/Sub Group
                                        </a>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table align-middle table-striped table-nowrap table-hover dt-responsive contacts-table" id="">
                                            <thead class="table-dark">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Sacco/Sub Group</th>
                                                <th>Sub County</th>
                                                <th scope="col">Ward</th>
                                                <th scope="col" class="">Uncompliant Vehicles</th>
                                                <th scope="col" class="">Compliant Vehicles</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody class="text-capitalize">
                                            <tr :key="index" :value="item" v-for="(item, index) in saccos">
                                                <th>{{index+1}}.</th>
                                                <th>{{item.saccoName}}</th>
                                                <td class="text-capitalize">{{item.subCountyName}}</td>
                                                <td>{{item.wardName}}</td>
                                                <th>
                                                    <span class="text-danger">{{getUnCompliant(item.total,item.compliant)}}</span>
                                                </th>
                                                <th>
                                                    <span class="text-success">{{item.compliant}}</span>
                                                </th>
                                                <th>
                                                    <span class="text-black">{{item.total}}</span>
                                                </th>
                                                <td class="text-right">
                                                    <button v-if="getRight('PRINT STICKER')" @click="printSticker(item)"  class="btn btn-warning btn-sm" style="margin-right: 10px">Stickers</button>

                                                    <button v-if="getRight('ADD VEHICLE TO SACCO')" @click="selectedSacco(item)" data-toggle="modal" data-target=".add-car-modal" class="btn btn-success btn-sm">Add vehicle(S)</button>
                                                    <a @click="gotTo('parking-fleet-payment',item.saccoName,item.saccoID,item.total,item.compliant,getUnCompliant(item.total,item.compliant),)" href="javascript: void(0);" class="btn btn-info btn-sm mx-3">Receive Payment</a>
                                                </td>
                                            </tr>
                                            </tbody>


                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer bg-white">
                                    <nav aria-label="Page navigation example " class="d-flex align-items-center justify-content-between">

                                        <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                                            <select class="form-select w-auto font-13px ">
                                                <option value="10">10 Rows</option>
                                                <option value="25">25 Rows</option>
                                                <option value="50">50 Rows</option>
                                                <option value="100">100 Rows</option>
                                                <option value="150">150 Rows</option>
                                                <option value="200">200 Rows</option>
                                            </select>
                                        </div>

                                        <ul class="pagination">
                                            <li class="page-item disabled">
                                                <a class="page-link" href="#" tabindex="-1">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                                            <li class="page-item active">
                                                <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                                            </li>
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
                            <span
                                    class="badge rounded-pill bg-success text-uppercase font-12px text-uppercase mx-3">Active
                                Certificate</span>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="hori-timeline mt-4">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel"
                                             id="timeline-carousel">
                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">Kelvin Omondi</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">15 Sep 2023 12:59 AM</div>
                                                        <h5 class="mb-4">Details Verification</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By Kelvin Ouma</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">20 Sep 2023 08:23 AM</div>
                                                        <h5 class="mb-4">Premices Inspection</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By Alex Kinoti</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">23 sep 2023 08:23 AM</div>
                                                        <h5 class="mb-4">Approval Of Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By John King</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">23 sep 2023 08:25 AM</div>
                                                        <h5 class="mb-4">issueance Of Certifcate</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">By John King</a></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list active">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">By 31 Dec 2023 12:00 AM</div>
                                                        <h5 class="mb-4">Renewal</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i
                                                                class="bx bx-down-arrow-circle h1 text-info down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize"><a
                                                                href="user-details.html">-</a></p>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-success border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-certification font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Certificate details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Serial
                                                                No.</p>
                                                        </td>
                                                        <td class="">12345</td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Last Paid for</p>
                                                        </td>
                                                        <td class="">12 Jan 2023</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Expiry
                                                                Date</p>

                                                        </td>
                                                        <td class="">31 Dec 2023</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Days
                                                                to expiry</p>

                                                        </td>
                                                        <td class="">204 Days</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Printing status</p>

                                                        </td>
                                                        <td class="">Printed</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount
                                                                Paid</p>

                                                        </td>
                                                        <td class="text-uppercase text-black fw-semibold">KES 25,000
                                                        </td>

                                                    </tr>




                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Business Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Name</p>
                                                        </td>
                                                        <td class="">Wireless Electronics</td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Certificate of Incoporation No. </p>
                                                        </td>
                                                        <td class="">1234557768</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                                                Pin
                                                                No.</p>

                                                        </td>
                                                        <td class="">12345</td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                                                No.
                                                            </p>

                                                        </td>
                                                        <td class="">12345</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Activity</p>

                                                        </td>
                                                        <td class="">Accomodation and Catering</td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business
                                                                Sub category</p>

                                                        </td>
                                                        <td class="">Small Lodging House</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bxs-user-pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Full Names</p>
                                                        </td>
                                                        <td class="">Mr Alex Wanjala Akinyi</td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                ID/PPT No.</p>
                                                        </td>
                                                        <td class="">1234557768</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Fax</p>

                                                        </td>
                                                        <td class="">12345</td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Owner's Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Mobile No.</p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                    </tr>



                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                                                Address</p>

                                                        </td>
                                                        <td class="">12-1004 Nairobi</td>
                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-4">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="table-responsive">
                                                <table
                                                        class="table table-nowrap vertical-align-middle table-striped table-sm">
                                                    <tbody>
                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Mobile No.</p>
                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                    </tr>

                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Alternative Phone No</p>
                                                        </td>
                                                        <td class=""><a href="tel:0704549859">0704549859</a></td>

                                                    </tr>



                                                    <tr
                                                            title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Business Email address</p>
                                                        </td>
                                                        <td class=""><a
                                                                href="mailto:email@email.com">email@email.com</a>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                                                county</p>

                                                        </td>
                                                        <td class=""> Town Sub county</td>

                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                                                            </p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">Rodi Township Ward</a>
                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Physical address/Street</p>

                                                        </td>
                                                        <td class=""><a href="tel:0704549859">Along Moi Avenue
                                                            opposite cooperative Bank</a></td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Building Name</p>

                                                        </td>
                                                        <td class="">ALexis complex</td>

                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                                                Stall/Room No.</p>

                                                        </td>
                                                        <td class="">122</td>

                                                    </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-sm-12 col-md-8">
                                    <div class="row">
                                        <div class="col-12 mt-4">
                                            <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                                                <i class="bx bx-map font-28 mr-15px"></i>
                                                <p class="fw-semibold mb-0 pb-0">Map View</p>

                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div>
                                                <iframe
                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske"
                                                        width="100%" height="450" style="border:0;" allowfullscreen=""
                                                        loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">

                            <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                               class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Certificate
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade add-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Usiku Sacco</h5>
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div >
                                <div class="col-12">
                                    <address>
                                        <strong>About Sacco:</strong><br>
                                        Conatct Person: {{sacco.fullNames}}<br>
                                        {{sacco.phoneNumber}}<br>
                                        {{sacco.physicalAddress}}
                                        <br><br>
                                        {{sacco.wardName}}, {{sacco.subCountyName}}
                                    </address>
                                </div>
                                <div class="new-vehicles-container-modal">
                                    <div class="col-12">
                                        <div class="py-2 mt-3">
                                            <h3 class="font-size-15 fw-bold">Vehicles to Be newly registered</h3>
                                        </div>
                                        <div class="py-1 mt-3">
                                            <select @change="selectedIncomeType($event)"  title="-- Income Type --" class="form-control show-tick" data-live-search="true" v-model="incomeType">
                                                <option v-for="(item, index) in incomeTypes" :value="JSON.stringify(item)" :key="index">
                                                    {{ item.incomeTypeDescription }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="">
                                            <table class="table table-editable-1 align-middle table-edits">
                                                <thead class="table-light">
                                                <tr class="text-uppercase table-dark">
                                                    <th>#</th>
                                                    <th>Vehicle</th>
                                                    <th class="">Category</th>
                                                    <th class="">Owner</th>
                                                    <th>Phone No.</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr  v-for="(item,index) in vehicles" :key="index" >
                                                    <td style="width: 80px " class="categoryIndex ">{{index+1}}</td>

                                                    <td class="">
                                                        <input v-model="item.numberPlate" type="text " class="form-control " placeholder="Enter Plate No." spellcheck="false" data-ms-editor="true">
                                                    </td>
                                                    <td>
                                                        <select v-model="item.category" class="form-control bg-white" title="Select Traffic offence" data-live-search="true" >
                                                            <option v-for="(item, index) in feesAndCharges" :value="item.feeId+':'+ item.feeDescription+':'+ item.unitFeeAmount" :key="index">
                                                                {{ item.feeDescription }} KES {{item.unitFeeAmount}}
                                                            </option>
                                                        </select>

                                                    </td>
                                                    <td class="">
                                                        <input v-model="item.owner" type="text " class="form-control " placeholder="Owner's Name" spellcheck="false">
                                                    </td>
                                                    <td class="">
                                                        <input v-model="item.phoneNumber" type="text " class="form-control " placeholder="Owner's Phone No." spellcheck="false" >
                                                    </td>


                                                    <td @click="removeVehicle(index)" class="text-right cell-change d-flex align-items-center justify-content-end">
                                                        <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-2 " title="Cancel "><i class="bx bx-x "></i></a>
                                                    </td>
                                                </tr>

                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td @click="addVehicle()" colspan="7" class="bg-light add-field-1 cursor-pointer">
                                                        <span class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Add A Vehicle</span></span>
                                                    </td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>


                                    </div>
                                </div>

                                <div class="d-none  loader-panel">
                                    <div class="p-5 py-0 pt-3 d-flex justify-content-center align-items-center text-center flex-column">
                                        <div class="p-5">
                                            <div class="spinner-border text-info m-1" role="status">
                                                <span class="sr-only text-uppercase">Loading...</span>
                                            </div>
                                        </div>
                                        <h4 class="text-black fw-bold">Payment Being Processed</h4>
                                        <div class="mb-5">Ask Client to be checking their Phone for a payment request thats currently being Processed</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <div class="float-end">

                                <a @click="registerVehicles()" href="javascript: void(0);" data-dismiss="modal" class="btn btn-primary w-md waves-effect waves-light reg-payment">Submit</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                            ©County
                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">
                                 County Government
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>



    </body>


</template>

<script>

    import router from "@/router";
    import {parking, execute, biller, getRight} from "@/api";
    import NavigationBar from "@/components/Navigation";

    export default {
        name: "ParkingFleet",
        components: {NavigationBar},
        data() {
            return{
                uncompliantVehicles:0,
                compliantVehicles:0,
                registeredVehicles:0,
                download:'Download Report',
                loading: false,
                dateFrom:'',
                dateTo:'',
                incomeType:{
                    incomeTypeId: null,
                    incomeTypeDescription:null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null
                },
                feesAndCharges:[{
                    feeId: null,
                    feeDescription: null,
                    unitOfMeasure: null,
                    unitFeeAmount: null,
                    accountNo: null,
                    incomeTypeId: null,
                    feeType: null,
                    accountDesc:null ,
                    costCenterNo:null,
                    zone: null,
                    typeDescription: null
                }],
                incomeTypes:[{
                    incomeTypeId: null,
                    incomeTypeDescription:null,
                    incomeTypePrefix: null,
                    fundDescription: null,
                    status: null}],
                saccos: [
                    {
                        saccoID: null,
                        saccoName: null,
                        subCountyName: null,
                        wardName: null,
                        physicalAddress: null,
                        fullNames: null,
                        phoneNumber:null,
                        total: null,
                        compliant: null,

                    }
                ],
                sacco:{
                    saccoID: null,
                    saccoName: null,
                    subCountyName: null,
                    wardName: null,
                    physicalAddress: null,
                    fullNames: null,
                    phoneNumber:null,
                    total: null,
                    compliant: null,

                },
                vehicles:[
                    {
                        numberPlate: null,
                        category:null,
                        owner:null,
                        phoneNumber:null
                    }
                ]
            }
        },
        computed:{
            totalReceiptAmount() {
                return this.formatPrice(this.receiptDetails.reduce((sum, billDetail) => sum + parseInt(billDetail.receiptAmount), 0));
            }
        },
        mounted() {

            this.getSaccos()
            this.getIncomeTypes()

        },

        methods: {
            selectedSacco(item){
                console.log("###",item)
                this.sacco = item
            },
            printSticker(item){
                this.sacco = item
                localStorage['params'] = JSON.stringify({
                    saccoID: this.sacco.saccoID
                })
               const routeData = this.$router.resolve({name: 'parking-sticker'});
                window.open(routeData.href, '_blank');

            },
            getUnCompliant(total, compliant){
                return total - compliant;
            },
            selectedIncomeType(event){
                const value = event.target.value
                const result = JSON.parse(value);
                this.getFeesAndChargesBilling(result.incomeTypeId)
            },
            getIncomeTypes(){
                const data = new FormData();
                data.append("function", "getIncomeTypes");
                data.append("incomeTypePrefix", "PKN");
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.incomeTypes =res.data.data.incomeTypes
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getRight(type){
                return getRight(type)
            },
            getFeesAndChargesBilling(incomeTypeId){
                const data = new FormData();
                data.append("function", "getFeesAndCharges");
                data.append("incomeTypeId",incomeTypeId);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.feesAndCharges = res.data.data.feesAndCharges
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            getSaccos(){
                this.saccos.splice(0)
                const data = new FormData();
                data.append("function", "getSaccos");
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {

                            this.saccos =res.data.data.saccos

                            this.registeredVehicles = this.saccos.reduce((acc, cur) => {
                                let totalAmount = Number(cur.total);
                                return acc + totalAmount;
                            }, 0);
                            this.compliantVehicles = this.saccos.reduce((acc, cur) => {
                                let totalAmount = Number(cur.compliant);
                                return acc + totalAmount;
                            }, 0);
                            this.uncompliantVehicles = this.registeredVehicles - this.compliantVehicles
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            registerVehicles(){
                const data = new FormData();
                data.append("function", "registerVehicles");
                data.append("sacco", JSON.stringify(this.sacco));
                data.append("vehicles", JSON.stringify(this.vehicles));
                execute(data,parking)
                    .then((res) =>{
                        if (res.data.success) {
                            this.getSaccos()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            addVehicle(numberPlate,category,owner,phoneNumber){
                this.vehicles.push({
                    numberPlate:numberPlate,
                    category:category,
                    owner:owner,
                    phoneNumber:phoneNumber})
            },
            removeVehicle(position){

                this.vehicles.splice(position,1)
            },

            //#########
            getCurrentMonth(){
                const d = new Date();
                const monthName = this.monthNames[d.getMonth()];
                console.log(monthName);
                return monthName;
            },
            getMonthName(){
                const monthNames = ['January','February','March','April','May','June','July','August','September','October','November','December'];
                const d = new Date();
                const monthName = monthNames[d.getMonth()];
                console.log(monthName);
                return monthName;
            },
            getCurrentYear(){
                const date = new Date();
                const currentYear = date.getFullYear();
                console.log(currentYear); // Output: 2023
                return currentYear;

            },
            formatPrice(value) {
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'KES',
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            gotTo(route,saccoName,saccoID,total,compliant,unCompliant){
                sessionStorage.setItem("saccoName",saccoName)
                sessionStorage.setItem("saccoID",saccoID)
                sessionStorage.setItem("total",total)
                sessionStorage.setItem("compliant",compliant)
                sessionStorage.setItem("unCompliant",unCompliant)
                router.push(route)
            },
        }
    }
</script>

<style scoped>

</style>