<template>

    <head>

        <meta charset="utf-8"/>
        <title>Maji H2O | All Trip requests</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta content="Premium Multipurpose Admin & Dashboard Template" name="description"/>
        <meta content="Themesbrand" name="author"/>
        Business with AActive SBP Certificate
        <!-- App favicon -->
        <link rel="shortcut icon" href="assets/images/favicon.svg">

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css"/>
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
              type="text/css"/>
        <!-- Bootstrap Rating css -->
        <link href="assets/libs/bootstrap-rating/bootstrap-rating.css" rel="stylesheet" type="text/css"/>

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css"/>
        <!-- Bootstrap Rating css -->
        <link href="assets/libs/bootstrap-rating/bootstrap-rating.css" rel="stylesheet" type="text/css"/>
        <!-- Bootstrap select styling -->
        <link rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css"/>
        <!-- App Css-->
        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css"/>
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css"/>

        <!-- map Css-->
        <link href="assets/css/map.css" id="app-style" rel="stylesheet" type="text/css"/>


    </head>
    <body data-sidebar="dark">
    <!-- Loader -->

    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>

        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">
            <!-- Business details side bar -->
            <div class="modal fade trip-details-modal" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5>Kelvin's trip details</h5>

                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 me-3">
                                    <div class="avatar-xs">
                                        <span class="avatar-title rounded-circle bg-primary bg-soft text-primary text-uppercase">
                                            <strong>KN</strong>
                                        </span>
                                    </div>
                                </div>

                                <div class="flex-grow-1">
                                    <h5 class="modal-title">Trip With Alex</h5>
                                    <p class="mb-0 pb-0"><span class="text-muted">Sat, 26 Feb 2022</span></p>
                                </div>
                            </div>


                            <div class="mb-3 row">
                                <div class="col-12 pt-4">
                                    <ul class="verti-timeline list-unstyled mt-3">
                                        <li class="event-list active">
                                            <div class="event-timeline-dot">
                                                <i class="bx bxs-right-arrow-circle font-size-18"></i>
                                            </div>
                                            <div class="d-flex">
                                                <div class="flex-shrink-0 me-3">
                                                    <h5 class="font-size-14">16:05 <i
                                                            class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                                    </h5>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <div>
                                                        Accra Road
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="event-list">
                                            <div class="event-timeline-dot">
                                                <i class="bx bx-right-arrow-circle font-size-18"></i>
                                            </div>
                                            <div class="d-flex">
                                                <div class="flex-shrink-0 me-3">
                                                    <h5 class="font-size-14">On Transit <i
                                                            class="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                                    </h5>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <div>
                                                        Embakasi
                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div class="col-12 pt-4">
                                    <h6 class="text-capitalize">2KM To go</h6>

                                </div>
                                <div class="col-12 pt-4">
                                    <h6>Payment</h6>
                                    <table class="table">
                                        <tbody>
                                        <tr>
                                            <td>Fare. Reguler cab</td>
                                            <td class="text-right">KES 250.00</td>
                                        </tr>
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th>Pending</th>
                                            <th class="text-right">Waiting</th>
                                        </tr>

                                        </tfoot>

                                    </table>

                                </div>
                            </div>
                            <button class="btn btn-primary"><i class="bx bx-printer font-size-16 align-middle me-2"></i>Print
                                Receipt
                            </button>
                            <button class="btn btn-outline-primary waves-effect"><i
                                    class="mdi mdi-email-send-outline font-size-16 align-middle me-2"></i>Resend Receipt
                            </button>

                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- trip details modal -->

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">

                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Map view</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item active">map View</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- tool bar -->

                  <ToolBarMapping/>


                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white boarder-bottom-1 d-none">
                                    <div class="row d-flex justify-content-between">
                                        <div class="col-12">
                                            <div class="search-box mb-2 me-2">
                                                <div class="position-relative">
                                                    <input type="text"
                                                           class="form-control bg-light border-light rounded map-searcher"
                                                           id="pac-inpu" placeholder="Search for location">
                                                    <i class="bx bx-search-alt search-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div v-if="loading"  class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                                    <div class="p-5 py-0 pt-3">
                                        <div class="p-5">
                                            <div class="spinner-border text-info m-1" role="status">
                                                <span class="sr-only text-uppercase">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 class="text-uppercase">Loading Data</h4>
                                    <div class="col-sm-12 col-md-8 col-lg-5">
                                        <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                                    </div>
                                </div>
                                <div v-if="businesses.length<1 & loading===false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                                    <div class="p-5 py-0 pt-3">
                                        <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                                    </div>
                                    <h4>No Results To Show</h4>
                                    <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                                </div>


                                <div class="card-body">
                                    <div  id="sbpMap" class="w-100 mt-1 h-100 live-map-cont main-map-container"></div>

                                </div>


                            </div>



                          <!--  <div class="">
                                <div class="position-relative ">


                                    &lt;!&ndash; map serach input box &ndash;&gt;
                                    <div class="form-group map-searcher">

                                        <input type="text" class="form-control form-control-lg text-black"
                                               placeholder="Search for a location" id="pac-input">
                                        <button title="Clear search results"
                                                class="btn btn-transparent border-0 clear-map"><i
                                                class="mdi mdi-close d-none"></i></button>
                                    </div>

                                    &lt;!&ndash; key button &ndash;&gt;
                                    <div class="map-key-card card">
                                        <div class="card-body">
                                            <i class="mdi mdi-key-variant"></i>
                                        </div>
                                    </div>

                                    &lt;!&ndash; key items &ndash;&gt;
                                    <div class="card map-card animated slideInLeft d-none">
                                        <span class="close-map-key"><i class="zmdi zmdi-close"></i></span>
                                        <div class="card-body">
                                            <h4 class="card-title">Map Key</h4>

                                        </div>
                                        <div class="listview py-4 map-key">
                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/damaged.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Damaged Ad Plates</strong></div>
                                                    </div>
                                                </span>

                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/available.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Available Ad Plates</strong></div>
                                                    </div>
                                                </span>

                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/rented.svg" class="listview__img"
                                                         alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Rented Ad Plates</strong></div>
                                                    </div>
                                                </span>

                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/multiple-plates.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Multiple sided Ad Plates</strong></div>
                                                    </div>
                                                </span>


                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/rejected.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Rejected Applications</strong></div>
                                                    </div>
                                                </span>

                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/pending.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Pending applications</strong></div>
                                                    </div>
                                                </span>

                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/approved.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Approved Applications</strong></div>
                                                    </div>
                                                </span>


                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/warning.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Incidents</strong></div>
                                                    </div>
                                                </span>

                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/active-agent.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>active-agent</strong></div>
                                                    </div>
                                                </span>

                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/inactive-agent.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Inactive agents</strong></div>
                                                    </div>
                                                </span>

                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/parliament.svg"
                                                         class="listview__img" alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>City Hall</strong></div>
                                                    </div>
                                                </span>


                                            <span class="listview__item py-0">
                                                    <img src="assets/images/map-assets/flag.svg" class="listview__img"
                                                         alt="">

                                                    <div class="listview__content d-flex align-items-center">
                                                        <div class="listview__heading"><strong>Service/Collection Point</strong></div>
                                                    </div>
                                                </span>


                                        </div>
                                    </div>

                                </div>

                            </div>-->
                        </div>
                        <!-- end col -->
                    </div>
                    <!-- end row -->


                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- Transaction Modal -->
            <div class="modal fade transaction-detailModal" tabindex="-1" role="dialog"
                 aria-labelledby="transaction-detailModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="transaction-detailModalLabel">Transactions Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">


                            <div class="table-responsive mt-4">
                                <table class="table align-middle table-nowrap">
                                    <tbody>
                                    <tr>
                                        <td style="width: 30%">
                                            <p class="mb-0">Car Plate Number</p>
                                        </td>
                                        <td style="width: 25%">
                                            <h5 class="mb-0 text-uppercase">UAG 1235p</h5>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="mb-0">Vehicle Category</p>
                                        </td>
                                        <td>
                                            <h5 class="mb-0">Salon Car</h5>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="mb-0">Payment Date</p>
                                        </td>
                                        <td>
                                            <h5 class="mb-0">22 Apr 2022 06:54PM</h5>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="mb-0">San Diego</p>
                                        </td>
                                        <td>
                                            <h5 class="mb-0">1,026</h5>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <p class="mb-0 pb-0">Amount Paid</p>
                                            <h3>UGX 2,000</h3>
                                        </td>
                                    </tr>
                                    <TR>
                                        <td col-span="2">
                                            <p class="mb-2">Attended By<span class="text-primary"> Alex Sebeye</span>
                                            </p>
                                            <p class="mb-4">Toll taker's No. <span
                                                    class="text-primary">0704549859</span></p>
                                        </td>
                                    </TR>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Bill Receipting -->
            <div class="modal fade receipting-Modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                 role="dialog" aria-labelledby="transaction-detailModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-capitalize">
                            <h5 class="modal-title" id="#">Receipt The bill (Bill No. 123456)</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="">Select Payment Method</label>
                                        <select name="" class="form-select select-payment-method" id="">
                                            <option value="Mpesa">MPESA</option>
                                            <OPtion value="cash">Cash</OPtion>
                                            <option value="Bank">Bank</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="">Payer's Name</label>
                                        <!--  by default this should be the name to the driveer selected from the Db -->
                                        <input type="text" value="Kelvin Njuguna" class="form-control"
                                               placeholder="Enter payer's name" name="" id="">
                                        <small>You can change if the payer is different from the driver</small>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="">Phone No.</label>
                                        <!--  Directly from the DB -->
                                        <input type="text" value="0704549859" class="form-control"
                                               placeholder="Enter payer's name" name="" id="">
                                        <small>You can change if the payer is different from the driver</small>
                                    </div>
                                </div>

                                <div class="mb-4">
                                    <label>Payment Date</label>
                                    <div class="input-group" id="datepicker2">
                                        <input type="text" class="form-control" placeholder="dd M, yyyy"
                                               data-date-format="dd M, yyyy" data-date-container='#datepicker2'
                                               data-provide="datepicker" data-date-autoclose="true">

                                        <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                                    </div>
                                    <!-- input-group -->
                                </div>

                                <!-- Mpesa -->
                                <div class="col-12 mpesa-mode  driver-pay-method">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="">Mpesa Ref No.</label>
                                                <input type="text" class="form-control"
                                                       placeholder="Enter Mpesa Ref No." name="" id="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- cash -->
                                <div class="col-12 cash-mode d-none driver-pay-method">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="">Received Amount</label>
                                                <input type="text" class="form-control"
                                                       placeholder="Enter Cash amount received " name="" id="">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- bank payment -->
                                <div class="col-12 bank-mode d-none driver-pay-method">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="">Bank</label>
                                                <input type="text" class="form-control"
                                                       placeholder="Enter the bank's Name" name="" id="">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="">Transaction Ref No.</label>
                                                <input type="text" class="form-control"
                                                       placeholder="Enter Bank's transaction's Ref No" name="" id="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-success">Receipt Bill</button>
                        </div>

                    </div>
                </div>
            </div>
            <!-- end modal -->
            <!-- end modal -->
            <!-- filter right of canvus -->
            <div class="offcanvas offcanvas-end" tabindex="-1" id="dt__filter" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header">
                    <h5 id="offcanvasRightLabel">Filter Member Register</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div class="col-12 mb-4">
                        <div class="alert alert-info alert-border" role="alert">
                            Use this section to filter the table
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="mb-4">
                            <label>Last collection date Range</label>

                            <div class="input-daterange input-group" id="datepicker6" data-date-format="dd M, yyyy"
                                 data-date-autoclose="true" data-provide="datepicker"
                                 data-date-container='#datepicker6'>
                                <input type="text" class="form-control date-drop-input" name="start"
                                       placeholder="Start Date" readonly/>
                                <input type="text" class="form-control date-drop-input" name="end"
                                       placeholder="End Date" readonly/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-4">
                            <label>Milk collections amount range</label>

                            <div class="input-daterange input-group">
                                <input type=" text " class="form-control " name="" placeholder="Min Amount"/>
                                <input type="text " class="form-control " name="" placeholder="Max Amount"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-4">
                            <label>Land Size</label>

                            <div class="input-daterange input-group">
                                <input type=" text " class="form-control " name="" placeholder="Min Size"/>
                                <input type="text " class="form-control " name="" placeholder="Max size"/>
                            </div>
                            <span class="text-muted"><small>Specify a range for the field size</small></span>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-4">
                            <label>Route</label>
                            <div class="row">
                                <div class="col-md-12 col-lg-6">
                                    <div class="form-check form-check-primary mb-3">
                                        <input class="form-check-input" type="checkbox" id="allTollTakers" checked>
                                        <label class="form-check-label text-capitalize" for="allTollTakers">
                                            All Routes
                                        </label>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-6">
                                    <div class="form-check form-check-primary mb-3">
                                        <input class="form-check-input" type="checkbox" id="tollTaker1">
                                        <label class="form-check-label text-capitalize" for="tollTaker1">
                                            Nkubu
                                        </label>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-6">
                                    <div class="form-check form-check-primary mb-3">
                                        <input class="form-check-input" type="checkbox" id="tollTaker3">
                                        <label class="form-check-label text-capitalize" for="tollTaker3">
                                            Mutithi
                                        </label>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-6">
                                    <div class="form-check form-check-primary mb-3">
                                        <input class="form-check-input" type="checkbox" id="tollTaker4">
                                        <label class="form-check-label text-capitalize" for="tollTaker4">
                                            Mukinduri
                                        </label>
                                    </div>
                                </div>

                                <div class="col-md-12 col-lg-6">
                                    <div class="form-check form-check-primary mb-3">
                                        <input class="form-check-input" type="checkbox" id="tollTaker5">
                                        <label class="form-check-label text-capitalize" for="tollTaker5">
                                            Kathangeri
                                        </label>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-4">
                            <button type="button" class="btn btn-primary waves-effect waves-light w-100">
                                <i class="mdi mdi-filter-variant font-size-16 align-middle me-2"></i> Filter Table
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- offcanvasess begining. they will be containing the different side bars with the statuses -->
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header">
                    <div class="d-flex">
                        <div class="flex-grow-1 overflow-hidden pe-5">
                            <div class=" mb-1 text-capitalize d-flex">
                                <h5 class="text-truncate font-size-14 mb-0">
                                    {{business.businessName}}
                                </h5>
                            </div>
                            <p class="text-truncate mb-0 text-uppercase">Business ID <strong>{{business.businessID}}</strong></p>
                        </div>


                    </div>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">

                    <div v-bind:class="{ 'alert': true, 'alert-success': color === 'alert-success', 'alert-danger': color === 'alert-danger' }" role="alert">
                        Business with An expired SBP Certificate
                    </div>


                    <div v-bind:class="{ 'alert': true, 'alert-success': color === 'alert-success', 'alert-danger': color === 'alert-danger' }" role="alert">

                        <strong>Business Category</strong>
                        <p>{{business.businessCategory}}</p>

                        <strong>Business Sub Category</strong>
                        <p>{{business.businessSubCategory}}</p>

                        <strong>Business Activity</strong>
                        <p>{{business.businessDes}}</p>

                        <strong>Paid SBP Amount</strong>
                        <h5>KES {{numberFormat(business.receiptAmount)}}</h5>

                        <a class="" href="PrintDocs/Receipts/receipts.html">Receipt No. {{business.billNo}}</a>


                    </div>



                    <div class="card shadow-none border">
                        <div class="card-body p-3">
                            <div class="">

                                <div class="d-flex">
                                    <div class="overflow-hidden me-auto">
                                        <h5 class="font-size-14 text-truncate mb-1"><a href="javascript: void(0);" class="text-body">Permit Details</a></h5>

                                    </div>

                                </div>

                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-file-check-outline text-success align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Permit Status</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class="">Active</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-calendar-month text-warning align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Duration Paid For</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class="">{{formatDate(business.startDate)}} - {{formatDate(business.endDate)}}</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-file-certificate-outline text-warning align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Permit Number</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class="">{{business.businessID}}</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-calendar-clock text-info align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Date Issued</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class="">{{formatDate(business.dateOfIssue)}}</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>



                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-file-document-multiple text-warning align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Previous SBP Permits</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class=""><a href="">{{business.permitsCount}} Previous Permit(s)</a></span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-cash-multiple text-success align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Total Charges Paid</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class="">KES {{numberFormat(business.receiptAmount)}}</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                            <!--    <div class="d-flex">
                                    <div class="overflow-hidden w-100">

                                        <a @click="printTradePermit(business.businessID)" type="button" class="btn btn-primary waves-effect waves-light w-100 text-center btn-100 btn-flex" fdprocessedid="1zlfom">
                                            <i class="mdi mdi-download font-size-16 align-middle me-2"></i> Download Permit
                                        </a>
                                    </div>

                                </div>-->




                            </div>
                        </div>
                    </div>

                    <div class="card shadow-none border">
                        <div class="card-body p-3">
                            <div class="">

                                <div class="d-flex">
                                    <div class="overflow-hidden me-auto">
                                        <h5 class="font-size-14 text-truncate mb-1"><a href="javascript: void(0);" class="text-body">Business Location Details</a></h5>
                                        <p class="text-muted text-truncate mb-0">{{business.physicalAddress}}</p>
                                    </div>

                                </div>

                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-map-marker text-warning align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Actual Location</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class="the-clicked-address ">{{business.physicalAddress}}</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>

                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-map-legend text-warning align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Sub County & Ward</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class="">{{business.subCountyName}}, {{business.w}}</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>



                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-home-map-marker text-warning align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Address</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class="">{{business.physicalAddress}}</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>


                                <div class="card-body bg-light d-flex mb-4 mt-4">
                                    <p class=" mb-0 ">
                                        <i class="mdi mdi-office-building-outline text-warning align-middle me-3 font-size-24 ml-5 mr-5 "></i>
                                    </p>
                                    <div>
                                        <p class="text-muted mb-0 ">Door Number and Floor</p>
                                        <p class="mb-0 ">
                                            <strong class="fw-semibold">
                                                <span class="">Door No. {{business.roomNo}}, {{business.buildingName}} {{business.floorNo}}</span>
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">> ©County
                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">
                                LeadCab
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->


    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>

</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import ToolBar from "@/components/sbp/ToolBar";
    import {trade, execute} from "@/api";
    import moment from "moment";
    import ToolBarMapping from "@/components/sbp/data/ToolBarMapping";
    export default {
        name: "BusinessMapping",
        components: {ToolBarMapping, ToolBar, NavigationBar},
        data() {
            return {
                currentPage: 1,
                // set the total number of pages as 10 (you can change this according to your data source)
                totalPages: 0,
                // set the number of items per page as 5 (you can change this according to your preference)
                pageSize: 100,
                summary: {
                    count: 0,
                    total: 0
                },
                loading: true,
                county: {
                    id: null,
                    countyID: null,
                    countyName: null,
                    tagline: null,
                    smsusername: null,
                    smspassword: null,
                    contact: null,
                    center: null,
                    address: null,
                    email: null,
                    headline: null,
                    tagline2: null,
                    ussd: null,
                    logo: null,
                    bank: null,
                    powered: null,
                    stateLogo:null,
                    seal: null,
                    signature: null,
                    lat: null,
                    lng:null,
                    link: null,
                    paysol: null,
                    poweredByLogo: null,
                    mobileApp: null,
                    selfService: null
                },
                color:'alert-success',
                nonCompliant: [{
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyName: null,
                    physicalAddress: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    businessDes: null,
                    status: null,
                    receiptAmount: null,
                    startDate:null,
                    endDate: null,
                    billNo: null,
                    dateOfIssue: null,
                    issuingOfficer: null,
                    lastRenewed: null,
                    floorNo: null,
                    roomNo: null,
                    lat: null,
                    lng: null,
                    permitsCount: null}],
                compliant: [{
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyName: null,
                    physicalAddress: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    businessDes: null,
                    status: null,
                    receiptAmount: null,
                    startDate:null,
                    endDate: null,
                    billNo: null,
                    dateOfIssue: null,
                    issuingOfficer: null,
                    lastRenewed: null,
                    floorNo: null,
                    roomNo: null,
                    lat: null,
                    lng: null,
                    permitsCount: null}],
                businesses: [{
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    plotNumber: null,
                    physicalAddress: null ,
                    buildingName: null,
                    buildingOccupancy:null ,
                    floorNo:null ,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    feeID: null,
                    businessEmail:null ,
                    postalAddress: null,
                    postalCode:null ,
                    businessPhone: null,
                    contactPersonNames:null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames:null ,
                    ownerID:null ,
                    ownerPhone: null,
                    ownerEmail:null ,
                    kraPin:null ,
                    createdBy: null,
                    createdByIDNo: null,
                    dateCreated: null,
                    lat: null,
                    lng: null,
                    fireLicence: null,
                    liquorLicence: null,
                    businessType: null
                }],
                business: {
                    id: null,
                    businessID: null,
                    businessName: null,
                    subCountyID: null,
                    subCountyName: null,
                    wardID: null,
                    wardName: null,
                    plotNumber: null,
                    physicalAddress: null ,
                    buildingName: null,
                    buildingOccupancy:null ,
                    floorNo:null ,
                    roomNo: null,
                    premiseSize: null,
                    numberOfEmployees: null,
                    tonnage: null,
                    businessDes: null,
                    businessCategory: null,
                    businessSubCategory: null,
                    incomeTypeID: null,
                    feeID: null,
                    businessEmail:null ,
                    postalAddress: null,
                    postalCode:null ,
                    businessPhone: null,
                    contactPersonNames:null,
                    contactPersonIDNo: null,
                    businessRole: null,
                    contactPersonPhone: null,
                    contactPersonEmail: null,
                    fullNames:null ,
                    ownerID:null ,
                    ownerPhone: null,
                    ownerEmail:null ,
                    kraPin:null ,
                    createdBy: null,
                    createdByIDNo: null,
                    dateCreated: null,
                    lat: null,
                    lng: null,
                    fireLicence: null,
                    liquorLicence: null,
                    businessType: null
                },
            }

        },
        mounted() {
            this.getBusinessData()
        },
        methods: {

            formatDate(date) {
                let d = new Date(date);
                let day = d.getDate();
                let month = d.toLocaleString('en-US', { month: 'short' });
                let year = d.getFullYear();

                let suffix = '';
                if (day % 10 === 1 && day !== 11) {
                    suffix = 'st';
                } else if (day % 10 === 2 && day !== 12) {
                    suffix = 'nd';
                } else if (day % 10 === 3 && day !== 13) {
                    suffix = 'rd';
                } else {
                    suffix = 'th';
                }
                return `${day}${suffix} ${month} ${year}`;
            },
            formattedTime(time) {
                return moment(time).format('hh:mm A')
            },

            numberFormat(num) {
                const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                return britishNumberFormatter.format(num)
            },

            printTradePermit(businessID) {

                const data = new FormData();
                data.append("function", "printTradePermit");
                data.append("businessID", businessID);
                execute(data, trade)
                    .then((res) => {
                        if (res.data.success) {

                            localStorage['params'] = JSON.stringify({
                                businessID: businessID
                            })
                            const routeData = this.$router.resolve({name: 'trade'});
                            window.open(routeData.href, '_blank');

                        } else {
                            this.businessNotFound = true
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
            getBusinessData() {
                this.loading = true
                const data = new FormData();
                data.append("function", "getBusinessData");
                data.append("page", this.currentPage);
                data.append("rows_per_page", this.pageSize);
                execute(data, trade)
                    .then((res) => {
                        this.loading = false
                        if (res.data.success) {
                            this.businesses = res.data.data.businesses
                            this.county = res.data.data.county
                            this.initMap()
                        } else {
                            this.message = res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message = e.message
                    });
            },

            initMap() {

                //closing marker details

                $('.close-aside').on('click', function () {
                    $(this).parent().parent().addClass('left-100');
                    $('.ma-backdrop').addClass('d-none');
                    // marker.setAnimation(null);
                    removeMarkers();
                });


                //declined business application


                var dueBusiness = {
                    url: "assets/images/map-assets/biz/biz-due.svg", // url
                    scaledSize: new google.maps.Size(65, 65), // scaled size
                    origin: new google.maps.Point(0, 0), // origin
                    anchor: new google.maps.Point(0, 0) // anchor
                };

                var overdueBusiness = {
                    url: "assets/images/map-assets/biz/penalized-business.svg", // url
                    scaledSize: new google.maps.Size(65, 65), // scaled size
                    origin: new google.maps.Point(0, 0), // origin
                    anchor: new google.maps.Point(0, 0) // anchor
                };

                var activeBusiness = {
                    url: "assets/images/map-assets/biz/compliant-biz-business.png", // url
                    scaledSize: new google.maps.Size(19.7, 27.9), // scaled size
                    origin: new google.maps.Point(0, 0), // origin
                    anchor: new google.maps.Point(0, 0) // anchor
                };


                const latitude = parseFloat(this.county.lat);
                const longitude = parseFloat(this.county.lng);
                //the map options
                var options = {
                    zoom: 15,
                    center: {lat: latitude, lng: longitude}
                }


                //new map
                var map = new google.maps.Map(document.getElementById('sbpMap') , options);

                var gmarkers = [];

                //listen for click on  map

                // the smooth zoom function not in use
                function  numberFormat(num) {
                    const britishNumberFormatter = new Intl.NumberFormat("en-GB");
                    return britishNumberFormatter.format(num)
                }

                function smoothZoom(map, max, cnt) {
                    if (cnt >= max) {
                        return;
                    } else {
                        z = google.maps.event.addListener(map, 'zoom_changed', function (event) {
                            google.maps.event.removeListener(z);
                            smoothZoom(map, max, cnt + 1);
                        });
                        setTimeout(function () {
                            map.setZoom(cnt)
                        }, 80); // 80ms is what I found to work well on my system -- it might not work well on all systems
                    }
                }


                google.maps.event.addListener(map, 'dblclick', function (event) {
                    removeMarkers();

                    //add marker

                    addMarker({coords: event.latLng});


                    //get value of clicked coodinates

                    //stores coodinates of selected area
                    var newCoords = event.latLng;

                    // return clicked area coods
                    console.log(event.latLng);

                    console.log(newCoords.toString());
                    newCoords = newCoords.toString();
                    var Latitude;
                    var longitude;
                    longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
                    Latitude = newCoords.substring(0, newCoords.indexOf(','));
                    Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);

                    longitude = longitude.substring(0, longitude.indexOf(')'));
                    // console.log("Lat:"+Latitude);
                    // console.log("long:"+longitude);

                    //reverse geocoding function
                    //usses clicked coodinates to get the newly clicked llocation


                    smoothZoom(map, 12, map.getZoom());


                    map.setCenter(new google.maps.LatLng(Latitude, longitude));

                    reverseGeocoding(Latitude, longitude);


                    //opens the side bar form
                    $('#newPoint').removeClass('left-100').siblings().addClass('left-100');

                });



                //compliant businbesses
                if(this.businesses !==null){
                    this.businesses.forEach((item) => {
                        var color ='alert-success'

                        const prop = {
                            coords: {lat: Number(item.lat), lng: Number(item.lng)},
                            iconImage: activeBusiness,
                            content: `<p class="d-none">application|identifier</p><h6 class="d-flex align-items-center">
<div class="card mb-0">
    <div class="card-body px-0 mb-3">
           <div class="d-flex">
           <div class="flex-grow-1 overflow-hidden pe-5">
               <h5 class="text-truncate font-size-16 mb-1 text-capitalize">
                ${item.businessName}
               </h5>
               <p class="text-truncate  text-uppercase text-grey mb-4">${item.subCountyName}</p>
           </div>
           </div>
    </div>

    <div class="alert alert-info">
        <strong>Business Location</strong>
        <p class="text-muted mb-0"><i class="mdi mdi-map-marker text-success align-middle me-1 font-18px"></i><span class="the-clicked-address">${item.physicalAddress}</span> </p>
    </div>

    <div class="alert ${color}" role="alert">
       <strong>Business Category</strong>
       <p>${item.businessCategory}</p>

       <strong>Business Sub Category</strong>
       <p>${item.businessSubCategory}</p>

       <strong>Renewal Fee Paid</strong>
        <h5>KES #</h5>

        <a class="text-info text-uppercase mt-4 fw-semibold">Receipt No. #</a>
    </div>

    <div class="card-body">

        <ul class="verti-timeline list-unstyled">
            <li class="event-list">
                <div class="event-timeline-dot">
                    <i class="mdi mdi-square font-size-18 text-black"></i>
                </div>
                <div class="d-flex">

                    <div class="flex-grow-1">
                    <span class="text-muted">
                     Application date
                    </span>
                        <div>
                        Done By ${item.createdBy} On ${item.dateCreated}
                        </div>
                    </div>
                </div>
            </li>
            <li class="event-list">
                <div class="event-timeline-dot">
                    <i class="mdi mdi-circle font-size-18 text-primary"></i>
                </div>
                <div class="d-flex">

                    <div class="flex-grow-1">
                    <span class="text-muted">
                     Last Renewed
                    </span>
                        <div>
                        lastRenewed
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

    `
                        };
                        addMarker.bind(this)(prop,item,color);

                    });
                }

                //add marker function
                function addMarker(props,item,color) {
                    //add marker
                    var marker = new google.maps.Marker({
                        position: props.coords,
                        map: map,
                        animation: google.maps.Animation.DROP,
                        // icon:props.iconImage
                    });

                    if (!props.content) {
                        gmarkers.push(marker);

                    }

                    if (props.iconImage) {
                        //set icon image if its there
                        marker.setIcon(props.iconImage);

                    }

                    // check if there is content
                    if (props.content) {
                        //set icon image if its there

                        var infowindow = new google.maps.InfoWindow({
                            content: props.content
                        });

                        marker.addListener('mouseover', function (e) {

                            infowindow.open(map, marker);

                            var newCoords = e.latLng;
                            newCoords = newCoords.toString();
                            // alert(newCoords);

                            var Latitude;
                            var longitude;
                            longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
                            Latitude = newCoords.substring(0, newCoords.indexOf(','));
                            Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);
                            longitude = longitude.substring(0, longitude.indexOf(')'));

                            reverseGeocoding(Latitude, longitude);
                            // alert("mouse hovered");

                        });

                        marker.addListener('click', (e) => {
                            toggleBounce(marker);

                            this.business = item
                            this.color = color

                            var newCoords = e.latLng;
                            newCoords = newCoords.toString();
                            // alert(newCoords);

                            var Latitude;
                            var longitude;
                            longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
                            Latitude = newCoords.substring(0, newCoords.indexOf(','));
                            Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);
                            longitude = longitude.substring(0, longitude.indexOf(')'));

                            reverseGeocoding(Latitude, longitude);


                            // alert(iconImage);
                            // alert( e.latLng);

                            //position of the clicked marker . the longitude and latitude
                            var pointPosition = e.latLng;

                            //gettting the image

                            //initializing image as empty
                            var theImage = "assets/images/billboard-ads/no-add.jpg";

                            console.log(infowindow.content.toString())

                            //checking if the content has an image
                            if (infowindow.content.includes("<img")) {
                                //if it has image assign mage to

                                theImage = infowindow.content.substring(infowindow.content.indexOf('src="') + 5);
                                //the image url
                                theImage = theImage.substring(0, theImage.indexOf('"'));


                            }

                            var theContent = infowindow.content.substr(18);
                            var theContent = theContent.substring(0, theContent.indexOf('<'));

                            //the group of markers eg car,incident,collection points etc
                            var theGroup = theContent.substring(0, theContent.indexOf('|'));

                            //the unique identifier of the clicked item eg number plate
                            var uniqueIdentifier = theContent.substring(theContent.lastIndexOf("|") + 1);

                            var theCanvas = new bootstrap.Offcanvas(offcanvasExample)
                            theCanvas.show()


                        });

                        // close content when mouse exits
                        marker.addListener('mouseout', function () {
                            infowindow.close(map, marker);

                        });

                    }
                }

                searchAddress();

                var locationForm = document.getElementById('sbpMap') ;

                //listen for submit
                locationForm.addEventListener('submit', geocode);

                function geocode(e) {
                    e.preventDefault();
                    var location = document.getElementById('location-input').value;
                    axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                        params: {
                            address: location,
                            key: 'AIzaSyAGPbYfcYqdbSBeXdIpH5iWrznfU886Qk8'
                        }
                    })
                        .then(function (response) {
                            // log full response
                            console.log(response);

                            //formated address
                            var formattedAddress = response.data.results[0].formatted_address;
                            console.log(formattedAddress);

                            var formattedAddressOutput = `
              <ul class="list-group">
                <li class="list-group-item">${formattedAddress}</li>
              </ul>
            `;

                            // Address components
                            var addressComponents = response.data.results[0].address_components;
                            var addressComponentsOutput = '<ul class="list-group">'
                            for (var i = 0; i < addressComponents.length; i++) {
                                addressComponentsOutput += `
                    <li class="list-group-item">
                        <strong>${addressComponents[i].types[0]}</strong>: ${addressComponents[i].long_name}
                    </li>
                `;
                            }
                            addressComponentsOutput += '</ul>'

                            //geometry
                            var lat = response.data.results[0].geometry.location.lat;
                            var lng = response.data.results[0].geometry.location.lng;

                            var geometryOutput = `
              <ul class="list-group">
                <li class="list-group-item"><strong>Latitude:</strong> :${lat}</li>
                <li class="list-group-item"><strong>Longitude:</strong> :${lng}</li>
              </ul>
            `;

                            //output to APP
                            document.getElementById('formatted-address').innerHTML = formattedAddressOutput;
                            document.getElementById('address-components').innerHTML = addressComponentsOutput;
                            document.getElementById('geometry').innerHTML = geometryOutput;
                        })
                        .catch(function (error) {
                            console.log(response);
                        });
                }

                var map;
                var infowindow;

                function searchAddress() {

                    var input = document.getElementById("pac-input");
                    var searchBox = new google.maps.places.SearchBox(input);
                    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input); // Bias the SearchBox results towards current map's viewport.

                    map.addListener("bounds_changed", function () {
                        searchBox.setBounds(map.getBounds());
                    });

                    //marker
                    var markers = [];
                    // Listen for the event fired when the user selects a prediction and retrieve
                    // more details for that place.

                    //this function runs when the search box is clicked
                    $('#pac-input').on('click', function () {
                        $('.map-info-cont').addClass('left-100');
                    });

                    //shows the close button on the search box when someone starts to search for a new place
                    $('#pac-input').on('keyup', function () {
                        removeMarkers();
                        $('.clear-map i').removeClass('d-none');
                        $('.map-info-cont').addClass('left-100');
                    });

                    //this function is fired up when the close button is clicked
                    // it clears the searched icons plus the search input box
                    $('.clear-map').on('click', function () {

                        $('.form-map-locations .clicked-subcounty').text("Not Selected").addClass('text-danger').removeClass('text-black');
                        $('.form-map-locations .clicked-address').text("Not Selected").addClass('text-danger').removeClass('text-black');
                        $('.form-map-locations .clicked-ward').text("Not Selected").addClass('text-danger').removeClass('text-black');
                        $('.form-map-locations .clicked-street').text("Not Selected").addClass('text-danger').removeClass('text-black');


                        const places = searchBox.getPlaces();
                        console.log(places);
                        $('.map-info-cont').addClass('left-100');
                        $('#pac-input').val("");
                        $('.clear-map i').addClass('d-none');
                        removeMarkers();
                        // Clear out the old markers.

                        markers.forEach((marker) => {
                            marker.setMap(null);
                        });
                    });

                    searchBox.addListener("places_changed", () => {

                        const places = searchBox.getPlaces();

                        if (places.length == 0) {
                            return;
                        }

                        // Clear out the old markers.
                        markers.forEach((marker) => {
                            marker.setMap(null);


                        });
                        markers = [];


                        // For each place, get the icon, name and location.
                        var bounds = new google.maps.LatLngBounds();

                        places.forEach(function (place) {
                            if (!place.geometry) {
                                console.log("Returned place contains no geometry");
                                return;
                            }

                            //the icons attributes for search results
                            const icon = {
                                // url: place.icon,//adds unique marker depending on search results
                                size: new google.maps.Size(71, 71),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(17, 34),
                                scaledSize: new google.maps.Size(25, 25),
                            };

                            // Create a marker for each place.
                            var marker = new google.maps.Marker({
                                map: map,
                                title: place.name,
                                position: place.geometry.location,
                                icon: icon,
                                description: `<strong>` + place.name + `</strong><br><p class="text-info text-underline">Click Icon for more options</p>`
                            })
                            markers.push(marker);


                            var infowindow = new google.maps.InfoWindow({
                                content: marker.description
                            });


                            marker.addListener('mouseout', function () {
                                infowindow.close(map, marker);

                            });


                            // add a hover event on the search results markers
                            google.maps.event.addListener(marker, "mouseover", function (e) {
                                infowindow.open(map, marker);
                                infowindow.setContent(data.description);

                                var newCoords = e.latLng;
                                newCoords = newCoords.toString();
                                // alert(newCoords);

                                var Latitude;
                                var longitude;
                                longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
                                Latitude = newCoords.substring(0, newCoords.indexOf(','));
                                Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);
                                longitude = longitude.substring(0, longitude.indexOf(')'));

                                reverseGeocoding(Latitude, longitude);


                            });
                            toggleBounce(marker);

                            //   adding marker click event

                            google.maps.event.addListener(marker, "click", function (e) {

                                var thePlace = place.name;
                                var newCoords = e.latLng;

                                newCoords = newCoords.toString();

                                var Latitude;
                                var longitude;
                                longitude = newCoords.substring(newCoords.lastIndexOf(",") + 1);
                                Latitude = newCoords.substring(0, newCoords.indexOf(','));
                                Latitude = Latitude.substring(Latitude.lastIndexOf("(") + 1);
                                longitude = longitude.substring(0, longitude.indexOf(')'));

                                reverseGeocoding(Latitude, longitude);
                                $('#newPoint').removeClass('left-100').siblings().addClass('left-100');

                                var thePlaceHolder = $('.listview .selected-point-details');

                                thePlaceHolder = `
                    <p class="mb-0"><strong>The Place Name</strong></p>
                    <p class="clicked-place">${thePlace}</p>
                    `;
                                toggleBounce(marker);


                            });

                            //   marker click event ends here


                            if (place.geometry.viewport) {
                                // Only geocodes have viewport.
                                bounds.union(place.geometry.viewport);
                            } else {
                                bounds.extend(place.geometry.location);
                                numberFormat                }
                        });
                        map.fitBounds(bounds);

                    });

                }

                //make the marker bounce
                function toggleBounce(marker) {
                    if (marker.getAnimation() !== null) {
                        marker.setAnimation(null);
                    } else {
                        marker.setAnimation(google.maps.Animation.BOUNCE);
                        setTimeout(function () {
                            marker.setAnimation(null);
                        }, 750);
                    }
                }

                //remove marker function
                function removeMarkers() {
                    for (i = 0; i < gmarkers.length; i++) {
                        gmarkers[i].setMap(null);
                    }
                }

                // reverse geo coding
                function reverseGeocoding(Latitude, longitude) {
                    const KEY = "AIzaSyAGPbYfcYqdbSBeXdIpH5iWrznfU886Qk8";
                    const LAT = -1.270102;
                    const LNG = 36.8589333;
                    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${Latitude},${longitude}&key=${KEY}`;
                    fetch(url)
                        .then(response => response.json())
                        .then(data => {
                            var reverseResultsOutput = '<ul class="list-group">';
                            console.log(data);
                            var numOfResults = data.results.length;

                            var County;
                            var subCounty;
                            var street;
                            var address;
                            var ward;
                            var province;
                            var constituency;


                            for (var num = 0; num < numOfResults; num++) {
                                // let parts=data.results[i].address_components;
                                address = data.results[0].formatted_address;

                                // alert(num);
                                let parts = data.results[num].address_components;
                                parts.forEach(part => {
                                    if (part.types.includes("administrative_area_level_2")) {
                                        //we found subcounty inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                                        subCounty = part.long_name;


                                    }
                                    if (part.types.includes("country")) {
                                        //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo

                                        Country = part.long_name;

                                    }

                                    if (part.types.includes("administrative_area_level_1")) {
                                        //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                                        County = part.long_name;


                                    }
                                    if (part.types.includes("administrative_area_level_4")) {
                                        //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                                        ward = part.long_name;


                                    }
                                    if (part.types.includes("administrative_area_level_4")) {
                                        //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                                        ward = part.long_name;


                                    }
                                    if (part.types.includes("sublocality_level_1")) {
                                        //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                                        ward = part.long_name;


                                    }

                                    if (part.types.includes("route")) {
                                        //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                                        street = part.long_name;


                                    }
                                    if (part.types.includes("sublocality_level_1")) {
                                        //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                                        constituency = part.long_name;


                                    }

                                });


                            }
                            // alert("ward::"+ward);
                            // alert("street: "+street);
                            // alert("constituency: "+constituency);
                            // alert("subcounty:"+subCounty);

                            $('#newPoint .clicked-ward').text(ward);
                            $('.form-map-locations .clicked-ward').text(ward).addClass('text-black').removeClass('text-danger');
                            ;

                            $('#newPoint .clicked-street').text(street);
                            $('.form-map-locations .clicked-street').text(street).addClass('text-black').removeClass('text-danger');
                            ;

                            $('#newPoint .clicked-subcounty').text(subCounty);
                            $('.form-map-locations .clicked-subcounty').text(subCounty).addClass('text-black').removeClass('text-danger');


                            $('#newPoint .clicked-address').text(address);
                            $('.form-map-locations .clicked-address').text(address).addClass('text-black').removeClass('text-danger');
                            $('.form-map-locations .form-address').val(address);

                            $('.form-map-locations .form-latitude').val(Latitude);
                            $('.form-map-locations .form-longitude').val(longitude);

                            $('.permits-asside .the-clicked-address').text(address);
                            $('.the-clicked-address').text(address);

                            //alert(address);

                            $('#plate .plate-address').text(address);
                            $('#application .plate-address').text(address);

                            $('#plate .plate-street').text(street);
                            $('#application .plate-street').text(street);

                            $('#multi-plate .plate-street').text(street);
                            $('#multi-plate .plate-address').text(address);


                            let parts = data.results[0].address_components;
                            reverseResultsOutput += `
            <li class="list-group-item"><strong>Address: </strong> :${data.results[0].formatted_address}</li>
            `;
                            parts.forEach(part => {
                                // if(part.types.includes("country")){
                                //     //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                                //     reverseResultsOutput+=`
                                //     <ul class="list-group">
                                //         <li class="list-group-item"><strong>Country:</strong> :${part.long_name}</li>
                                //     `;

                                //     document.getElementById('location-cods').innerHTML=reverseResultsOutput;
                                // }

                                if (part.types.includes("administrative_area_level_2")) {
                                    //we found country inside the data.results[0].address_components[x].types.zmdi-view-arraydo
                                    reverseResultsOutput += `

                    <li class="list-group-item"><strong>County: </strong> :${part.long_name}</li>
                `;

                                    document.getElementById('location-cods').innerHTML = reverseResultsOutput;
                                }

                                reverseResultsOutput += "</ul>";
                            })
                        })
                        .catch(err => console.warn(err.message));
                }

                //remove marker
                function removeMarkers() {
                    for (i = 0; i < gmarkers.length; i++) {
                        gmarkers[i].setMap(null);
                    }
                }

            }
        }
    }
</script>

<style scoped>

</style>