import {createWebHashHistory, createRouter} from "vue-router";
import Login from "@/components/Login.vue";
import Dashboard from "@/components/Dashboard.vue";
import NewBill from "@/components/NewBill.vue";
import Bill from "../../public/PrintDocs/Bill/Bill.vue";
import PrintReceipt from "../../public/PrintDocs/Receipts/PrintReceipt.vue";
import BillsRaised from "@/components/BillsRaised";
import BillsReceipted from "@/components/BillsReceipted";
import UnPaidBills from "@/components/UnPaidBills";
import ReceiptedPayments from "@/components/ReceiptedPayments";
import ReceiptsNew from "@/components/ReceiptsNew";
import TradePermts from "@/components/TradePermts";
import TradePermit from "../../public/PrintDocs/Trade-Permit/TradePermit";
import Customers from "@/components/Customers";
import AddUser from "@/components/systemusers/AddUser";
import Users from "@/components/systemusers/Users";
import Transactions from "@/components/Transactions";
import Zones from "@/components/systemusers/Zones";
import FinanceAct from "@/components/finance-act/FinanceAct";
import Department from "@/components/systemsettings/Department";
import RevenueStream from "@/components/systemsettings/RevenueStream";
import SubCountyReport from "../../public/PrintDocs/Print-Reports/SubCountyReport";
import Rentals from "@/components/rent/Rentals";
import Stalls from "@/components/rent/Stalls";
import AgentsTransactions from "@/components/Transactions/AgentsTransactions";
import UserLogs from "@/components/systemusers/UserLogs";
import UserCollections from "@/components/systemusers/UserCollections";
import ReportsSubCounty from "@/components/reports/collectionbysubcounties/ReportsSubCounty";
import ReportsSubCountyDetails from "@/components/reports/collectionbysubcounties/AllTransactions";
import ReportsSubCountyStreams from "@/components/reports/collectionbysubcounties/ReportsSubCountyStreams";
import ReportsSubCountyStreamsBreakdown from "@/components/reports/collectionbysubcounties/ReportsSubCountyStreamsBreakdown";
import ReportsSubCountyStreamsDetails from "@/components/reports/collectionbysubcounties/ReportsSubCountyStreamsDetails";
import LiveMonitoring from "@/components/LiveMonitoring";
import ReportsSubCountyZones from "@/components/reports/collectionbysubcounties/ReportsSubCountyZones";
import ReportsSubCountyZonesDetails from "@/components/reports/collectionbysubcounties/ReportsSubCountyZonesDetails";
import ReportsSubCountyAgents from "@/components/reports/collectionbysubcounties/ReportsSubCountyAgents";
import ReportsSubCountyAgentDetails from "@/components/reports/collectionbysubcounties/ReportsSubCountyAgentDetails";
import Mapping from "@/components/Mapping";
import CertBizStats from "@/components/sbp/CertBizStats";
import PendingValidation from "@/components/sbp/PendingValidation";
import ReportsAgents from "@/components/reports/agents/ReportsAgents";
import ReportsAgentsDetails from "@/components/reports/agents/ReportsAgentsDetails";
import TargetsSubCounty from "@/components/systemsettings/TargetsSubCounty";
import PendingInspection from "@/components/sbp/PendingInspection";
import PendingApproval from "@/components/sbp/PendingApproval";
import Declined from "@/components/sbp/Declined";
import Approved from "@/components/sbp/Approved";
import PaidSBP from "@/components/sbp/PaidSBP";
import UnPaidSBP from "@/components/sbp/UnPaidSBP";
import SbpUnPaidBills from "@/components/sbp/SbpUnPaidBills";
import SbpPaidBills from "@/components/sbp/SbpPaidBills";
import SBPMap from "@/components/sbp/SBPMap";
import TargetsCollectors from "@/components/systemsettings/TargetsCollectors";
import TargetStream from "@/components/systemsettings/TargetStream";
import ReportsSubCountyTeam from "@/components/reports/collectionbysubcounties/ReportsSubCountyTeam";
import ReportsSubCountyTeamDetails from "@/components/reports/collectionbysubcounties/ReportsSubCountyTeamDetails";
import BusinessData from "@/components/sbp/data/BusinessData";
import BusinessMapping from "@/components/sbp/data/BusinessMapping";
import ParkingFleet from "@/components/parking/ParkingFleet";
import ParkingFleetNewSacco from "@/components/parking/ParkingFleetNewSacco";
import ParkingFleetPayment from "@/components/parking/ParkingFleetPayment";
import ParkingSticker from "../../public/PrintDocs/Parking-Sticker/ParkingSticker";
import ReportsStreams from "@/components/reports/streams/ReportsStreams";
import ReportsStreamsSubcounties from "@/components/reports/streams/ReportsStreamsSubcounties"
import SetAccounts from "@/components/systemusers/SetAccounts";

const routes =[

    {
        path:"/reports-streams",
        name:"reports-streams",
        component: ReportsStreams,
        meta:{public: false,
        }
    },
    {
        path:"/set-accounts",
        name:"set-accounts",
        component: SetAccounts,
        meta:{public: false,
        }
    },
    {
        path:"/reports-streams-sub-counties",
        name:"reports-streams-sub-counties",
        component: ReportsStreamsSubcounties,
        meta:{public: false}
    },

    {
        path:"/reports-agents",
        name:"reports-agents",
        component: ReportsAgents,
        meta:{public: false,
        }
    },
    {
        path:"/targets-SubCounty",
        name:"targets-SubCounty",
        component: TargetsSubCounty,
        meta:{public: false}
    },
    {
        path:"/parking-sticker",
        name:"parking-sticker",
        component: ParkingSticker,
        meta:{public: false}
    }
    ,
    {
        path:"/reports-agents-details",
        name:"reports-agents-details",
        component: ReportsAgentsDetails,
        meta:{public: false}
    },

    {
        path:"/",
        name:"login",
        component: Login,
        meta:{public: true}
    },
    {
        path:"/dashboard",
        name:"dashboard",
        component: Dashboard,
        meta:{public: false}
    },
    {
        path:"/new-bill",
        name:"newBill",
        component: NewBill,
        meta:{public: false}
    },

    {
        path: '/bill',
        name: 'bill',
        component: Bill,
        meta:{public: false}
    },
    {
        path: "/receipt",
        name: "receipt",
        component: PrintReceipt,
        meta: {public: true}
    },
    {
        path: "/bills-raised",
        name: "bills-raised",
        component: BillsRaised,
        meta: {public: false}
    },
    {
        path: "/un-paid-bills",
        name: "un-paid-bills",
        component: UnPaidBills,
        meta: {public: false}
    },
    {
        path: "/bills-receipted",
        name: "bills-receipted",
        component: BillsReceipted,
        meta: {public: false}
    },
    {
        path: "/receipts",
        name: "receipts",
        component: ReceiptedPayments,
        meta: {public: false}
    },
    {
        path: "/receipts-new",
        name: "receipts-new",
        component: ReceiptsNew,
        meta: {public: false}
    },
    {
        path: "/permits",
        name: "permits",
        component: TradePermts,
        meta: {public: false}
    },{
        path: "/trade",
        name: "trade",
        component: TradePermit,
        meta: {public: false}
    },
    {
        path: "/customers",
        name: "customers",
        component: Customers,
        meta: {public: false}
    },
    {
        path: "/add-users",
        name: "add-users",
        component: AddUser,
        meta: {public: false}
    },
    {
        path: "/users",
        name: "users",
        component: Users,
        meta: {public: false}
    },{
        path: "/transactions",
        name: "transactions",
        component: Transactions,
        meta: {public: false}
    },{
        path: "/zones",
        name: "zones",
        component: Zones,
        meta: {public: false}
    },
    {
        path: "/finance-act",
        name: "finance-act",
        component: FinanceAct,
        meta: {public: false}
    },
    {
        path: "/department",
        name: "department",
        component: Department,
        meta: {public: false}
    }, {
        path: "/revenue-stream",
        name: "revenue-stream",
        component: RevenueStream,
        meta: {public: false}
    },{
        path: "/sub-county-report",
        name: "sub-county-report",
        component: SubCountyReport,
        meta: {public: false}
    },
    {
        path: "/rentals",
        name: "rentals",
        component: Rentals,
        meta: {public: false}
    },
    {
        path: "/stalls",
        name: "stalls",
        component: Stalls,
        meta: {public: false}
    },{
        path: "/agent-transactions",
        name: "agent-transactions",
        component: AgentsTransactions,
        meta: {public: false}
    },
    {
        path: "/user-logs",
        name: "user-logs",
        component: UserLogs,
        meta: {public: false}
    },
    {
        path: "/user-collections",
        name: "user-collections",
        component: UserCollections,
        meta: {public: false}
    },{
        path: "/reports-sub-county",
        name: "reports-sub-county",
        component: ReportsSubCounty,
        meta: {public: false}
    },
    {
        path: "/reports-sub-counties-details",
        name: "reports-sub-counties-details",
        component: ReportsSubCountyDetails,
        meta: {public: false}
    },{
        path: "/reports-sub-county-streams",
        name: "reports-sub-county-streams",
        component: ReportsSubCountyStreams,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-streams-break-down",
        name: "reports-sub-county-streams-break-down",
        component: ReportsSubCountyStreamsBreakdown,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-streams-details",
        name: "reports-sub-county-streams-details",
        component: ReportsSubCountyStreamsDetails,
        meta: {public: false}
    },{
        path: "/live_monitoring",
        name: "live_monitoring",
        component: LiveMonitoring,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-zones",
        name: "reports-sub-county-zones",
        component: ReportsSubCountyZones,
        meta: {public: false}
    },{
        path: "/reports-sub-county-zones-details",
        name: "reports-sub-county-zones-details",
        component: ReportsSubCountyZonesDetails,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-agents",
        name: "reports-sub-county-agents",
        component: ReportsSubCountyAgents,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-agent-details",
        name: "reports-sub-county-agent-details",
        component: ReportsSubCountyAgentDetails,
        meta: {public: false}
    },
    {
        path: "/mapping",
        name: "mapping",
        component: Mapping,
        meta: {public: false}
    },
    {
        path: "/cert-biz-stats",
        name: "cert-biz-stats",
        component: CertBizStats,
        meta: {public: false}
    },
    {
        path: "/pending-validation",
        name: "pending-validation",
        component: PendingValidation,
        meta: {public: false}
    },
    {
        path: "/pending-inspection",
        name: "pending-inspection",
        component: PendingInspection,
        meta: {public: false}
    },
    {
        path: "/pending-approval",
        name: "pending-approval",
        component: PendingApproval,
        meta: {public: false}
    },{
        path: "/declined",
        name: "declined",
        component: Declined,
        meta: {public: false}
    },{
        path: "/approved",
        name: "approved",
        component: Approved,
        meta: {public: false}
    },{
        path: "/paid-sbp",
        name: "paid-sbp",
        component: PaidSBP,
        meta: {public: false}
    },{
        path: "/unpaid-sbp",
        name: "unpaid-sbp",
        component: UnPaidSBP,
        meta: {public: false}
    },{
        path: "/sbp-unpaid-bills",
        name: "sbp-unpaid-bills",
        component: SbpUnPaidBills,
        meta: {public: false}
    },{
        path: "/sbp-paid-bills",
        name: "sbp-paid-bills",
        component: SbpPaidBills,
        meta: {public: false}
    },{
        path: "/sbp-map",
        name: "sbp-map",
        component: SBPMap,
        meta: {public: false}
    },
    {
        path: "/targets-collectors",
        name: "targets-collectors",
        component: TargetsCollectors,
        meta: {public: false}
    }, {
        path: "/targets-stream",
        name: "targets-stream",
        component: TargetStream,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-team",
        name: "reports-sub-county-team",
        component: ReportsSubCountyTeam,
        meta: {public: false}
    }, {
        path: "/reports-sub-county-team-details",
        name: "reports-sub-county-team-details",
        component: ReportsSubCountyTeamDetails,
        meta: {public: false}
    },
    {
        path: "/business-data",
        name: "business-data",
        component: BusinessData,
        meta: {public: false}
    },{
        path: "/business-mapping",
        name: "business-mapping",
        component: BusinessMapping,
        meta: {public: false}
    },{
        path: "/parking-fleet",
        name: "parking-fleet",
        component: ParkingFleet,
        meta: {public: false}
    },
    {
        path: "/parking-fleet-new",
        name: "parking-fleet-new",
        component: ParkingFleetNewSacco,
        meta: {public: false}
    }, {
        path: "/parking-fleet-payment",
        name: "parking-fleet-payment",
        component: ParkingFleetPayment,
        meta: {public: false}
    }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
});



router.beforeEach((to, from, next) => {
// check if the target route requires authentication
    if (!to.meta.public) {
// check if the user is logged in or not
        if (sessionStorage.getItem("isLoggedIn")) {
// if yes, allow the navigation
            next();
        } else {
// if no, redirect the user to the login page
            next("/");
        }
    } else {
// if the target route does not require authentication, allow the navigation
        next();
    }
});



export default router;
