<template>
    <div class="row">
        <div class="col-12 active">
            <div class="card active">
                <div class="card-body mini-nav bg-primary pt-2 pb-3 active">
                    <nav class="navbar navbar-expand-md navbar navbar-dark bg-primary py-2 active">
                        <button
                                class="navbar-toggler btn btn-sm px-3 font-size-16 header-item waves-effect h-auto text-primary"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarNavAltMarkup"
                                aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                        >
                            <span class="mdi mdi-menu"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-between active" id="navbarNavAltMarkup">
                            <div class="navbar-nav active">
                                <a
                                        class="nav-item nav-link"
                                        :class="{ active: selectedSub === 'reports-streams' }"
                                        @click="gotTo('reports-streams')"
                                >
                                    Summarized
                                </a>
                                <a
                                        class="nav-item nav-link"
                                        :class="{ active: selectedSub === 'reports-streams-sub-counties' }"
                                        @click="gotTo('reports-streams-sub-counties')"
                                >
                                    Sub counties
                                </a>
                            </div>
                            <div class="navbar-nav text-capitalize">
                                <h4 class="mb-0 m-0 card-title text-white">Sammarized Revenue Streams Report</h4>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ToolBarStreams",
        data() {
            return {
                selectedSub: null,
            };
        },
        mounted() {
            this.selectedSub = sessionStorage.getItem("selectedSub");
        },
        methods: {
            gotTo(route) {
                this.selectedSub = route;
                this.$router.push(route);
                sessionStorage.setItem("selectedSub", route);

            },
        },
    };
</script>

<style scoped></style>
