<template>

<head>

    <meta charset="utf-8" />
    <title>Revenue Streams</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Keep your finances and membership up to date" name="description" />
    <meta content="Kelvin Njuguna Ndungu" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <link href="assets/libs/bootstrap-datepicker/css/bootstrap-datepicker.min.css" rel="stylesheet" type="text/css">
    <link href="assets/libs/spectrum-colorpicker2/spectrum.min.css" rel="stylesheet" type="text/css">
    <link href="assets/libs/bootstrap-timepicker/css/bootstrap-timepicker.min.css" rel="stylesheet" type="text/css">
    <link href="assets/libs/bootstrap-touchspin/jquery.bootstrap-touchspin.min.css" rel="stylesheet" type="text/css" />
    <link rel="stylesheet" href="assets/libs/%40chenfengyuan/datepicker/datepicker.min.css">

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />


</head>

<body data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <NavigationBar/>

        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Revenue Streams</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="javascript: void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item active">Set Ups </li>
                                        <li class="breadcrumb-item active">Revenue Streams</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">

                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">
                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <h4 class="mb-0">Revenue Streams</h4>
                                        <div v-if="getRight('CREATE REVENUE STREAM')" class="d-flex">
                                            <a href="#" type="button" class="btn btn-primary dropdown-toggle option-selector" data-bs-toggle="modal" data-bs-target=".add-modal">
                                                <i class="mdi mdi-account-multiple-plus-outline  font-size-16"></i> <span class="pl-1 d-md-inline">Add Stream</span>
                                            </a>
                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">

                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="bx  bx-trash label-icon"></i> Delete Contact(s)</button>
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-plus label-icon"></i> Compose Message</button>
                                    </div>
                                </div>

                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table class="table align-middle  table-nowrap table-hover dt-responsive contacts-table" id="">
                                            <thead class="table-dark">
                                                <th colspan="4">
                                                    Revenue Streams
                                                </th>
                                            </thead>
                                            <tfoot class="table-dark">
                                                <tr>

                                                    <th colspan="2">{{streams.length}} Revenue Streams</th>
                                                    <th colspan="2">{{totalSources}} Sources</th>
                                                </tr>
                                            </tfoot>

                                            <tbody style="cursor: pointer;" v-for="(item, index) in streams" :value="item" :key="index">
                                                <tr class="table-light">
                                                    <th style="width: 50px;">{{index+1}}.</th>
                                                    <th colspan="2">{{item.stream.stream}}</th>

                                                    <td class="text-right" v-if="permission=='WRITE'">
                                                        <button v-if="getRight('EDIT REVENUE STREAM')" style="margin-right: 5px" @click="selectedMainStream(item)" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target=".add-modal">
                                                            Edit
                                                        </button>

                                                        <button v-if="getRight('DELETE REVENUE STREAM')" @click="deleteItem(item)" class="btn btn-sm btn-danger">
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>

                                                <tr  v-for="(item2, index2) in item.incomeType" :value="item" :key="index2" class=" " >
                                                    <th style="width: 50px;"> </th>

                                                    <th colspan="2">({{toAlpha(index2+1)}}) {{item2.incomeTypeDescription}}</th>
                                                    <th v-if="getRight('EDIT REVENUE STREAM')" class="text-right"> <button style="margin-right: 5px" @click="selectedIncomeType(item2)" class="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target=".edit-rev-source">
                                                        Edit
                                                    </button></th>

                                                </tr>

                                            </tbody>
                                        </table>


                                        <div v-if="loading" class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center">
                                            <div class="p-5 py-0 pt-3">
                                                <div class="p-5">
                                                    <div class="spinner-border text-info m-1" role="status">
                                                        <span class="sr-only text-uppercase">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 class="text-uppercase">Loading Data</h4>
                                            <div class="col-sm-12 col-md-8 col-lg-5">
                                                <p>We are fetching the data for you. It will be ready soon. Please wait and have fun. Thank you for your interest.</p>

                                            </div>
                                        </div>

                                        <div v-if="streams.length<1 & loading === false" class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center">
                                            <div class="p-5 py-0 pt-3">
                                                <img :src="'./assets/images/no-results.png'" class="img mb-4"  alt="No search results"/>
                                            </div>
                                            <h4>No Results To Show</h4>
                                            <p>Sorry, we could not find any results that match your search or filter criteria. Please try using different keywords or filters.</p>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>





                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- registration and editing modal -->
            <div class="modal fade add-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Register Revenue Stream</h5>
                            <button @click="clear()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12">
                                    <label for="">Revenue Stream Name</label>
                                    <input v-model="stream.stream" type="text" class="form-control" placeholder="Revenue Stream Name">
                                </div>

                                <div class="col-12">
                                    <label for="">Income Type Prefix</label>
                                    <input v-model="stream.incomeTypePrefix" type="text" class="form-control" placeholder="Income Type Prefix">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                           <button  data-bs-dismiss="modal" @click="addMainStreams()" class="btn btn-primary w-100" title="Click to save">DONE</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of edditing modal -->

            <!-- Edit reveneu stream -->
            <div class="modal fade edit-rev-source" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-md modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Edit Revenue Source</h5>
                            <button @click="clear()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">

                                <div class="form-group" style="margin-top: 20px">
                                    <label>Revenue Source<strong class="text-danger">*</strong></label>
                                    <select v-model="incomeTypePrefix" class="form-control bg-white" title="Select Revenue Stream" data-live-search="true">
                                        <option value="">Select Revenue Type</option>
                                        <option v-for="(item, index) in streams" :key="`${index}`" :value="JSON.stringify(item.stream)">{{item.stream.stream}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button  data-bs-dismiss="modal" @click="updateRevenueStream()" class="btn btn-primary w-100" title="Click to save">SAVE</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of reveneu modal -->


            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            Best Birdy Billing system.
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>

</template>

<script>
    /* eslint-disable */
    import NavigationBar from "@/components/Navigation";
    import router from "@/router";
    import {biller, execute, getRight} from "@/api";
    export default {
        name: "AddUser",
        components: {NavigationBar},
        data() {
            return{
                incomeTypePrefix:'',
                totalSources:0,
                loading: false,
                permission:"",
                incomeTypes:[],
                departmentID:'',
                incomeTypeDescription:'',
                fundDescription:'',
                message: null,
                id:'',
                update:"false",
                departments:[],
                department:'',
                stream: {
                    id: null,
                    incomeTypePrefix: null,
                    stream: null
                },
                streams: [{
                        stream: {
                            id: "1",
                            incomeTypePrefix: "PKN",
                            stream: "PARKING"
                        },
                        incomeType: [
                            {
                                incomeTypeId: "99",
                                incomeTypeDescription: "Daily parking",
                                incomeTypePrefix: "PKN",
                                fundDescription: null,
                                status: "1",
                                departmentID: "3",
                                zoneCategory: "Parking",
                                zoneCategoryID: "9",
                                onUssd: "1",
                                dailyTargets: null,
                                monthlyTargets: null,
                                financialYearTargets: null,
                                brimsCode: ""
                            }]}],

                incomeType:
                    {
                        incomeTypeId: "99",
                        incomeTypeDescription: "Daily parking",
                        incomeTypePrefix: "PKN",
                        fundDescription: null,
                        status: "1",
                        departmentID: "3",
                        zoneCategory: "Parking",
                        zoneCategoryID: "9",
                        onUssd: "1",
                        dailyTargets: null,
                        monthlyTargets: null,
                        financialYearTargets: null,
                        brimsCode: ""
                    }
            }
        },
        computed: {


        },
        watch: {

        },

        mounted() {
            this.permission = sessionStorage.getItem("permission")
            this.getMainStreamsDetails()
        },
        methods:{
            getRight(type){
                return getRight(type)
            },
            gotTo(route){
                router.push(route)
            },
            selectedMainStream(item){
                this.stream = item.stream

            },

            clear(){
                this.stream.id =null
                this.stream.incomeTypePrefix =null
                this.stream.stream =null
                this.getMainStreamsDetails()
            },
            toAlpha(num) {
                let alpha = "";
                while (num > 0) {
                    let remainder = (num - 1) % 26;
                    alpha = String.fromCharCode(97 + remainder) + alpha; // Use 97 for lowercase 'a' and 65 for uppercase
                    num = Math.floor((num - 1) / 26);
                }
                return alpha;
            },
            deleteItem(item) {
                this.stream = item.stream
                // Display a confirmation dialog
                const isConfirmed = window.confirm(`Are you sure you want to delete ${this.stream.stream}?`);

                // Check the user's choice
                if (isConfirmed) {
                    this.deleteMainStreams()
                } else {
                    // User clicked "No," do nothing or provide feedback
                    console.log(`Deletion of ${this.stream.stream} canceled.`);
                }
            },

            selectedIncomeType(incomeType){
                this.incomeType =  incomeType;
            },

            updateRevenueStream(){
                this.message = null
                this.message = null
                const data = new FormData();
                data.append("function", "updateRevenueStream");
                data.append("departmentID", this.incomeType.departmentID);
                data.append("incomeTypeId", this.incomeType.incomeTypeId);
                data.append("incomeTypeDescription", this.incomeType.incomeTypeDescription);
                data.append("incomeTypePrefix", this.incomeTypePrefix);
                data.append("ussd", this.incomeType.onUssd);
                data.append("brimsCode", this.incomeType.brimsCode);
                execute(data,biller)
                    .then((res) =>{
                        if (res.data.success) {
                            this.message =res.data.message
                            this.clear()
                            //this.departments = res.data.data.departments
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });

            },
            addMainStreams(){
                if(this.streamName===''){
                   this.message ='Fill in stream Name '
                    return
                }
                this.message = null
                const data = new FormData();
                data.append("function", "addMainStreams");
                data.append("stream", JSON.stringify(this.stream));
                execute(data,biller)
                    .then((res) =>{
                        this.clear()
                        if (res.data.success) {
                            this.update = false
                            this.streamName = ""
                            this.incomeTypePrefix = ""
                            this.clear()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
            getMainStreamsDetails(){
                this.loading = true
                const data = new FormData();
                data.append("function", "getMainStreamsDetails");
                execute(data,biller)
                    .then((res) =>{
                        this.loading = false
                        if (res.data.success) {
                            this.streams.splice(0)
                            this.streams = res.data.data.streams
                            this.totalSources =  this.streams.reduce((total, stream) => total + stream.incomeType.length, 0);
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },

            deleteMainStreams(){
                this.message = null
                const data = new FormData();
                data.append("function", "deleteMainStreams");
                data.append("stream", JSON.stringify(this.stream));
                execute(data,biller)
                    .then((res) =>{
                        this.clear()
                        if (res.data.success) {
                            this.getMainStreamsDetails()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },

        },
    }
</script>

<style scoped>

</style>